/* eslint-disable jsx-a11y/img-redundant-alt */
import { useContext, useState } from "react";
import CreateAvailable from "../../Create-Available/CreateAvailable";
import WhiteButton from "../../Buttons/WhiteButton/WhiteButton";
import "./DashboardHeader.scss";
import StudentContext from "../../../Contexts/StudentContext";
import NewMessageModal from "../../Messages/NewMessageModal/NewMessageModal";
import moment from "moment";

export default function DashboardHeader() {
	const user = useContext(StudentContext);
	const [createAvailableModalOpen, setCreateAvailableModalOpen] =
		useState(false);
	const [sendMessageModalOpen, setSendMessageModalOpen] = useState(false);

	function createAvailableModalButtonHandler() {
		setCreateAvailableModalOpen(!createAvailableModalOpen);
	}

	function sendMessageModalButtonHandler() {
		setSendMessageModalOpen(!sendMessageModalOpen);
	}

	function linkToFilesButtonHandler() {
		window.location.href = "/files";
	}

	return (
		<>
			<div id="instDashHeader" className="gridHeader">
				<div id="mainPFP">
					<img
						referrerPolicy="no-referrer"
						src={user?.profilePicLink}
						alt="Profile Picture"
					/>
				</div>
				<div id="instWelcome">
					<p>Welcome Back,</p>
					<h2>{user!.firstName}</h2>
					<p id="today">{moment().format("dddd, MMMM Do yyyy")}</p>
				</div>
				<div id="miniStats">
					{/* <div id='addAvailability' onClick={modalButtonHandler}>
                    <p>Add Availability</p>
                </div> */}
					<WhiteButton
						id="addAvailability"
						text="Add Availability"
						onClick={createAvailableModalButtonHandler}
					/>
					<WhiteButton
						id="newMessage"
						text="New Message"
						onClick={sendMessageModalButtonHandler}
					/>
					<WhiteButton
						id="manageFiles"
						text="Manage Files"
						onClick={linkToFilesButtonHandler}
					/>
					{/* <a id='newMessage'>
                    <p>New Message</p>
                </a>
                <a id='manageFiles'>
                    <p>Manage Files</p>
                </a> */}
				</div>
			</div>
			{createAvailableModalOpen && (
				<CreateAvailable exitCallback={createAvailableModalButtonHandler} />
			)}
			{sendMessageModalOpen && (
				<NewMessageModal exitCallback={sendMessageModalButtonHandler} />
			)}
		</>
	);
}

import moment from 'moment';
import { forwardRef } from 'react';
import TimePickerListItem from '../TimePickerListItem/TimePickerListItem';
import './TimePickerDropDown.scss'

type Props = {
    time: string,
    setTime: Function,
    setShowDropdown: Function,
    startLimit?: string,
    endLimit?: string
}
const TimePickerDropDown = forwardRef((props: Props, ref: any) => {
    const startTimeMoment = moment('11:45pm', 'h:mma');
    const startLimit = moment(props.startLimit, 'h:mma');
    const endLimit = moment(props.endLimit, 'h:mma');
    const hours = props.startLimit && props.endLimit ? endLimit.hours() - startLimit.hours() : 24;
    console.log(hours)
    const hourElements = Array.from(Array(4 * hours).keys()).map(number => {
        const value = props.startLimit && props.endLimit ?
        startLimit.add((15 - startLimit.minute() % 1), 'minutes').format('h:mma') :
            startTimeMoment.add((15 - startTimeMoment.minute() % 15), 'minutes').format('h:mma');
        let isSelected = false;
        if (props.time === value)
            isSelected = true;
        return <TimePickerListItem
            isSelected={isSelected}
            key={number + 1}
            setTime={props.setTime}
            setShowDropdown={props.setShowDropdown}
            value={value}
        />
    });

    return (
        <div ref={ref} className="timePickerDropdown">
            <ul className='timeList'>{hourElements}</ul>
        </div>
    )
});

export default TimePickerDropDown;

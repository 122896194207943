import CircleCheck from '../../../images/gray-circle-check.png';
import CircleX from '../../../images/x-circle-icon.png';
type Props = {
    isSuccessful: boolean,
    errorMessage?: string,
    cartLength: number,
    priceString: string
}
function PostPaymentMessage(props: Props) {
    return (
        <>
            {props.isSuccessful &&
                <div id="successDiv" className="">
                    <div id="successContent">
                        <>
                            <div id="successHeader">
                                <div>
                                    <img src={CircleCheck} alt="Payment Successful" />
                                    <h4>Payment Succeeded!</h4>
                                </div>
                                <p>You bought <strong><span id="lessonNumber">{props.cartLength}</span></strong> lesson{props.cartLength > 1 ? "s" : ""} for <strong><span id="total">{props.priceString}</span></strong></p>
                            </div>
                            <div id="successBody">
                                <h4>Next Steps</h4>
                                <ul>
                                    <li>1. See if your instructor shared any files with you</li>
                                    <li>2. Message your instructor with any questions</li>
                                    <li>3. Set your email preferences to get lesson reminders</li>
                                    <li>4. View your dashboard to manage and join upcoming lessons</li>
                                </ul>
                            </div>
                            <div id="successFooter">
                                <button type="button" className="blackButton"><a href="/files">View Files</a></button>
                                <button type="button" className="blackButton"><a href="/message">Message Instructor</a></button>
                                <button type="button" className="blackButton"><a href="/settings">Edit Notifications</a></button>
                                <button type="button" className="greenButton"><a href="/dashboard">Return To Dashboard</a></button>
                            </div>
                        </>
                    </div>
                </div>
            }
            {!props.isSuccessful &&
                <>
                    <div id="errorDiv">
                        <div id="errorContent">
                            <img src={CircleX} alt="Payment Failed" />
                            <h3>Failure</h3>
                            <span>-</span>
                            <p id="errorMessage">{props.errorMessage}</p>
                            <button type="button" className="greenButton"><a href="/cart">Return To Cart</a></button>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default PostPaymentMessage
import React, {
	ChangeEvent,
	MouseEvent,
	ReactElement,
	useEffect,
	useState,
} from "react";
import Modal from "../../Modal/Modal";
import ModalHeader from "../../Modal/ModalHeader/ModalHeader";
import UserType from "../../../Types/UserType";
import RelationshipsService from "../../../services/relationship.service";
import "../Messages.scss";
import SearchService from "../../../services/search.service";

type Props = {
	exitCallback: Function;
};
function NewMessageModal(props: Props) {
	const [userRelationships, setUserRelationships] = useState<UserType[]>([]);
	const [userRelationshipsElements, setUserRelationshipsElements] = useState<
		ReactElement[]
	>([]);
	const [, setSelectedRelationship] = useState("");
	const [searchValue, setSearchValue] = useState("");
	const searchWrapper = document.querySelector(".search-input");

	useEffect(() => {
		RelationshipsService.getUserRelationships().then((res) => {
			setUserRelationships(res);
		});
	}, []);

	function keyUpSearch(event: ChangeEvent<HTMLInputElement>) {
		setSelectedRelationship("");
		setSearchValue(event.target.value);
		const value = event.currentTarget.value.toUpperCase();
		const elements: ReactElement[] = [];
		let searchActive = false;
		if (value.length === 0) {
			setUserRelationshipsElements(elements);
			return;
		}
		for (const relationship of userRelationships) {
			if (
				relationship.firstName?.toUpperCase().includes(value) ||
				relationship.lastName?.toUpperCase().includes(value) ||
				relationship.email
					?.substring(0, relationship.email.indexOf("@"))
					.toUpperCase()
					.includes(value)
			) {
				elements.push(
					<li
						onClick={relationShipSelect}
						key={relationship.id}
						className="shareLi cursorPoint"
						id={relationship.id.toString()}
					>
						<p className="shareName">
							{relationship.firstName} {relationship.lastName}
						</p>{" "}
						- <p className="userEmail">{relationship.email}</p>
					</li>
				);
				searchActive = true;
			}
		}

		if (searchActive) searchWrapper?.classList.add("active");
		else searchWrapper?.classList.remove("active");
		setUserRelationshipsElements(elements);
	}

	function relationShipSelect(e: MouseEvent<HTMLLIElement>) {
		const id = e.currentTarget.id;
		const relationship = userRelationships.filter(
			(i) => i.id.toString() === id
		)[0];
		setSearchValue(relationship.email!);
		setSelectedRelationship(id);
		setUserRelationshipsElements([]);
		searchWrapper?.classList.remove("active");
	}

	async function handleRedirect() {
		const result: number = (await SearchService.getUserViaEmail(searchValue))
			.data;
		if (result === null) {
			alert(
				"That user does not exist. Ensure that the email you provided is correct."
			);
			return;
		} else {
			window.location.href = "/message/" + result;
		}
	}

	return (
		<Modal exitCallback={props.exitCallback}>
			<ModalHeader headerText="New Message" />
			<hr />
			<div id="userSearch" className="messageModal">
				<p className="question">Who would you like to message?</p>
				<p>
					Enter an exact email if this is your first time messaging the user
				</p>
				<div className="wrapper">
					<div className="search-input">
						<input
							onChange={keyUpSearch}
							id="userShareSearch"
							type="text"
							placeholder="Type to search.."
							autoComplete="off"
							value={searchValue}
						/>
						<div className="autocom-box">
							<ul id="users">{userRelationshipsElements}</ul>
						</div>
						<div className="icon" onClick={handleRedirect}>
							Message
						</div>
					</div>
				</div>
			</div>
		</Modal>
	);
}

export default NewMessageModal;

import axios from "axios";
import CartType from "../Types/CartType";
import AddToCartDetailsType from "../Types/AddToCartDetailsType";
import config from "../utils/config";

class CartService {
	static async getCarts(): Promise<CartType[]> {
		return (
			await axios.get(config.API_URL + "/api/cart", {
				withCredentials: true,
			})
		).data;
	}
	static async getNumCarts(): Promise<number> {
		return (
			await axios.get(config.API_URL + "/api/cart/count", {
				withCredentials: true,
			})
		).data.numCarts;
	}
	static async addToCart(
		lessonId: number,
		details: AddToCartDetailsType
	): Promise<any> {
		return await axios
			.post(config.API_URL + "/api/cart/" + lessonId, details, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.response;
			});
	}
	static deleteCart(lessonId: number): Promise<any> {
		return axios
			.delete(config.API_URL + "/api/cart/" + lessonId, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			});
	}
}

export default CartService;

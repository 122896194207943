import { ChangeEvent, useState } from 'react'
import PaymentFormDataType from '../../../Types/PaymentFormDataType';

type Props = {
    setStep: Function,
    step: number,
    formData: PaymentFormDataType,
    setFormDataHandler: Function
}
function BillingStep(props: Props) {
    const STREETADDRESSLINETWOBILLINGSTRING = 'streetAddressLineTwoBilling';
    const [nextActivated, setNextActivated] = useState(false);

    // Event Handler functions
    function nextStepHandler() {
        props.setStep(props.step + 1);
    }
    function previousStepHandler() {
        props.setStep(props.step - 1);
    }

    function onChangeHandler(event: ChangeEvent<HTMLInputElement>) {
        const newData = { ...props.formData, [event.target.name]: event.target.value };
        props.setFormDataHandler(newData);

        // Check if inputs are filled out
        const asArray = Object.entries(newData);
        const filteredResult = asArray.filter(([key, value]) =>
            key !== STREETADDRESSLINETWOBILLINGSTRING &&
            value.length > 0
        );
        if (filteredResult.length === (Object.keys(newData).length - 1))
            setNextActivated(true);
        else
            setNextActivated(false);
    }

    return (
        <div id="billingDiv" className="">
            <h2>Billing</h2>
            <div id="billingInputs">
                <ul id="nameInputs" className="formGroup">
                    <li className="shortInput">
                        <label htmlFor="firstNameBilling">First Name</label>
                        <input onChange={onChangeHandler} type="text" name="firstNameBilling" id="firstNameBilling"
                            placeholder='Johnny' value={props.formData.firstNameBilling} />
                    </li>
                    <li className="shortInput">
                        <label htmlFor="lastNameBilling">Last Name</label>
                        <input onChange={onChangeHandler} type="text" name="lastNameBilling" id="lastNameBilling" placeholder='Appleseed'
                            value={props.formData.lastNameBilling}
                        />
                    </li>
                    <li>
                        <label htmlFor="streetAddressLineOneBilling">Street Address Line 1</label>
                        <input onChange={onChangeHandler} type="text" name="streetAddressLineOneBilling"
                            id="streetAddressLineOneBilling" placeholder='1234 Example St.' value={props.formData.streetAddressLineOneBilling} />
                    </li>
                    <li>
                        <label htmlFor="streetAddressLineTwoBilling">Street Address Line 2</label>
                        <input onChange={onChangeHandler} type="text" name="streetAddressLineTwoBilling"
                            id="streetAddressLineTwoBilling" placeholder='Apt 1234'
                            value={props.formData.streetAddressLineTwoBilling} />
                    </li>
                    <li className="shortInput">
                        <label htmlFor="cityBilling">City</label>
                        <input onChange={onChangeHandler} type="text" name="cityBilling" id="cityBilling" placeholder='Austin'
                            value={props.formData.cityBilling} />
                    </li>
                    <li className="shortInput">
                        <label htmlFor="stateBilling">State</label>
                        <input onChange={onChangeHandler} type="text" name="stateBilling" id="stateBilling" placeholder='Texas' value={props.formData.stateBilling} />
                    </li>
                    <li>
                        <label htmlFor="zipcodeBilling">Zipcode</label>
                        <input onChange={onChangeHandler} type="text" name="zipcodeBilling" id="zipcodeBilling" placeholder='12345' value={props.formData.zipcodeBilling} />
                    </li>
                </ul>
            </div>
            <div className="footerButtons">
                <button type="button" className="invertedBtn viewCartBtn"><a href="/cart">View Cart</a></button>
                <button type="button" id="billingBack" onClick={previousStepHandler}>Back</button>
                <button disabled={!nextActivated} type="button" className={nextActivated ? 'activated cursorPoint' : 'deactivated'} id="billingNext" onClick={nextStepHandler}>Next</button>
            </div>
        </div>
    )
}

export default BillingStep
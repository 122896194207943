import "./Dashboard.scss";
import { useContext, useEffect, useState } from "react";
import DashboardHeader from "./DashboardHeader/DashboardHeader";
import StudentDashboardHeader from "./StudentDashboardHeader/StudentDashboardHeader";
import DashboardLessons from "./DashboardLessons/DashboardLessons";
import DashboardNotifications from "./DashboardNotifications/DashboardNotifications";
import "./Dashboard.scss";
import StudentContext from "../../Contexts/StudentContext";
import config from "../../utils/config";
import LessonService from "../../services/lesson.service";
import PreviousLessonType from "../../Types/PreviousLessonType";
import StripeOnboardModal from "../StripeOnboardModal/StripeOnboardModal";
import TermsAndConditionsModal from "./TermsAndConditions/TermsAndConditionsModal";
import InstructorOnboardModal from "../InstructorOnboardModal/InstructorOnboardModal";

export default function Dashboard() {
	const user = useContext(StudentContext);
	const [previousLesson, setPreviousLesson] = useState<
		PreviousLessonType | undefined
	>();

	useEffect(() => {
		document.title = config.DASHBOARD_DOC_TITLE;
		LessonService.getPreviousLesson().then((value: PreviousLessonType) => {
			setPreviousLesson(Object.keys(value).length > 0 ? value : undefined);
		});
	}, []);

	return (
		<>
			{!user?.agreedToTerms && <TermsAndConditionsModal />}

			{!!user?.isInstructor === true &&
				user?.agreedToTerms &&
				!user.stripeAccountOnboarded && (
					<StripeOnboardModal
						exitCallback={() => {}}
						accountCreated={
							user?.stripeAccountCreated === true &&
							user.stripeAccountOnboarded === false
						}
					/>
				)}

			{!!user?.isInstructor === true &&
				user?.agreedToTerms &&
				user.stripeAccountOnboarded &&
				!user?.isOnboarded && (
					<InstructorOnboardModal exitCallback={() => {}} />
				)}

			<div className="gridContainer">
				{!!user?.isInstructor === true && <DashboardHeader />}
				{!!user?.isInstructor === false && (
					<StudentDashboardHeader
						previousLesson={previousLesson}
						studentFirstName={user?.firstName!}
					/>
				)}
				<DashboardLessons />
				<DashboardNotifications />
			</div>
		</>
	);
}

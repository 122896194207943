import { useState, useEffect } from "react";
import PaymentFormDataType from "../../../Types/PaymentFormDataType";
import ExistingCard from "./ExistingCard";

type Props = {
	cardList: Array<any>;
	setPaymentMethod: Function;
	paymentMethod: string;
	nextActivatedHandler: Function;
	setFormData: Function;
	formData: PaymentFormDataType;
	setLast4: Function;
	setExp: Function;
};
function ExistingCardList(props: Props) {
	const [cardListStatus, setCardListStatus] = useState({});

	useEffect(() => {
		let tempCardListStatus = {};
		props.cardList.forEach((card) => {
			tempCardListStatus = { ...tempCardListStatus, [card.id]: false };
		});
		setCardListStatus(tempCardListStatus);
	}, [props.cardList]);

	function setFormDataHandler(pm: string) {
		const card = props.cardList.find((p) => p.id === pm);
		const formData = props.formData;
		formData.cityBilling = card.billing_details.address.city;
		formData.firstNameBilling = card.billing_details.name.split(" ")[0];
		formData.lastNameBilling = card.billing_details.name.split(" ")[1];
		formData.zipcodeBilling = card.billing_details.address.postal_code;
		formData.streetAddressLineOneBilling = card.billing_details.address.line1;
		formData.streetAddressLineTwoBilling = card.billing_details.address.line2;
		formData.stateBilling = card.billing_details.address.state;
		props.setFormData(formData);
		props.setLast4(card.card.last4);
		props.setExp(card.card.exp_month + "/" + card.card.exp_year);
	}

	return (
		<div id="existingCardInputs" className="">
			<div className="centerContent">
				<ul>
					{props.cardList.length === 0 && (
						<p>You currently have no saved cards</p>
					)}
					{props.cardList.map((card) => {
						return (
							<ExistingCard
								key={card.id}
								id={card.id}
								brand={card.card.brand}
								last4={card.card.last4}
								exp_month={card.card.exp_month}
								exp_year={card.card.exp_year}
								setPaymentMethod={props.setPaymentMethod}
								nextActivatedHandler={props.nextActivatedHandler}
								cardListStatus={cardListStatus}
								setCardListStatus={setCardListStatus}
								paymentMethod={props.paymentMethod}
								setFormData={setFormDataHandler}
							/>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default ExistingCardList;

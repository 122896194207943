import { FormEvent, useState, ChangeEvent } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import "./AddToCartModal.scss";
import moment from "moment";
import TimePicker from "../TimePicker/TimePicker";
import AvailableType from "../../Types/AvailableType";
import CartService from "../../services/cart.service";
import AddToCartDetailsType from "../../Types/AddToCartDetailsType";
import LoadingElement from "../LoadingElement/LoadingElement";

type Props = {
	id: number;
	exitCallback: Function;
	available: AvailableType;
	deleteFromAvailableList: Function;
};
export default function AddToCartModal(props: Props) {
	console.log(props.available);
	const date = moment(props.available.startTime);
	const [startTime, setStartTime] = useState(
		moment(props.available.startTime).format("h:mm a")
	);
	const [endTime, setEndTime] = useState(
		moment(props.available.endTime).format("h:mm a")
	);
	const [chosenInstrument, setchosenInstrument] = useState<number>();
	const [loading, setLoading] = useState(false);
	const [showTimeInput, setShowTimeInput] = useState(false);

	function shorterTimeOnClickHandler() {
		setShowTimeInput(!showTimeInput);
	}

	async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		if (!chosenInstrument) return;
		const lessonDetails: AddToCartDetailsType = {
			instrumentId: chosenInstrument,
		};
		if (
			startTime !== moment(props.available.startTime).format("h:mm a") ||
			endTime !== moment(props.available.endTime).format("h:mm a")
		) {
			lessonDetails.alteredStart = moment(startTime, "h:mm a")
				.dayOfYear(date.dayOfYear())
				.format();
			lessonDetails.alteredEnd = moment(endTime, "h:mm a")
				.dayOfYear(date.dayOfYear())
				.format();
		}
		const result = await CartService.addToCart(props.id, lessonDetails);

		await props.exitCallback();
		if (result === 204) {
			props.deleteFromAvailableList(props.id);
			alert("Lesson successfully added to your cart!");
		} else {
			alert(result.data || "Something went wrong. Try again later.");
		}
	}

	function onRadioChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		setchosenInstrument(parseInt(e.target.id));
	}

	return (
		<Modal exitCallback={props.exitCallback}>
			<ModalHeader headerText="Confirm Details" />
			<hr />
			<>{loading && <LoadingElement size={2} />}</>
			<>
				{!loading && (
					<form id="availableForm" method="POST" onSubmit={onSubmitHandler}>
						<div className="centerContent">
							<div id="availableInfo">
								<div className="popUpReservedFor">
									<p>Sean Danielson</p>
								</div>
								<div className="popUpDate">
									<p>{date.format("dddd, MMMM Do YYYY")}</p>
								</div>
								{!showTimeInput && (
									<>
										<div id="setTime" className="popUpTime">
											<p>
												{moment(props.available.startTime).format("h:mm a") +
													" - " +
													moment(props.available.endTime).format("h:mm a")}
											</p>
										</div>
										<div className="shorterTimeButton">
											<button
												onClick={shorterTimeOnClickHandler}
												id="actualShorterTimeButton"
												type="button"
											>
												Select shorter time
											</button>
										</div>
										<br></br>
									</>
								)}
								{showTimeInput && (
									<>
										<TimePicker
											time={startTime}
											setTime={setStartTime}
											startLimit={startTime}
											endLimit={endTime}
										/>
										<TimePicker
											time={endTime}
											setTime={setEndTime}
											startLimit={startTime}
											endLimit={endTime}
										/>
									</>
								)}
							</div>
							<div id="availableLocation">
								<p id="availableLocationHeader">General Location</p>
								<div className="popUpLocation">
									<p>Virtual Lesson</p>
								</div>
							</div>
							<div id="availableInstruments">
								<p id="availableInstrumentsHeader">Instruments</p>
								<ul id="availableInstrumentList">
									{JSON.parse(`[${props.available.instrumentList}]`).map(
										(instrument: any) => {
											return (
												<li key={instrument.instrumentId}>
													<input
														onChange={onRadioChangeHandler}
														type="radio"
														name="instrumentId"
														id={instrument.instrumentId}
														value={instrument.instrumentId}
													/>{" "}
													<label htmlFor={instrument.instrumentId}>
														{instrument.instrumentName}
													</label>
												</li>
											);
										}
									)}
								</ul>
							</div>
						</div>
						<button
							id="addToCart"
							className={chosenInstrument ? "cursorPoint" : "deactivated"}
							disabled={chosenInstrument === null}
						>
							Add to Cart
						</button>
					</form>
				)}
			</>
		</Modal>
	);
}

import FileType from "../../../Types/FileType";
import File from "./File/File";

type Props = {
	files: FileType[];
	fileModalOpenHandler: Function;
	shareFileModalOpenHandler: Function;
	openFile: Function;
	deleteFile: Function;
	FAT?: string;
};

export default function FileList(props: Props) {
	return (
		<ul id="fileList">
			{Array.from(props.files).map((item: any) => {
				return (
					<div key={item.id}>
						<File
							file={item}
							fileModalOpenHandler={props.fileModalOpenHandler}
							shareFileModalOpenHandler={props.shareFileModalOpenHandler}
							openFile={props.openFile}
							deleteFile={props.deleteFile}
							FAT={props.FAT}
						/>
						<hr />
					</div>
				);
			})}
		</ul>
	);
}

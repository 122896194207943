import InstructorType from "../../../Types/InstructorType";
import Instructor from "./Instructor";
import "./InstructorList.scss";

type Props = {
	instructors: InstructorType[];
};
function InstructorList(props: Props) {
	return (
		<ul id="instructorList">
			{Array.from(props.instructors).map((instructor: InstructorType) => {
				return <Instructor key={instructor.userId} instructor={instructor} />;
			})}
		</ul>
	);
}

export default InstructorList;

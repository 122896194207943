import { useEffect, useRef } from 'react'
import './TimePickerListItem.scss'

type Props = {
    value: any,
    setTime: Function,
    setShowDropdown: Function,
    isSelected: boolean
}
export default function TimePickerListItem(props: Props) {
    const listElement = useRef<HTMLLIElement>(null);
    function onClickHandler(): void {
        props.setTime(props.value);
        props.setShowDropdown(false);
    }

    function onMouseOverHandler(): void {
        if (listElement.current?.className === '') 
            listElement.current.className = 'selected';
    }
    function onMouseLeaveHandler(): void {
        if (listElement.current?.className === 'selected' && !props.isSelected) 
            listElement.current.className = '';
    }

    useEffect(() => {
        if (props.isSelected)
            listElement.current?.scrollIntoView();
    }, [listElement, props.isSelected])

    return (
        <li ref={listElement} onMouseLeave={onMouseLeaveHandler} onMouseOver={onMouseOverHandler} className={'cursorPoint' + props.isSelected ? 'selected timeListElement' : 'timeListElement'} onClick={onClickHandler}>
            {props.value}
        </li>
    )
}

// import React, { Component } from 'react';
import ConversationHeader from './ConversationHeader/ConversationHeader';
import ConversationList from './ConversationList/ConversationList';
import './Messages.scss'
import { useEffect, useState } from 'react';
import config from '../../utils/config';

export default function ConversationsPage() {

  const [showMessageList, setShowMessageList] = useState(false);
  const [talkingToId, setTalkingToId] = useState(-1);
  const [, setMessages] = useState(Object); 

  useEffect(() => {
    document.title = config.MESSAGE_DOC_TITLE;
  }, [])

  return (
    <div className="gridContainer">
      <div className="fullScreen">
        {!showMessageList && <ConversationHeader />}
        {!showMessageList &&
          <ConversationList
            talkingToId={talkingToId}
            showMessageList={showMessageList}
            setTalkingToId={setTalkingToId}
            setShowMessageList={setShowMessageList}
            setMessages={setMessages}
          />}

      </div>
    </div>
  )
}

import './ErrorPage.scss';
import WhiteButton from '../Buttons/WhiteButton/WhiteButton';
import Gramophone from "../../images/gramophone.svg"
import Record from "../../images/record.svg";
import { useNavigate } from "react-router-dom";
import { useEffect } from 'react';
import config from '../../utils/config';

export default function ErrorPage() {
  const navigate = useNavigate();

  useEffect(() => {
    document.title = config.NOT_FOUND_DOC_TITLE;
  }, [])
  

  function returnHome() {
    navigate("/");
  }

  return (
    <div id="errorContainer" className='gridContainer'>
      <div id="errorContent">
        <div id="leftContent">
          <img id='gramophone' src={Gramophone} alt="Gramophone" />
          <WhiteButton id="errorButton" text="Return to Home" onClick={returnHome} />
        </div>
        <img id='record' src={Record} alt="record" />
      </div>
    </div>
  )
}

import { AxiosRequestHeaders } from "axios";
import { Cookies } from "react-cookie";

export default function authHeader(): AxiosRequestHeaders {
    const cookies = new Cookies();
    const token = cookies.get('token_id');
    if (token) {
      return { Authorization: 'Bearer ' + token };
    } else {
      return {};
    }
  }
import React, { useState } from 'react';
import GreenButton from '../../Buttons/GreenButton/GreenButton';
import NewMessageModal from '../NewMessageModal/NewMessageModal';

export default function ConversationHeader() {
  const [showNewMessage, setShowNewMessageHandler] = useState(false);

  function showNewMessageHandler() {
    setShowNewMessageHandler(!showNewMessage);
  }

  return (
      <>
        <div id="messageListHeader">
          <h3>Messages</h3>
          <GreenButton text={'New Message'} onClick={showNewMessageHandler}></GreenButton>
        </div>
        <hr />
        {showNewMessage &&
          <NewMessageModal exitCallback={showNewMessageHandler} />
        }
      </>
  );
}

import { useState } from 'react'
import PaymentFormDataType from '../../../Types/PaymentFormDataType';
import ExistingCardList from '../ExistingCard/ExistingCardList';
import NewCard from '../NewCard/NewCard'

type Props = {
    setStep: Function,
    step: number,
    stripePromise: Object,
    cardList: Array<any>,
    setSaveCard: Function,
    setIsLoading: Function,
    setExistingCardPaymentMethod: Function,
    setFormData: Function,
    setNewCardDetails: Function,
    formData: PaymentFormDataType,
    setLast4: Function,
    setExp: Function,
    formDisabled: boolean
}
function PaymentStep(props: Props) {
    const [newCard, setNewCard] = useState(true);
    const [nextActivated, setNextActivated] = useState(false);
    const [paymentMethod, setPaymentMethod] = useState("");

    async function nextStepHandler() {
        props.setIsLoading(true);
        if (!newCard) {
            await props.setExistingCardPaymentMethod(paymentMethod);
            props.setStep(props.step + 2);
        }
        else {
            props.setStep(props.step + 1);
            props.setNewCardDetails();
        }
        props.setIsLoading(false);
    }

    function cardButtonsHandler(event: React.MouseEvent<HTMLButtonElement> ) {
        switch (event.currentTarget.id){
            case 'newCardButton':
                setNewCard(true);
                break;
            case 'existingCardButton':
                setNewCard(false);
                break;
        }
        setNextActivated(false);
        setPaymentMethod("");
        props.setExistingCardPaymentMethod("");
        props.setFormData({
            firstNameBilling: "",
            lastNameBilling: "",
            streetAddressLineOneBilling: "",
            streetAddressLineTwoBilling: "",
            cityBilling: "",
            stateBilling: "",
            zipcodeBilling: ""
        });
        props.setExp('');
        props.setLast4('');
    }

    function nextActivatedHandler(canActivateNextButton: boolean) {
        setNextActivated(canActivateNextButton);
    }

    return (
        <div id="paymentDiv" className={props.step === 0 ? '' : 'hidden'}>
            <h2>Payment</h2>
            <div id="paymentInputs">
                <div id="cardButtons">
                    <button type="button" id="newCardButton" className={newCard ? 'selected' : ''} onClick={cardButtonsHandler}>New
                        Card</button>
                    <button disabled={props.formDisabled} type="button" id="existingCardButton" className={!newCard ? 'selected' : ''} onClick={cardButtonsHandler}>Existing
                        Card</button>
                </div>
                <div className={newCard ? '' : 'hidden'}>
                    <NewCard
                        stripePromise={props.stripePromise}
                        nextActivatedHandler={nextActivatedHandler}
                        setSaveCard={props.setSaveCard}
                        formDisabled={props.formDisabled}
                    />
                </div>
                {!newCard &&
                    <ExistingCardList
                        cardList={props.cardList}
                        paymentMethod={paymentMethod}
                        setPaymentMethod={setPaymentMethod}
                        nextActivatedHandler={nextActivatedHandler}
                        setFormData={props.setFormData}
                        formData={props.formData}
                        setLast4={props.setLast4}
                        setExp={props.setExp}
                    />
                }
            </div>
            <button type="button" className="invertedBtn viewCartBtn"><a href="/cart">View Cart</a></button>
            {/* 'deactivated' is the class to make this gray */}
            <button type="button" id="paymentNext" className={nextActivated ? 'activated cursorPoint' : 'deactivated'}
                onClick={nextStepHandler} disabled={!nextActivated}>Next</button>
        </div>
    )
}

export default PaymentStep
import React, { useContext, useState } from "react";
import FilterDropdown from "../../FilterDropdown/FilterDropdown";
import StudentContext from "../../../Contexts/StudentContext";
import LessonHistoryList from "./LessonHistoryList/LessonHistoryList";
import LessonType from "../../../Types/LessonType";

type Props = {
  pastLessons: LessonType[];
};

export default function LessonHistory(props: Props) {
  const isStudent = !useContext(StudentContext)?.isInstructor;
  const [filterOption, setFilterOption] = useState("");
  let filteredLessons: LessonType[] = [];

  function filterLessons() {
    let filteredArray: LessonType[] = [];
    switch (filterOption) {
      case "mostRecent":
        filteredArray = props.pastLessons.sort(function (a, b) {
          return (
            new Date(b.chosenStart).getTime() -
            new Date(a.chosenStart).getTime()
          );
        });
        break;
      case "oldest":
        filteredArray = props.pastLessons.sort(function (a, b) {
          return (
            new Date(a.chosenStart).getTime() -
            new Date(b.chosenStart).getTime()
          );
        });
        break;
      case "AtoZ":
        if (isStudent) {
          filteredArray = props.pastLessons.sort(function (a, b) {
            var textA = a.available.instructor!.user!.firstName!.toUpperCase();
            var textB = b.available.instructor!.user!.firstName!.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        } else {
          filteredArray = props.pastLessons.sort(function (a, b) {
            var textA = a.user!.firstName!.toUpperCase();
            var textB = b.user!.firstName!.toUpperCase();
            return textA < textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        break;
      case "ZtoA":
        if (isStudent) {
          filteredArray = props.pastLessons.sort(function (a, b) {
            var textA = a.available.instructor!.user!.firstName!.toUpperCase();
            var textB = b.available.instructor!.user!.firstName!.toUpperCase();
            return textA > textB ? -1 : textA > textB ? 1 : 0;
          });
        } else {
          filteredArray = props.pastLessons.sort(function (a, b) {
            var textA = a.user!.firstName!.toUpperCase();
            var textB = b.user!.firstName!.toUpperCase();
            return textA > textB ? -1 : textA > textB ? 1 : 0;
          });
        }
        break;
      default:
        break;
    }
    return filteredArray;
  }

  filteredLessons = filterLessons();

  return (
    <div id="paymentHistory" className="gridTallLeftMain">
      <h2 id="historyHeading">Lesson History</h2>
      <FilterDropdown setFilterOption={setFilterOption} />
      <hr />
      <LessonHistoryList
        pastLessons={filteredLessons}
        filterOption={filterOption}
      />
    </div>
  );
}

import "./ExitIcon.scss";

type Props = {
	exitCallback: Function;
	confirmExit?: boolean;
};
export default function ExitIcon(props: Props) {
	function handleClick() {
		if (props.confirmExit) {
			if (
				window.confirm(
					"You will lose all of your progress. Are you sure you want to exit?"
				)
			) {
				props.exitCallback();
			}
		} else props.exitCallback();
	}
	return <i onClick={handleClick} className="xImg" />;
}

import axios from "axios";
import AvailableType from "../Types/AvailableType";
import CreateAvailableRequestType from "../Types/CreateAvailableRequestType";
import config from "../utils/config";

class AvailableService {
	static async getInstructorAvailables(id: number): Promise<AvailableType[]> {
		return (
			await axios.get(config.API_URL + "/api/available/instructor/" + id, {
				withCredentials: true,
			})
		).data;
	}

	static async createAvailable(
		available: CreateAvailableRequestType
	): Promise<any> {
		var result = await axios
			.post(config.API_URL + "/api/available", available, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.response.status;
			});
		return result;
	}

	static async editAvailable(
		id: number,
		available: CreateAvailableRequestType
	): Promise<any> {
		return await axios
			.put(config.API_URL + `/api/available/${id}`, available, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.status;
			});
	}

	static deleteAvailable(lessonId: number): Promise<String> {
		return axios.delete(config.API_URL + "/api/available/" + lessonId, {
			withCredentials: true,
		});
	}
}

export default AvailableService;

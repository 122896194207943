import axios from "axios";
import LessonType from "../Types/LessonType";
import config from "../utils/config";
import PreviousLessonType from "../Types/PreviousLessonType";

class LessonService {
	static async getLessons(): Promise<LessonType[]> {
		return (
			await axios.get(config.API_URL + "/api/lesson", {
				withCredentials: true,
			})
		).data;
	}

	static async getLesson(id: number): Promise<LessonType> {
		return (
			await axios.get(config.API_URL + `/api/lesson/${id}`, {
				withCredentials: true,
			})
		).data;
	}

	static async getPastLessons(): Promise<LessonType[]> {
		return (
			await axios.get(config.API_URL + "/api/lesson/history", {
				withCredentials: true,
			})
		).data;
	}

	static async getPreviousLesson(): Promise<PreviousLessonType> {
		return (
			await axios.get(config.API_URL + "/api/lesson/previous", {
				withCredentials: true,
			})
		).data;
	}

	static async addNotesToLesson(id: number, notes: string): Promise<any> {
		return (
			await axios.put(
				config.API_URL + "/api/lesson/" + id,
				{ notes: notes },
				{
					withCredentials: true,
				}
			)
		).status;
	}

	static async getFutureLessons(): Promise<LessonType[]> {
		return (
			await axios.get(config.API_URL + "/api/lesson/future", {
				withCredentials: true,
			})
		).data;
	}

	static deleteLesson(lessonId: number): Promise<String> {
		return axios.delete(config.API_URL + "/api/lesson/" + lessonId, {
			withCredentials: true,
		});
	}
}

export default LessonService;

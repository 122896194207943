import './Cart.scss'
import moment from "moment";
import React from "react";

type Props = {
    lessonId: number,
    firstName: string,
    lastName: string,
    price: string,
    date: string,
    startTime: string,
    endTime: string,
    handleRemove: Function
}

export default function Cart( props: Props ) {

    function onClickHandler(): void {
        props.handleRemove(props.lessonId);
      }

    return (
        <>
            <li>
                <div className='cartItem'>
                    <div className='cartInstructorName'>Lesson with {props.firstName} {props.lastName}</div>
                    <div className='itemPrice'><p>${props.price}</p></div>
                    <div className='itemDate'><p>{moment(props.date).format("dddd, MMMM Do YYYY")}</p></div>
                    <div className='itemTime'><p>{moment(props.startTime).format("h:mm a")} - {moment(props.endTime).format("h:mm a")}</p></div>
                    <div className='removeButton'><button onClick={onClickHandler}>Remove</button></div>
                    <hr />
                </div>
            </li>
        </>
    )
}

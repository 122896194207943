import axios from "axios";
import CalendarEventType from "../Types/CalendarEventType";
import config from "../utils/config";

class CalendarService {
	static async getCalendarEvents(id: number): Promise<CalendarEventType> {
		return (
			await axios.get(config.API_URL + "/api/calendar/events/" + id, {
				withCredentials: true,
			})
		).data;
	}
}

export default CalendarService;

import moment from 'moment';
import React, { useState } from 'react'
import AvailableType from '../../../Types/AvailableType';
import AddToCartModal from '../../AddToCartModal/AddToCartModal';

type Props = {
    id: number,
    available: AvailableType,
    deleteFromAvailableList: Function
}
function InstructorLesson(props: Props) {
    const [modalOpen, setModalOpen] = useState(false);

    function modalButtonHandler() {
        setModalOpen(!modalOpen);
    }

    return (
        <>
            <li className='cursorPOint greenText' onClick={modalButtonHandler}>
                <p>${parseFloat(props.available.price)}</p>
                <p>{moment(props.available.startTime).format("h:mm a")} - {moment(props.available.endTime).format("h:mm a")}</p>
                <p>Add to cart</p>
            </li>
            {modalOpen && (
                <AddToCartModal
                    id={props.id}
                    available={props.available}
                    exitCallback={modalButtonHandler}
                    deleteFromAvailableList={props.deleteFromAvailableList}
                />
            )}
        </>
    )
}

export default InstructorLesson
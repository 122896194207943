import PaymentCart from "./PaymentCart/PaymentCart";
import Steps from "./Steps/Steps";
import "./PaymentPage.scss";
import { useEffect, useState } from "react";
import PostPaymentMessage from "./PostPaymentMessage/PostPaymentMessage";
import PaymentService from "../../services/payment.service";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CartService from "../../services/cart.service";
import CartType from "../../Types/CartType";
import PaymentLoading from "./PaymentLoading/PaymentLoading";
import PaymentForm from "./PaymentForm/PaymentForm";
import config from "../../utils/config";

const stripePromise = loadStripe(
	"pk_test_51H88DyEaBJLClmCVSDHosmSNZZdZ8CQL8ES06TawootvKYfI7Imw34FiIvQbE9x5qoOCwgrDrRJNVqjy1PnYyMdn00SSlMzxPn"
);

function PaymentPage() {
	const [step, setStep] = useState<number>(0);
	const [isComplete, setIsComplete] = useState(false);
	const [isSuccessful, setIsSuccessful] = useState(false);
	const [message, setMessage] = useState("");
	const [isLoading, setIsLoading] = useState(false);
	const [cardList, setCardList] = useState([]);
	const [cart, setCart] = useState<CartType[]>([]);
	const [priceString, setPriceString] = useState("");

	useEffect(() => {
		document.title = config.PAYMENT_DOC_TITLE;
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	// Get payment intent from API
	async function fetchData() {
		setIsLoading(true);
		const cart: CartType[] = await CartService.getCarts();
		setCart(cart);
		if (cart.length !== 0) {
			let cards: any = await PaymentService.getPaymentMethods();
			setCardList(cards.paymentMethod);
			calculatePrice(cart);
		}
		setIsLoading(false);
	}

	function calculatePrice(cart: CartType[]) {
		var totalPrice = 0.0;
		var format = new Intl.NumberFormat("en-US", {
			style: "currency",
			currency: "USD",
			minimumFractionDigits: 2,
		});
		cart.forEach((cart) => {
			totalPrice += parseFloat(cart.price);
		});
		setPriceString(format.format(totalPrice));
	}

	return (
		<div className="gridContainer">
			<div className="fullScreen fullScreenAdjusted" id="paymentPage">
				<>
					<PaymentCart
						cart={cart}
						setCart={setCart}
						fetchData={fetchData}
						isLoading={isLoading}
						isComplete={isComplete}
						priceString={priceString}
					/>

					<div className="vr"></div>

					<div className="split right">
						<Steps step={step} />

						{!isComplete && (
							<Elements stripe={stripePromise}>
								<div
									className={isLoading ? "hidden" : ""}
									id="paymentFormContainer"
								>
									<PaymentForm
										step={step}
										setStep={setStep}
										setIsLoading={setIsLoading}
										setIsComplete={setIsComplete}
										priceString={priceString}
										stripePromise={stripePromise}
										cardList={cardList}
										setMessage={setMessage}
										setIsSuccessful={setIsSuccessful}
										formDisabled={cart.length === 0}
									/>
								</div>
							</Elements>
						)}
						{isLoading && <PaymentLoading />}
						{isComplete && (
							<PostPaymentMessage
								isSuccessful={isSuccessful}
								errorMessage={message}
								cartLength={cart.length}
								priceString={priceString}
							/>
						)}
					</div>
				</>
			</div>
		</div>
	);
}

export default PaymentPage;

import axios from "axios";
import config from "../utils/config";

export default class AuthService {
	static async verifyToken(): Promise<boolean> {
		const result = await axios
			.get(config.API_URL + "/login/verify", {
				withCredentials: true,
			})
			.then((response) => {
				if (response.status === 200) return true;
				else return false;
			})
			.catch((err) => {
				console.log(err);
				console.error(err);
				return false;
			});

		return result;
	}
}

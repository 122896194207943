import { useEffect, useRef, useState } from 'react';
import whitePlusIcon from '../../../../images/whitePlusIcon.png';
import AddFileDropdown from '../AddFileDropdown/AddFileDropdown';
import ReactDOM from 'react-dom';
import { useNavigate } from "react-router-dom";
import BlackButton from '../../../Buttons/BlackButton/BlackButton';


type Props = {
    folderName?: string,
    fileModalOpenHandler: Function
}
export default function FilesPageHeader(props: Props) {
    const [showAddFileDropdown, setShowAddFileDropdown] = useState(false);
    const addButtonRef = useRef(null)
    const dropdownRef = useRef(null);
    const navigate = useNavigate();

    
    function showAddFileDropdownHandler() {
        setShowAddFileDropdown(!showAddFileDropdown);
    }

    function handleMouseDown(event: MouseEvent) {
        const addButtonNode = ReactDOM.findDOMNode(addButtonRef.current);
        if (addButtonRef && event.target !== addButtonRef.current &&
            !addButtonNode?.contains(event.target as Node)) {
            setShowAddFileDropdown(false);
        }
    }

    function toFiles() {
        navigate("/files");
        window.location.reload();
    }

    function toSharedFiles() {
        navigate("/files/shared");
        window.location.reload();
    }

    useEffect(() => {
        document.addEventListener('click', handleMouseDown);
        return function cleanup() {
            document.removeEventListener('click', handleMouseDown);
        };
    });

    return (
        <div id='filesHeader'>
            <div id='headerName'>
                <h2>Files</h2>
                {props.folderName &&
                    <h4 id='folderName'>- {props.folderName}</h4>
                }
            </div>
            <div className='upperLeftButtons'>
                <BlackButton text="My Files" onClick={toFiles} />
                <BlackButton text="Shared with Me" onClick={toSharedFiles} />
                <div ref={addButtonRef} id="uploadDiv" onClick={showAddFileDropdownHandler} className='cursorPoint'>
                    <p>Add</p>
                    <img id="uploadIcon" src={whitePlusIcon} alt="Add Icon" />
                </div>
            </div>
            {showAddFileDropdown &&
                <AddFileDropdown
                    ref={dropdownRef} 
                    fileModalOpenHandler={props.fileModalOpenHandler}
                />
            }
        </div>
    )
}

import axios from "axios";
import MessageType from "../Types/MessageType";

import config from "../utils/config";

class MessageService {
	static async getConversationList(): Promise<MessageType[]> {
		return (
			await axios.get(config.API_URL + "/api/message", {
				withCredentials: true,
			})
		).data;
	}

	static async getMessageToUser(id: number): Promise<MessageType[]> {
		return (
			await axios.get(config.API_URL + "/api/message/" + id, {
				withCredentials: true,
			})
		).data;
	}

	static async sendMessage(
		senderId: number,
		receiverId: number,
		body: string,
		isInstructorSender: boolean,
		id?: number | null
	): Promise<any> {
		return (
			await axios.post(
				config.API_URL + "/api/message",
				{
					id: id || null,
					senderId: senderId,
					receiverId: receiverId,
					body: body,
					isInstructorSender: isInstructorSender,
				},
				{
					withCredentials: true,
				}
			)
		).data;
	}
}

export default MessageService;

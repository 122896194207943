import { useContext } from 'react'
import StudentContext from '../../../../../Contexts/StudentContext';
import UserType from '../../../../../Types/UserType'

type Props = {
    pastUser: UserType,
}

export default function PastUser( props: Props ) {
    const isStudent = !useContext(StudentContext)?.isInstructor;

    return(
        <div className="history">
            <p>{props.pastUser.firstName} {props.pastUser.lastName}</p>
            { isStudent && 
                <div>
                    <a href={'/instructors/' + props.pastUser.id}>View Availability</a>
                </div>
            }

            <hr />
        </div>
    )
}
import { useEffect, useState } from "react";
import AddFileModal from "./AddFileModal";
import FileList from "./FileList/FileList";
import FilesPageHeader from "./FilesPageHeader/FilesPageHeader/FilesPageHeader";
import "./FilePage.scss";
import ShareFileModal from "./ShareFileModal";
import config from "../../utils/config";
import FileService from "../../services/file.service";
import FileType from "../../Types/FileType";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import "./../../scss/_classes.scss";
import GetFilesResponseType from "../../Types/GetFilesResponseType";

export default function FilePage() {
	const { folderId } = useParams();
	const [searchParams] = useSearchParams();
	const [fileId, setFileId] = useState(0);
	const [fileName, setFileName] = useState("");
	const [addFileModalOpen, setAddFileModalOpen] = useState(false);
	const [addFolderModalOpen, setAddFolderModalOpen] = useState(false);
	const [editFileModalOpen, setEditFileModalOpen] = useState(false);
	const [editFolderModalOpen, setEditFolderModalOpen] = useState(false);
	const [shareFileModalOpen, setShareFileModalOpen] = useState(false);
	const [filePath, setFilePath] = useState("");
	const [files, setFiles] = useState<FileType[]>([]);
	const location = useLocation();
	const [FAT, setFAt] = useState<string | undefined>(undefined);

	function populateFileList() {
		if (location.pathname !== "/files/shared") {
			FileService.getFiles(folderId, searchParams.get("FAT") ?? undefined).then(
				(value: GetFilesResponseType | number) => {
					if ((typeof value === "number" && value === 404) || value === 401)
						return (window.location.href = "/error");
					if (typeof value === "object") {
						setFiles(value.files);
						setFAt(value.fileAccessToken);
					}
				}
			);
		} else {
			FileService.getSharedFiles().then((value: FileType[]) => {
				setFiles(value);
			});
		}
	}

	useEffect(() => {
		document.title = config.FILES_DOC_TITLE;
		populateFileList();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		let fileNumber = Number(
			location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
		);
		if (!isNaN(fileNumber)) {
			FileService.getFileMetadata(fileNumber).then((value: FileType) => {
				if (value) {
					setFilePath(value.displayName);
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function fileModalOpenHandler(modalType: string, id: number = 0) {
		setFileId(id);
		switch (modalType) {
			case "file":
				setAddFileModalOpen(!addFileModalOpen);
				break;
			case "folder":
				setAddFolderModalOpen(!addFolderModalOpen);
				break;
			case "editFile":
				setEditFileModalOpen(!editFileModalOpen);
				break;
			case "editFolder":
				setEditFolderModalOpen(!editFolderModalOpen);
				break;
			default:
				setAddFileModalOpen(!addFileModalOpen);
				break;
		}
		populateFileList();
	}

	function shareFileModalOpenHandler(fileName: string, id: number) {
		setFileName(fileName);
		setFileId(id);
		setShareFileModalOpen(!shareFileModalOpen);
	}

	async function deleteFile(fileId: number) {
		if (window.confirm("Are you sure you would like to delete this file?")) {
			const result = await FileService.deleteFile(fileId);
			switch (result.status) {
				case 204:
					setFiles(files.filter((a) => a.id !== fileId));
					break;
				case 400:
					window.alert(result.data);
					break;
				case 500:
					window.alert("Something went wrong processing your request");
					break;
			}
		}
	}

	function openFile(fileId: number) {
		FileService.getFileUrl(fileId, FAT).then((url: string) => {
			window.open(url, "_blank");
		});
	}

	return (
		<div className="gridContainer">
			<div id="fileContainer" className="fullScreen">
				<FilesPageHeader
					folderName={filePath}
					fileModalOpenHandler={fileModalOpenHandler}
				/>
				<hr />
				<FileList
					files={files}
					fileModalOpenHandler={fileModalOpenHandler}
					shareFileModalOpenHandler={shareFileModalOpenHandler}
					openFile={openFile}
					deleteFile={deleteFile}
					FAT={FAT}
				/>
			</div>
			{addFileModalOpen && (
				<AddFileModal
					modalType={"file"}
					fileId={fileId}
					exitCallback={() => fileModalOpenHandler("file")}
				/>
			)}
			{addFolderModalOpen && (
				<AddFileModal
					modalType={"folder"}
					fileId={fileId}
					exitCallback={() => fileModalOpenHandler("folder")}
				/>
			)}
			{editFileModalOpen && (
				<AddFileModal
					modalType={"editFile"}
					fileId={fileId}
					exitCallback={() => fileModalOpenHandler("editFile")}
				/>
			)}
			{editFolderModalOpen && (
				<AddFileModal
					modalType={"editFolder"}
					fileId={fileId}
					exitCallback={() => fileModalOpenHandler("editFolder")}
				/>
			)}
			{shareFileModalOpen && (
				<ShareFileModal
					fileName={fileName}
					fileId={fileId}
					exitCallback={shareFileModalOpenHandler}
				/>
			)}
		</div>
	);
}

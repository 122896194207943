import React, { useContext, useEffect, useState } from "react";
import moment from "moment";
import LessonType from "../../../../../Types/LessonType";
import StudentContext from "../../../../../Contexts/StudentContext";
import LessonModal from "../../../../LessonModal/LessonModal";
import Modal from "../../../../Modal/Modal";
import ModalHeader from "../../../../Modal/ModalHeader/ModalHeader";

type Props = {
  pastLesson: LessonType;
};

export default function PastLesson(props: Props) {
  const isStudent = !useContext(StudentContext)?.isInstructor;
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [showLessonDetailsModal, setShowLessonDetailsModal] = useState(false);

  function modalHandler() {
    setShowLessonDetailsModal(!showLessonDetailsModal);
  }

  useEffect(() => {
    if (isStudent) {
      setFirstName(props.pastLesson.available.instructor!.user!.firstName!);
      setLastName(props.pastLesson.available.instructor!.user!.lastName!);
    } else {
      setFirstName(props.pastLesson.user!.firstName!);
      setLastName(props.pastLesson.user!.lastName!);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {showLessonDetailsModal && (
        <Modal exitCallback={modalHandler}>
          <ModalHeader headerText={`Booked Lesson`} />
          <LessonModal
            title={`Booked Lesson`}
            start={props.pastLesson.chosenStart}
            end={props.pastLesson.chosenEnd}
            id={props.pastLesson.lessonId}
            notes={props.pastLesson.notes!}
            meetLink={props.pastLesson.meetLink}
            studentName={
              props.pastLesson.user
                ? props.pastLesson.user?.firstName! +
                  " " +
                  props.pastLesson.user?.lastName!
                : props.pastLesson.available?.instructor?.user?.firstName! +
                  " " +
                  props.pastLesson.available?.instructor?.user?.lastName!
            }
            instrumentList={props.pastLesson.instrumentName!}
            instructorName={props.pastLesson.instructorName!}
            lessonPrice={parseFloat(props.pastLesson.price)}
            reservedFor={""}
            addressLineOne={
              props.pastLesson.available?.address?.lineOne ?? null
            }
            addressLineTwo={
              props.pastLesson.available?.address?.lineTwo ?? null
            }
            addressZipCode={
              props.pastLesson.available?.address?.zipCode ?? null
            }
            addressCity={props.pastLesson.available?.address?.city ?? null}
            addressState={props.pastLesson.available?.address?.state ?? null}
            addressDescription={
              props.pastLesson.available?.address?.description ?? null
            }
          />
        </Modal>
      )}
      <div className="edit-view" id="lessonId">
        <p>
          {moment(props.pastLesson.chosenStart).format("dddd, MMMM Do YYYY")} @{" "}
          {moment(props.pastLesson.chosenStart).format("hh:mm a")}
        </p>
        <p>
          {firstName} {lastName} - ${props.pastLesson.price}
        </p>
      </div>
      <button className="bareButton greenText" onClick={modalHandler}>
        See More
      </button>
      <hr />
    </>
  );
}

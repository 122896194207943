import axios from "axios";
import NotificationType from "../Types/NotificationType";
import config from "../utils/config";

class NotificationService {
	static getNotifications(): Promise<NotificationType[]> {
		return axios
			.get(config.API_URL + "/api/notification", {
				withCredentials: true,
			})
			.then((notification) => {
				return notification.data;
			});
	}

	static deleteNotifications(): Promise<any> {
		return axios
			.delete(config.API_URL + "/api/notification", {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			});
	}

	static deleteNotification(id: number): Promise<any> {
		return axios
			.delete(config.API_URL + "/api/notification/" + id, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			});
	}
}

export default NotificationService;

import './BlackButton.scss'

type Props = {
    text: string,
    onClick: Function
}
export default function BlackButton(props: Props) {
    function onClickHandler() {
        props.onClick();
    }
    return (
        <button onClick={onClickHandler} className="blackButton">
            {props.text}
        </button>
    )
}

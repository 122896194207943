import axios from "axios";
import SettingsType from "../Types/SettingsType";
import authHeader from "./auth-header";
import config from "../utils/config";

class SettingsService {
	static async getSettings(): Promise<SettingsType> {
		return (
			await axios.get(config.API_URL + "/api/settings", {
				withCredentials: true,
			})
		).data;
	}
	static async setSettings(settings: FormData): Promise<Object> {
		const headers = authHeader();
		headers["content-type"] = "multipart/form-data";
		return await axios
			.put(config.API_URL + "/api/settings", settings, {
				withCredentials: true,
			})
			.then((result) => result.status)
			.catch((err) => err.status);
	}
}

export default SettingsService;

import React from "react";
import LessonType from "../../../../Types/LessonType";
import PastLesson from "./PastLesson/PastLesson";

type Props = {
  pastLessons: LessonType[];
  filterOption: string;
};

export default function LessonHistoryList(props: Props) {
  return (
    <div className="history" id="lessonHistory">
      {props.pastLessons.length > 0 && (
        <>
          {Array.from(props.pastLessons).map((pastLesson: LessonType) => {
            return (
              <PastLesson key={pastLesson.lessonId} pastLesson={pastLesson} />
            );
          })}
          <p>
            <a href="/calendar">View Calendar</a>
          </p>
        </>
      )}

      {!(props.pastLessons.length > 0) && (
        <p className="noContentToShow">You have not had a lesson yet!</p>
      )}
    </div>
  );
}

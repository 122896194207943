import "./InstructorLessonList.scss";
import InstructorLesson from "./InstructorLesson/InstructorLesson";
import InstructorService from "../../services/instructor.service";
import InstructorType from "../../Types/InstructorType";
import AvailableService from "../../services/available.service";
import AvailableType from "../../Types/AvailableType";
import moment from "moment";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import CartService from "../../services/cart.service";
import CartType from "../../Types/CartType";
import config from "../../utils/config";
import InstructorProfileHeader from "../InstructorProfile/InstructorProfileHeader";

function InstructorLessonList() {
	const { instructorId } = useParams();
	const [instructor, setInstructor] = useState<InstructorType>(Object);
	const [availables, setAvailables] = useState<AvailableType[]>(Object);
	const [cartLength, setCartLength] = useState(0);
	const navigate = useNavigate();
	let dateCheck: string = "";
	let numDays = 0;

	const [, setCarts] = useState(Object);

	useEffect(() => {
		CartService.getCarts()
			.then((value: CartType[]) => {
				setCarts(value);
				setCartLength(value.length);
			})
			.catch((err) => {
				console.error(err);
				setCartLength(0);
			});
	}, []);

	useEffect(() => {
		document.title = `Instructor Lessons ${config.DOC_TITLE_SUFFIX}`;

		try {
			InstructorService.getInstructor(parseInt(instructorId!)).then(
				(instructor: InstructorType) => {
					setInstructor(instructor);
					document.title = `${
						instructor.firstName + " " + instructor.lastName
					} Lessons ${config.DOC_TITLE_SUFFIX}`;
				}
			);
		} catch (err) {
			console.log(err);
			navigate("/error");
		}
	}, [instructorId, navigate]);

	useEffect(() => {
		AvailableService.getInstructorAvailables(parseInt(instructorId!)).then(
			(value: AvailableType[]) => {
				setAvailables(value);
			}
		);
	}, [instructorId]);

	function deleteFromAvailableList(id: number) {
		setAvailables(availables.filter((a) => a.id !== id));
		setCartLength(cartLength + 1);
	}

	function dateChange(date: string) {
		if (dateCheck === date) {
			return false;
		} else {
			dateCheck = date;
			return true;
		}
	}

	return (
		<div className="content gridContainer">
			<div className="fullScreen" id="instructorLessonList">
				<InstructorProfileHeader
					cartLength={cartLength}
					instructor={instructor}
				/>

				<div id="lessonListContainer">
					<ul id="lessonsAvailable">
						{availables.length > 0 &&
							Array.from(availables).map((item: any) => {
								let dateChanged = false;
								if (
									dateChange(
										moment(item.startTime).format("dddd, MMMM Do YYYY")
									)
								) {
									numDays++;
									dateChanged = true;
								}
								return (
									<div key={numDays}>
										{dateChanged && (
											<>
												<hr />
												<h4>
													{moment(item.startTime).format("dddd, MMMM Do YYYY")}
												</h4>
											</>
										)}
										<InstructorLesson
											key={item.id}
											id={item.id}
											available={item}
											deleteFromAvailableList={deleteFromAvailableList}
										/>
									</div>
								);
							})}
						{!(availables.length > 0) && (
							<>
								<hr />
								<p>This instructor currently has no available lessons.</p>
							</>
						)}

						<hr />
					</ul>
				</div>
			</div>

			{/* <div id="availableDiv" class="hidden">
            <div class="overlayContent">
                <div class="header">
                    <h2>Lesson Details</h2>
                    <img id="xImg cursorPoint" src="/images/x-icon.png" alt="X Icon" onclick="confirmXInfo()">
                </div>
                <hr>
                <form id="availableForm" method="POST">
                    <div class="centerContent">
                        <div id="availableInfo"></div>
                        <div id="availableLocation"></div>
                        <div id="availableInstruments"></div>
                    </div>
                    <button id="addToCart">Add to Cart</button>
                </form>
            </div>
        </div> */}
		</div>
	);
}

export default InstructorLessonList;

import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";
import LoadingPage from "./LoadingPage/LoadingPage";
import config from "../utils/config";
import { useLocation, useNavigate } from "react-router-dom";
import UserType from "../Types/UserType";
import AuthService from "../services/auth.service";
import StudentContext from "../Contexts/StudentContext";
import UserService from "../services/user.service";
import RefreshUserContext from "../Contexts/UserRefreshContext";

type Props = {
	children: JSX.Element | JSX.Element[];
};
function ProtectedRoute(props: Props) {
	const [, setCookie] = useCookies();
	const [loading, setloading] = useState(true);
	const [user, setUser] = useState<UserType | undefined>(undefined);
	const path = useLocation();
	const navigate = useNavigate();

	useEffect(() => {
		checkAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function checkAccountStatus(user: UserType) {
		if (
			path.pathname !== "/dashboard" &&
			(!user?.agreedToTerms ||
				(user.isInstructor === true &&
					(!user.stripeAccountCreated ||
						!user.stripeAccountOnboarded ||
						!user.isOnboarded)))
		) {
			navigate("/dashboard");
		}
	}

	async function checkAuth() {
		var result = await AuthService.verifyToken();
		if (!result) {
			return window.location.replace(config.API_URL);
		}

		await refreshUserData();

		setloading(false);
	}

	async function refreshUserData() {
		await UserService.getUserData().then((userData) => {
			checkAccountStatus(userData);
			setUser(userData);
			setCookie("user", userData);
		});
	}

	return (
		<>
			{loading && <LoadingPage />}
			{!loading && (
				<StudentContext.Provider value={user}>
					<RefreshUserContext.Provider value={refreshUserData}>
						<>{props.children}</>
					</RefreshUserContext.Provider>
				</StudentContext.Provider>
			)}
		</>
	);
}

export default ProtectedRoute;

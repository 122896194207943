import "./StudentDashboardHeader.scss";
import WhiteButton from "../../Buttons/WhiteButton/WhiteButton";
import { useContext, useState } from "react";
import Modal from "../../Modal/Modal";
import StudentContext from "../../../Contexts/StudentContext";
import PreviousLessonType from "../../../Types/PreviousLessonType";
import LessonModal from "../../LessonModal/LessonModal";
import ModalHeader from "../../Modal/ModalHeader/ModalHeader";

type Props = {
	previousLesson: PreviousLessonType | undefined;
	studentFirstName: string;
};
export default function StudentDashboardHeader(props: Props) {
	const user = useContext(StudentContext);
	const [showLessonDetailsModal, setShowLessonDetailsModal] = useState(false);
	function toSchedule() {
		window.location.href = "/instructors";
	}

	function handleLessonDetailsModal() {
		setShowLessonDetailsModal(!showLessonDetailsModal);
	}

	console.log(props.previousLesson);

	return (
		<div className="lessonRecap gridHeader" id="studentDashHeader">
			{showLessonDetailsModal && (
				<Modal exitCallback={handleLessonDetailsModal}>
					<ModalHeader
						headerText={`Lesson with ${props.previousLesson?.instructorFirstName} ${props.previousLesson?.instructorLastName}`}
					/>
					<LessonModal
						title={`Lesson with ${props.previousLesson?.instructorFirstName} ${props.previousLesson?.instructorLastName}`}
						start={props.previousLesson!.start}
						end={props.previousLesson!.end}
						id={props.previousLesson!.id}
						notes={props.previousLesson!.notes!}
						meetLink={props.previousLesson!.meetLink}
						studentName={user?.firstName + " " + user?.lastName}
						instrumentList={props.previousLesson?.instrumentList!}
						instructorName={`${props.previousLesson?.instructorFirstName} ${props.previousLesson?.instructorLastName}`}
						lessonPrice={parseFloat(props.previousLesson?.lessonPrice!)}
						reservedFor={""}
						addressLineOne={props.previousLesson?.addressLineOne!}
						addressLineTwo={props.previousLesson?.addressLineTwo!}
						addressCity={props.previousLesson?.addressCity!}
						addressState={props.previousLesson?.addressState!}
						addressZipCode={props.previousLesson?.addressZipCode!}
						addressDescription={props.previousLesson?.addressDescription!}
						getCalendarEvents={undefined}
						setShowEventInfoModal={undefined}
						setShowEditAvailableModal={undefined}
						setCalendarEvents={undefined}
					/>
				</Modal>
			)}
			{props.previousLesson !== undefined && (
				<>
					<h2>Lesson Recap</h2>
					<hr />
					<div id="mainStudentPFP">
						<img
							src={props.previousLesson?.profilePicLink}
							alt="Instructor PFP"
						/>
					</div>
					{props.previousLesson?.notes !== null &&
						props.previousLesson?.notes?.length > 0 && (
							<>
								<h4>{props.previousLesson.instructorFirstName}'s Recap:</h4>

								<p> {props.previousLesson.notes}</p>
								<br />
								<button
									className="bareButton"
									onClick={handleLessonDetailsModal}
								>
									View Lesson
								</button>
							</>
						)}
					{(props.previousLesson?.notes === null ||
						props.previousLesson?.notes?.length === 0) && (
						<>
							<h4>
								{props.previousLesson.instructorFirstName} has not left any
								notes, be sure to check back later or{" "}
								<a
									id="sendMessage"
									href={"/message/" + props.previousLesson.instructorId}
								>
									send them a message
								</a>
								.
							</h4>
						</>
					)}
				</>
			)}
			{props.previousLesson === undefined && (
				<>
					<h2>Welcome, {props.studentFirstName}</h2>
					<hr />
					<p className="noContentToShow">
						You have not had a lesson yet, click below to find one.
					</p>
					<br />
					<WhiteButton
						id="headerFindLesson"
						text="Find Lessons"
						onClick={toSchedule}
					/>
				</>
			)}
		</div>
	);
}

import React from 'react';
import './Conversation.scss'

type Props = {
  imgSrc: string,
  talkingToName: string,
  talkingToId: number,
  preview: string,
  showMessageList: boolean,
  setTalkingToId: Function,
  setShowMessageList: Function,
  setMessages: Function
}

export default function Conversation(props: Props) {

  return (
    <>
      <a href={"/message/" + props.talkingToId}>
        <li className='Conversation cursorPoint'>
          <img src={props.imgSrc} alt="User's face" />
          <div>
            <span>
              <h4>{props.talkingToName}</h4>
              <p>{props.preview}</p>
            </span>
          </div>
        </li>
        <hr />
      </a>
    </>
  )
}

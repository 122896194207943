import CartService from '../../../services/cart.service';
import CartType from '../../../Types/CartType';
import Cart from './Cart/Cart';
import './CartList.scss'

type Props = {
    carts: CartType[],
    setCarts: Function
}

export default function CartList( props: Props ) {
    let total: number = 0;

    if (props.carts.length > 0) {
        total=(props.carts.reduce((total: number, currentItem: CartType) =>  total = total + parseInt(currentItem.price, 10) , 0 ));
    }

    function handleRemove(id: number): void {
        CartService.deleteCart(id)
            .then((status: number) => {
                if (status === 200) {
                    const newList = props.carts.filter((item: CartType) => item.lessonId !== id);
                    props.setCarts(newList);
                }
            });
    }

    return (
        <div>
            <ul className="cartList">
                {Array.from(props.carts).map((item: any) => {
                    return (
                        <Cart
                        key={item.lessonId}
                        lessonId={item.lessonId}
                        firstName={item.firstName}
                        lastName={item.lastName}
                        price={item.price}
                        date={item.chosenStart}
                        startTime={item.chosenStart}
                        endTime={item.chosenEnd}
                        handleRemove={handleRemove} />
                    )
                })}
            </ul>
            <div id='cartTotal'><p>Total: ${ total }</p></div>
        </div>
    )
}
import "./TermsAndConditions.scss";
import UserService from "../../../services/user.service";
import { useContext } from "react";
import RefreshUserContext from "../../../Contexts/UserRefreshContext";
import Modal from "../../Modal/Modal";
import ModalHeader from "../../Modal/ModalHeader/ModalHeader";

export default function TermsAndConditionsModal() {
	const refreshUserData = useContext(RefreshUserContext);

	async function onSubmit(event: any) {
		event.preventDefault();
		await UserService.postAgreeToTerms();
		await refreshUserData();
	}

	return (
		<Modal exitCallback={() => {}} canExit={false}>
			<div id="termsAndConditionsModal">
				<ModalHeader headerText={"Terms and Conditions"} />

				<form id="termsAndConditionsForm" onSubmit={onSubmit}>
					<hr />
					<p>
						By creating an account, you agree to the Cloud Music Suite{" "}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://storage.googleapis.com/cms-policies/User%20Agreement.pdf"
						>
							Terms of Service
						</a>{" "}
						and{" "}
						<a
							target="_blank"
							rel="noopener noreferrer"
							href="https://storage.googleapis.com/cms-policies/Privacy%20Policy%20.pdf"
						>
							Privacy Policy.
						</a>
					</p>
					<br />
					<input type="checkbox" id="agree" name="agree" required />
					<label htmlFor="agree"> I agree to the Terms and Conditions</label>
					<br />
					<input type="submit" value="Continue" />
				</form>
			</div>
		</Modal>
	);
}

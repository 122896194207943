import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CartService from '../../services/cart.service';
import CartType from '../../Types/CartType';
import GreenButton from '../Buttons/GreenButton/GreenButton';
import BlackButton from '../Buttons/BlackButton/BlackButton';
import CartList from './CartList/CartList';
import config from '../../utils/config';

export default function CartPage() {
    let empty: boolean = false;
    const navigate = useNavigate();

    const [carts, setCarts] = useState(Object);

    useEffect(() => {
      document.title = config.CART_DOC_TITLE;

      CartService.getCarts().then((value: CartType[]) => {
        setCarts(value);
      });
    }, [])

    function toPayment() {
      navigate("/payment");
    }

    function toSchedule() {
      navigate("/instructors");
    }

    if (carts.length === 0) {
      empty = true;
    }
  
    return (
      <div className="gridContainer">
            <div className="fullScreen fullScreenAdjusted">
                <div className="cartFullScreen">
                    <h2>Cart</h2>
                    <hr />
                    {!(empty) &&
                      <CartList carts={carts} setCarts={setCarts} />
                    }
                    {(empty) &&
                      <div id='emptyCart'>
                        <div>
                          <p>Your cart is currently empty</p>
                          <p>-</p>
                          <GreenButton text="Find Lessons" onClick={toSchedule} />
                        </div>
                      </div>
                    }
                </div>
                <div className="footerButtons">
                    <BlackButton text="Back" onClick={toSchedule} />
                    <GreenButton text="Checkout" onClick={toPayment}/>
                </div>
            </div>
      </div>
    )
  }
  
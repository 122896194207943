import React from 'react';
import './Message.scss'

type Props = {
    message: string,
    sending: boolean
}

export default function Message(props: Props) {
  if (props.sending) {
    return (
        <li className="sendingMessage">
            <p>{props.message}</p>
        </li>
      );
  } else {
    return (
        <li className="receivingMessage">
            <p>{props.message}</p>
        </li>
      );
  }
  
}

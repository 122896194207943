import React, { ChangeEvent, useState, useEffect } from "react";
import "../../scss/main.scss";
import CreateAvailableRequestType from "../../Types/CreateAvailableRequestType";
import AvailableRadioTrackerType from "../../Types/AvailableRadioTrackerType";
import AddressType from "../../Types/AddressType";

type Props = {
	step: number;
	setStep: Function;
	formData: CreateAvailableRequestType;
	setFormData: Function;
	radioTracker: AvailableRadioTrackerType;
	setRadioTracker: Function;
	addresses: AddressType[];
};
export default function VirtualAddressStep(props: Props) {
	const [addressForm, setAddressForm] = useState<AddressType>({
		lineOne: "",
		lineTwo: "",
		zipCode: "",
		city: "",
		state: "",
		description: "",
	});
	const [savedAddressId, setSavedAddressId] = useState<number | undefined>(
		props.formData.addressId || undefined
	);
	const [nextStepUnlocked, setNextStepUnlocked] = useState(false);

	useEffect(() => {
		if (props.formData.lineOne !== undefined) {
			setAddressForm({
				lineOne: props.formData.lineOne,
				lineTwo: props.formData.lineTwo!,
				zipCode: props.formData.zipCode!,
				city: props.formData.city!,
				state: props.formData.state!,
				description: props.formData.description || "",
			});
		}
	}, [
		props.formData.lineOne,
		props.formData.lineTwo,
		props.formData.zipCode,
		props.formData.city,
		props.formData.state,
		props.formData.description,
	]);

	useEffect(() => {
		if (
			props.radioTracker.virtualRadio !== undefined &&
			((props.radioTracker.virtualRadio === true &&
				props.radioTracker.addressRadio !== undefined) ||
				props.radioTracker.virtualRadio === false)
		)
			setNextStepUnlocked(true);
		else setNextStepUnlocked(false);
	}, [addressForm, props.radioTracker]);

	function nextPage() {
		var formData = { ...props.formData };
		if (!props.radioTracker.virtualRadio) {
			formData.addressId = undefined;
			formData.lineOne = undefined;
			formData.lineTwo = undefined;
			formData.zipCode = undefined;
			formData.state = undefined;
			formData.city = undefined;
			formData.description = undefined;
			props.setFormData(formData);
		} else if (props.radioTracker.addressRadio) {
			Object.entries(addressForm).forEach((value, index) => {
				if (
					value[0] !== "description" ||
					(value[0] === "description" && value[1] !== "")
				)
					formData = { ...formData, [value[0]]: value[1] };
			});
			formData.addressId = undefined;
			props.setFormData(formData);
		} else {
			formData = { ...formData, addressId: savedAddressId };
			props.setFormData(formData);
		}
		props.setStep(props.step + 1);
	}

	function prevPage() {
		props.setStep(props.step - 1);
	}

	function onChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
		const newRadioTracker = { ...props.radioTracker };
		switch (e.target.name) {
			case "virtualRadio":
				if (e.target.className === "yesRadio") {
					newRadioTracker["virtualRadio"] = false;
				} else newRadioTracker["virtualRadio"] = true;

				break;
			case "addressRadio":
				if (e.target.className === "yesLabel") {
					newRadioTracker["addressRadio"] = true;
				} else newRadioTracker["addressRadio"] = false;
				break;
		}

		props.setRadioTracker(newRadioTracker);
	}

	function onSavedAddressFormChange(e: ChangeEvent<HTMLInputElement>) {
		setSavedAddressId(parseInt(e.currentTarget.value));
	}

	function onAddressFormChange(e: ChangeEvent<HTMLInputElement>) {
		const newAddress = { ...addressForm, [e.target.name]: e.target.value };
		setAddressForm(newAddress);
	}

	return (
		<>
			<div id="virtualAddresshtmlForm" className="">
				<hr />
				<p className="question">Would you like the lesson to be virtual?</p>
				<label id="yesVirtualLabel" className="yesLabel" htmlFor="yesVirtual">
					Yes
				</label>
				<input
					type="radio"
					id="yesVirtual"
					className="yesRadio"
					name="virtualRadio"
					checked={
						props.radioTracker.virtualRadio === undefined
							? false
							: !props.radioTracker.virtualRadio
					}
					onChange={onChange}
				/>
				<label htmlFor="noVirtual">No</label>
				<input
					type="radio"
					id="noVirtual"
					name="virtualRadio"
					checked={
						props.radioTracker.virtualRadio === undefined
							? false
							: props.radioTracker.virtualRadio
					}
					onChange={onChange}
				/>
				<br />
				{props.radioTracker.virtualRadio && (
					<div id="offlineLessons">
						<br />
						<p className="question">Would you like to...</p>
						<label
							id="yesVirtualLabel"
							className="longLeftLabel"
							htmlFor="newAddress"
						>
							Create a new address
						</label>
						<input
							type="radio"
							id="newAddress"
							className="yesLabel"
							name="addressRadio"
							checked={
								props.radioTracker.addressRadio === undefined
									? false
									: props.radioTracker.addressRadio
							}
							onChange={onChange}
						/>
						<label htmlFor="savedAddresses">Use a previous address</label>
						<input
							type="radio"
							id="savedAddresses"
							name="addressRadio"
							className="noLabel"
							checked={
								props.radioTracker.addressRadio === undefined
									? false
									: !props.radioTracker.addressRadio
							}
							onChange={onChange}
						/>
						<br />
						<div id="addressInfo">
							{props.radioTracker.addressRadio === false && (
								<>
									{props.addresses.length === 0 && (
										<>
											<br />
											<p>No past addresses</p>
										</>
									)}
									{props.addresses.length > 0 && (
										<>
											<br />
											<p className="question">Please select an Address:</p>
											{props.addresses.map((address) => {
												return (
													<div className="savedAddressDiv" key={address.id}>
														<input
															checked={
																address.id === savedAddressId ? true : false
															}
															onChange={onSavedAddressFormChange}
															type="radio"
															className="savedAddressRadio"
															id={"previousAddres" + address.id}
															name="newAddressList"
															value={address.id}
														/>
														<label htmlFor="previousAddress<%= address.id %>">
															<p className="inlineP">{`${address.lineOne}, ${address.lineTwo}`}</p>
															<p>{`${address.city}, ${address.state}`}</p>
															<p>{`${address.zipCode}`}</p>
															<div className="descriptionDiv">
																<p>{`${address.description}`}</p>
															</div>
														</label>
													</div>
												);
											})}
										</>
									)}
								</>
							)}
						</div>
						{props.radioTracker.addressRadio && (
							<div id="createAddress">
								<br />
								<p className="question">
									Please fill the following information:
								</p>
								<label htmlFor="lineOne">Address Line One</label>
								<br />
								<input
									type="text"
									name="lineOne"
									id="lineOne"
									value={addressForm.lineOne}
									onChange={onAddressFormChange}
								/>
								<br />
								<label htmlFor="lineTwo">Address Line Two (Optional)</label>
								<br />
								<input
									type="text"
									name="lineTwo"
									id="lineTwo"
									value={addressForm.lineTwo}
									onChange={onAddressFormChange}
								/>
								<br />
								<label htmlFor="city">City</label>
								<br />
								<input
									type="text"
									name="city"
									id="city"
									value={addressForm.city}
									onChange={onAddressFormChange}
								/>
								<br />
								<label htmlFor="state">State</label>
								<br />
								<input
									type="text"
									name="state"
									id="state"
									value={addressForm.state}
									onChange={onAddressFormChange}
								/>
								<br />
								<label htmlFor="zipCode">Zip</label>
								<br />
								<input
									type="text"
									name="zipCode"
									id="zipCode"
									value={addressForm.zipCode}
									onChange={onAddressFormChange}
								/>
								<br />
								<label htmlFor="description">Description (Optional)</label>
								<br />
								<input
									type="text"
									name="description"
									id="description"
									value={addressForm.description}
									onChange={onAddressFormChange}
								/>
								<br />
							</div>
						)}
					</div>
				)}

				<br />
			</div>
			<div className="nextBackButton">
				<button
					type="button"
					id="instrumentStudentBack"
					className="backButton"
					onClick={prevPage}
				>
					Back
				</button>
				<button
					type="button"
					id="dateTimeNext"
					className={nextStepUnlocked ? "nextButton" : "nextButton deactivated"}
					disabled={!nextStepUnlocked}
					onClick={nextPage}
				>
					Next
				</button>
			</div>
		</>
	);
}

import priceTagIcon from "../../images/price_tag.png";
import messageIcon from "../../images/message.png";
import chatIcon from "../../images/chat.png";
import InstructorType from "../../Types/InstructorType";
import { useLocation, useNavigate } from "react-router-dom";
import GreenButton from "../Buttons/GreenButton/GreenButton";
import "./InstructorProfileHeader.scss";

type Props = {
	instructor: InstructorType;
	cartLength: number;
};
function InstructorProfileHeader(props: Props) {
	const navigate = useNavigate();
	const path = useLocation();
	const pathString =
		path.pathname.split("/")[path.pathname.split("/").length - 1];

	return (
		<div id="instructorProfileHeader">
			<img
				id="instPFP"
				src={props.instructor.profilePicLink}
				alt="Instructor profile pic"
			/>
			<div>
				<h2 id="instructorProfileName">
					{props.instructor.firstName + " " + props.instructor.lastName}
				</h2>

				{/*  */}
				<span>
					{/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
					<a
						id="messageInstructorProfileLink"
						href={"/message/" + props.instructor.userId}
					>
						<img src={chatIcon} alt="Message Icon" />
						Message Instructor
					</a>
				</span>

				<span>
					<img src={priceTagIcon} alt="Price Tag Icon" />
					<p>${props.instructor.price} / hour</p>
				</span>

				{!!props.instructor.displayEmail === true && (
					<span>
						<img src={messageIcon} alt="Email Icon" />
						<p>{props.instructor.email}</p>
					</span>
				)}
			</div>

			<div className="rightSideButtons">
				{pathString !== "schedule" && (
					<GreenButton
						text={`Schedule Lessons`}
						onClick={() => {
							navigate("/instructors/" + props.instructor.userId + "/schedule");
						}}
					/>
				)}

				{pathString === "schedule" && (
					<GreenButton
						text={`Instructor Profile`}
						onClick={() => {
							navigate("/instructors/" + props.instructor.userId);
						}}
					/>
				)}

				{!(props.cartLength === 0) && (
					<GreenButton
						text={`View Cart (${props.cartLength})`}
						onClick={() => {
							navigate("/cart");
						}}
					/>
				)}
			</div>
		</div>
	);
}

export default InstructorProfileHeader;

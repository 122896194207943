import axios from "axios";
import AddressType from "../Types/AddressType";
import config from "../utils/config";

class AddressService {
	static async getAddressesForLoggedInInstructor(): Promise<AddressType[]> {
		return (
			await axios.get(config.API_URL + "/api/address/", {
				withCredentials: true,
			})
		).data;
	}
}

export default AddressService;

/* eslint-disable react-hooks/exhaustive-deps */
import "../../scss/main.scss";
import DatePicker from "react-multi-date-picker";
import { ChangeEvent, useEffect, useState } from "react";
import TimePicker from "../TimePicker/TimePicker";
import moment from "moment";
import CreateAvailableRequestType from "../../Types/CreateAvailableRequestType";
import AvailableRadioTrackerType from "../../Types/AvailableRadioTrackerType";

type Props = {
	step: number;
	setStep: Function;
	formData: CreateAvailableRequestType;
	setFormData: Function;
	radioTracker: AvailableRadioTrackerType;
	setRadioTracker: Function;
	isEditModal: boolean;
};
export default function DateTimeStep(props: Props) {
	const [datePickerValue, setDatePickerValue] = useState<any>(
		moment(props.formData.start).format("YYYY/MM/DD")
	);
	const [startTime, setStartTime] = useState(
		moment(props.formData.start).format("h:mma")
	);
	const [endTime, setEndTime] = useState(
		moment(props.formData.end).format("h:mma")
	);
	const [price, setPrice] = useState(props.formData.price || "40.00");
	const [repeat, setRepeat] = useState(
		props.formData.repeat ? props.formData.repeat : 1
	);
	const [nextStepUnlocked, setNextStepUnlocked] = useState(false);

	useEffect(() => {
		if (props.isEditModal) {
			props.setRadioTracker({ ...props.radioTracker, rateRadio: true });
		}
	}, []);

	useEffect(() => {
		const newData = { ...props.formData };
		const dateMoment =
			datePickerValue != null
				? moment(datePickerValue.toString(), "YYYY/MM/DD")
				: moment();
		newData["start"] = moment(startTime, "h:mma")
			.set({
				year: dateMoment.year(),
				month: dateMoment.month(),
				date: dateMoment.date(),
			})
			.format();
		if (newData["start"] !== props.formData["start"]) {
			newData["end"] = moment(startTime, "h:mma")
				.add(1, "hours")
				.set({
					year: dateMoment.year(),
					month: dateMoment.month(),
					date: dateMoment.date(),
				})
				.format();
			setEndTime(moment(newData["end"]).format("h:mma"));
		} else
			newData["end"] = moment(endTime, "h:mma")
				.set({
					year: dateMoment.year(),
					month: dateMoment.month(),
					date: dateMoment.date(),
				})
				.format();
		props.setFormData(newData);
	}, [datePickerValue, startTime, endTime]);

	useEffect(() => {
		checkNext();
	}, [
		props.radioTracker.repeatRadio,
		props.radioTracker.rateRadio,
		datePickerValue,
		startTime,
		endTime,
		repeat,
		price,
	]);

	function nextPage() {
		if (!isLessonLengthValid()) {
			alert("Lesson must be at least 15 minutes.");
			return;
		}

		if (!isDateValid()) {
			alert("Lesson cannot be in the past.");
			return;
		}

		const newData = { ...props.formData };
		if (props.radioTracker.repeatRadio) newData["repeat"] = repeat;
		if (props.radioTracker.rateRadio) newData["price"] = price;
		props.setFormData(newData);
		props.setStep(props.step + 1);
	}

	function isLessonLengthValid() {
		return (
			moment(props.formData.end).diff(
				moment(props.formData.start),
				"minutes"
			) >= 15
		);
	}

	function isDateValid() {
		const dateMoment =
			datePickerValue != null
				? moment(datePickerValue.toString(), "YYYY/MM/DD")
				: moment();
		console.log(
			(moment().diff(dateMoment, "days") === 0 &&
				moment(props.formData.start).diff(moment(), "minutes") >= 0) ||
				moment().diff(dateMoment, "days") < 0
		);
		return (
			(moment().diff(dateMoment, "days") === 0 &&
				moment(props.formData.start).diff(moment(), "minutes") >= 0) ||
			moment().diff(dateMoment, "days") < 0
		);
	}

	function onChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
		const newRadioTracker = { ...props.radioTracker };
		switch (e.target.name) {
			case "rateRadio":
				if (e.target.className === "yesRadio") {
					newRadioTracker["rateRadio"] = true;
				} else newRadioTracker["rateRadio"] = false;

				break;
			case "repeatRadio":
				if (e.target.className === "yesRadio") {
					newRadioTracker["repeatRadio"] = true;
				} else newRadioTracker["repeatRadio"] = false;
				break;
			case "repeat":
				setRepeat(parseInt(e.target.value));
				break;
			case "price":
				setPrice(e.target.value);
				break;
		}

		props.setRadioTracker(newRadioTracker);
	}

	function checkNext() {
		if (
			datePickerValue != null &&
			startTime.length !== 0 &&
			endTime.length !== 0 &&
			props.radioTracker.rateRadio !== undefined &&
			(props.radioTracker.repeatRadio !== undefined ||
				props.isEditModal === true) &&
			((props.radioTracker.repeatRadio && repeat >= 1 && repeat <= 25) ||
				!props.radioTracker.repeatRadio ||
				props.isEditModal === true) &&
			((props.radioTracker.rateRadio && price.length !== 0) ||
				!props.radioTracker.rateRadio)
		)
			setNextStepUnlocked(true);
		else setNextStepUnlocked(false);
	}

	return (
		<>
			<div id="dateTimeHtmlForm" className="">
				<div className="firstQuestionDiv">
					<hr />
					<p className="question">Which day will this lesson take place?</p>
					<DatePicker
						multiple={false}
						value={datePickerValue}
						onChange={setDatePickerValue}
					/>
				</div>

				<div id="timePickerQuestion" className="questionDiv">
					<p className="question">What time will this lesson take place?</p>
					<TimePicker time={startTime} setTime={setStartTime} />
					<TimePicker time={endTime} setTime={setEndTime} />
				</div>

				<div className="questionDiv">
					<p className="question">
						Would you like to set a special hourly rate For this lesson?
					</p>
					<label id="yesRateLabel" className="yesLabel" htmlFor="yesRate">
						Yes
					</label>
					<input
						type="radio"
						id="yesRate"
						className="yesRadio"
						name="rateRadio"
						checked={
							props.radioTracker.rateRadio === undefined
								? false
								: props.radioTracker.rateRadio
						}
						onChange={onChange}
					/>
					<label htmlFor="noRate">No</label>
					<input
						type="radio"
						id="noRate"
						name="rateRadio"
						checked={
							props.radioTracker.rateRadio === undefined
								? false
								: !props.radioTracker.rateRadio
						}
						onChange={onChange}
					/>
					<br />
					{props.radioTracker.rateRadio && (
						<div id="specialRateDiv">
							<input
								type="number"
								id="specialRate"
								name="price"
								placeholder="40.00"
								max="999.99"
								step=".01"
								min="20.00"
								defaultValue={props.formData.price ?? 40.0}
								onChange={onChange}
							/>
						</div>
					)}
				</div>

				{props.isEditModal === false && (
					<div className="questionDiv">
						<p className="question">
							Would you like to repeat this lesson on a weekly basis?
						</p>
						<label id="yesRepeatLabel" className="yesLabel" htmlFor="yesRepeat">
							Yes
						</label>
						<input
							type="radio"
							id="yesRepeat"
							className="yesRadio"
							name="repeatRadio"
							checked={
								props.radioTracker.repeatRadio === undefined
									? false
									: props.radioTracker.repeatRadio
							}
							onChange={onChange}
						/>
						<label htmlFor="noRepeat">No</label>
						<input
							type="radio"
							id="noRepeat"
							name="repeatRadio"
							checked={
								props.radioTracker.repeatRadio === undefined
									? false
									: !props.radioTracker.repeatRadio
							}
							onChange={onChange}
						/>

						{props.radioTracker.repeatRadio && (
							<div id="repeatDiv">
								{/* <select
                  name="repeat"
                  id="repeat"
                  defaultValue={1}
                  onChange={onChange}
                >
                  <option value="1">Repeat One Time</option>
                  <option value="2">Repeat Two Times</option>
                  <option value="3">Repeat Three Times</option>
                </select> */}
								<p>
									Repeat this lesson{" "}
									<input
										type="number"
										name="repeat"
										id="repeat"
										min="1"
										max="25"
										onChange={onChange}
									/>{" "}
									times. (Max number 25)
								</p>
							</div>
						)}
					</div>
				)}
			</div>
			<div className="nextButtonDiv">
				<button
					type="button"
					id="dateTimeNext"
					className={nextStepUnlocked ? "nextButton" : "nextButton deactivated"}
					disabled={!nextStepUnlocked}
					onClick={nextPage}
				>
					Next
				</button>
			</div>
		</>
	);
}

import moment from "moment";
import {
	ChangeEvent,
	DetailedHTMLProps,
	FocusEvent,
	InputHTMLAttributes,
	useRef,
} from "react";
import "./TimePickerInput.scss";

type Props = {
	setShowDropdown: Function;
	time: string;
	setTime: Function;
};
export default function TimePickerInput(props: Props) {
	const time = useRef<HTMLInputElement>(null);
	function onFocus(event: FocusEvent<HTMLInputElement>): void {
		event.target.select();
		props.setShowDropdown(true);
	}
	function onBlur() {
		if (!moment(props.time, "h:mma").isValid()) {
			props.setTime(moment().format("h:mma"));
		} else {
			props.setTime(moment(props.time, "h:mma").format("h:mma"));
		}
	}
	function onChange(event: ChangeEvent<HTMLInputElement>): void {
		props.setTime(event.target.value);
	}

	function handleKeyDown(
		event: DetailedHTMLProps<
			InputHTMLAttributes<HTMLInputElement>,
			HTMLInputElement
		>
	) {
		if (event.key === "Tab") {
			time.current?.blur();
			props.setShowDropdown(false);
		}
	}

	return (
		<div className="timePickerInputs">
			<input
				name="hour"
				onKeyDown={handleKeyDown}
				onChange={onChange}
				value={props.time}
				ref={time}
				onBlur={onBlur}
				onFocus={onFocus}
				type="text"
			/>
		</div>
	);
}

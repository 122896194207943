import UpcomingLesson from "./UpcomingLesson";
import { useEffect, useState } from "react";
import AvailableService from "../../../services/available.service";
import AvailableType from "../../../Types/AvailableType";
import { useParams } from "react-router-dom";

type Props = {
	cartLength: number;
	setCartLength: Function;
};
function UpcomingLessonsList(props: Props) {
	let empty: boolean = false;
	const [availables, setAvailables] = useState<AvailableType[]>(Object);
	const { instructorId } = useParams();
	const [modalOpen, setModalOpen] = useState(false);

	function modalButtonHandler() {
		setModalOpen(!modalOpen);
	}

	function deleteFromAvailableList(id: number) {
		setAvailables(availables.filter((a) => a.id !== id));
		props.setCartLength(props.cartLength + 1);
	}

	useEffect(() => {
		AvailableService.getInstructorAvailables(parseInt(instructorId!)).then(
			(value: AvailableType[]) => {
				setAvailables(value);
			}
		);
	}, [instructorId]);

	if (availables.length === 0) {
		empty = true;
	}

	return (
		<div id="upcomingLessonsContainer">
			<h3>Upcoming Availability</h3>
			<ul id="upcomingLessonsList">
				<hr />
				{!empty && (
					<>
						{Array.from(availables).map((item: any) => {
							return (
								<UpcomingLesson
									key={item.id}
									id={item.id}
									available={item}
									onClick={modalButtonHandler}
									deleteFromAvailableList={deleteFromAvailableList}
								/>
							);
						})}
					</>
				)}
				{availables.length > 5 && (
					<>
						<br />
						<a
							className="underlineLink"
							href={"/instructors/" + parseInt(instructorId!) + "/schedule"}
						>
							View More
						</a>
					</>
				)}
				{empty && <p>This instructor currently has no available lessons.</p>}
			</ul>
		</div>
	);
}

export default UpcomingLessonsList;

import React, { useContext, useEffect, useState } from "react";
import "./HistoryPage.scss";
import StudentContext from "../../Contexts/StudentContext";
import LessonHistory from "./LessonHistory/LessonHistory";
import LessonUserHistory from "./LessonUserHistory/LessonUserHistory";
import LessonService from "../../services/lesson.service";
import LessonType from "../../Types/LessonType";
import UserType from "../../Types/UserType";

export default function HistoryPage() {
  const isStudent = !useContext(StudentContext)?.isInstructor;
  const [pastLessons, setPastLessons] = useState<LessonType[]>([]);
  const [pastUsers, setPastUsers] = useState<UserType[]>([]);

  useEffect(() => {
    LessonService.getPastLessons().then((value: LessonType[]) => {
      setPastLessons(value);
    });
  }, []);

  useEffect(() => {
    const populatePastUsers = async () => {
      let tempPastUsers: UserType[] = [];
      if (isStudent) {
        for (let i = 0; i < pastLessons.length; i++) {
          if (
            !tempPastUsers.some(
              (user) => user.id === pastLessons[i].available.instructorId
            )
          ) {
            tempPastUsers.push(pastLessons[i].available.instructor!.user!);
          }
        }
      } else {
        for (let i = 0; i < pastLessons.length; i++) {
          if (
            !tempPastUsers.some((user) => user.id === pastLessons[i].userId)
          ) {
            tempPastUsers.push(pastLessons[i]!.user!);
          }
        }
      }
      setPastUsers(tempPastUsers);
    };
    populatePastUsers();
  }, [pastLessons, isStudent]);

  return (
    <div className="gridContainer">
      <LessonHistory pastLessons={pastLessons} />
      <LessonUserHistory pastUsers={pastUsers} />
    </div>
  );
}

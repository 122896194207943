import fileFolder from "../../../../images/fileIcons/fileFolder.png";
import fileDoc from "../../../../images/fileIcons/fileDoc.png";
import fileMusic from "../../../../images/fileIcons/fileMusic.png";
import filePicture from "../../../../images/fileIcons/filePicture.png";
import fileVideo from "../../../../images/fileIcons/fileVideo.png";
import FileType from "../../../../Types/FileType";
import { MouseEvent, useContext } from "react";
import StudentContext from "../../../../Contexts/StudentContext";

type Props = {
	file: FileType;
	fileModalOpenHandler: Function;
	shareFileModalOpenHandler: Function;
	openFile: Function;
	deleteFile: Function;
	FAT?: string;
};
export default function File(props: Props) {
	const user = useContext(StudentContext);

	function fileModalOpenHandler(event: any) {
		props.fileModalOpenHandler(event.target.className, event?.target.id);
	}
	function shareFileModalOpenHandler(fileName: string, id: number) {
		props.shareFileModalOpenHandler(fileName, id);
	}
	function onClickHandler(e: MouseEvent<HTMLButtonElement>): void {
		if (e.currentTarget.className === "viewFolder") {
			let query = "";
			if (props.FAT) query = "?FAT=" + props.FAT;
			window.location.href = "/files/" + props.file.id + query;
			return;
		}
		props.openFile(props.file.id);
	}
	function onDeleteHandler(): void {
		props.deleteFile(props.file.id);
	}

	let imgSrc;
	if (!props.file.isFolder && props.file.type) {
		switch (props.file.type.split("/")[0]) {
			case "image": {
				imgSrc = filePicture;
				break;
			}
			case "audio": {
				imgSrc = fileMusic;
				break;
			}
			case "video": {
				imgSrc = fileVideo;
				break;
			}
			default: {
				imgSrc = fileDoc;
				break;
			}
		}
	}

	return (
		<li id={props.file.id.toString()} className="individualFiles cursorPoint">
			<p>
				{props.file.isFolder ? (
					<button className="viewFolder" onClick={onClickHandler}>
						<img src={fileFolder} alt="Folder Icon" className="icon cursorPoint" />
						{props.file.displayName}
					</button>
				) : (
					// Need to conditionally show different file images
					<button className="view" onClick={onClickHandler}>
						<img src={imgSrc} alt="Folder Icon" className="icon cursorPoint" />
						{props.file.displayName}
					</button>
				)}
			</p>

			{/* Need to conditionally show profile pic if the file is shared */}
			{props.file.owner === user?.id && (
				<p>
					<button
						onClick={() =>
							shareFileModalOpenHandler(props.file.displayName, props.file.id)
						}
						className="showShare"
					>
						Share
					</button>
					<button onClick={onDeleteHandler} className="deleteFolder">
						Delete
					</button>
					{props.file.isFolder ? (
						<button
							onClick={fileModalOpenHandler}
							id={props.file.id.toString()}
							className="editFolder"
						>
							Edit
						</button>
					) : (
						<button
							onClick={fileModalOpenHandler}
							id={props.file.id.toString()}
							className="editFile"
						>
							Edit
						</button>
					)}
				</p>
			)}
			{props.file.owner !== user?.id && (
				<div className="sharerInfo">
					<img src={props.file.profilePicLink} alt="File sharer's face" />
					<p>
						{props.file.ownerFirstName} {props.file.ownerLastName}
					</p>
				</div>
			)}
		</li>
	);
}

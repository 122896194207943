import InstructorType from "../../../Types/InstructorType";

type Props = {
	instructor: InstructorType;
};
function Instructor(props: Props) {
	const instrumentList: Array<string> =
		props.instructor.instrumentList!.split(",");
	let instrumentListElement: any[] = [];
	let availablesElement: any;

	// Add instrument elements to list to be rendered
	for (let i = 0; i < instrumentList.length; i++) {
		if (i < 4) {
			while (instrumentList[i].length > 22) i++;
			instrumentListElement.push(<li>{instrumentList[i]}</li>);
		}
		if (i >= 4) {
			instrumentListElement.push(<li>More...</li>);
			break;
		}
	}

	// Setup available element
	if (props.instructor.availables === 0) {
		availablesElement = (
			<p className="noLessonAlert">Message for Availability</p>
		);
	} else if (props.instructor.availables === 1) {
		availablesElement = (
			<p className="lessonAlert">
				{props.instructor.availables} Lesson Available
			</p>
		);
	} else {
		availablesElement = (
			<p className="lessonAlert">
				{props.instructor.availables} Lessons Available
			</p>
		);
	}

	return (
		<a
			href={"/instructors/" + props.instructor.userId}
			className="anInstructor"
		>
			<li id={props.instructor.userId.toString()}>
				<p>
					{props.instructor.firstName}
					<br />
					{props.instructor.lastName}
				</p>

				<p>${props.instructor.price}</p>

				{availablesElement}

				<img src={props.instructor.profilePicLink} alt="Instructor's face" />

				<div>
					<p>Instruments</p>
					<ul>{instrumentListElement}</ul>
				</div>
			</li>
		</a>
	);
}

export default Instructor;

import axios, { AxiosResponse } from "axios";
import userRelationshipType from "../Types/UserRelationshipType";
import config from "../utils/config";

class SearchService {
	static async getUserRelationships(
		userId: number
	): Promise<userRelationshipType[]> {
		return (
			await axios.get(
				config.API_URL + "/api/search/userRelationships/" + userId,
				{
					withCredentials: true,
				}
			)
		).data;
	}

	static async getUserViaEmail(email: string): Promise<AxiosResponse> {
		return await axios.get(config.API_URL + "/api/search/usersEmail/" + email, {
			withCredentials: true,
		});
	}
}

export default SearchService;

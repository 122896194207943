import { useContext } from "react";
import StudentContext from "../../../../Contexts/StudentContext";
import UserType from "../../../../Types/UserType";
import PastUser from "./PastUser/PastUser";

type Props = {
  pastUsers: UserType[];
};

export default function LessonUserHistoryList(props: Props) {
  const isStudent = !useContext(StudentContext)?.isInstructor;

  return (
    <>
      {/* Need to add list in case lesson history exists */}
      {Array.from(props.pastUsers).map((pastUser: UserType) => {
        return <PastUser key={pastUser.id} pastUser={pastUser} />;
      })}

      {/* Need to add statement incase lesson history doesn't exist */}
      {!(props.pastUsers.length > 0) && (
        <>
          {isStudent && (
            <p className="noContentToShow">
              You currently have no recent instructors.
            </p>
          )}
          {!isStudent && (
            <p className="noContentToShow">
              You currently have no recent students.
            </p>
          )}
        </>
      )}
    </>
  );
}

import moment from "moment"

type Props = {
    id: number,
    chosenStart: string,
    chosenEnd: string,
    price: string,
    instructorFirstName: string,
    instructorLastName: string
    handleDelete: Function,
    isLoading: boolean,
    isComplete: boolean
}
function PaymentCartItem(props: Props) {

    function onClickHandler() {
        props.handleDelete(props.id);
    }

    return (
        <li id={props.id.toString()}>
            <div className='cartItem'>
                <div className='cartInstructorName'>Lesson with {props.instructorFirstName} {props.instructorLastName}</div>
                <div className='itemPrice'><p>${props.price}</p></div>
                <div className='itemDate'><p>{moment(props.chosenStart).format("dddd, MMMM Do YYYY")}</p></div>
                <div className='itemTime'><p>{moment(props.chosenStart).format("h:mm a")} - {moment(props.chosenEnd).format("h:mm a")}</p></div>
                {!(props.isComplete || props.isLoading) &&
                    <div className='removeButton'><button onClick={onClickHandler}>Remove</button></div>
                }
                <hr />
            </div>
        </li>
    )
}

export default PaymentCartItem
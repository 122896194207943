import { MouseEvent, useState } from "react";
import InstrumentType from "../../../../Types/InstrumentType";
import Modal from "../../../Modal/Modal";
import "./SelectInstruments.scss";

type Props = {
	exitCallback: Function;
	instruments: InstrumentType[];
	userInstruments: Map<number, boolean>;
	setUserInstruments: Function;
};
function SelectInstruments(props: Props) {
	const [userInstrumentUpdate, setUserInstrumentUpdate] = useState<
		Map<number, boolean>
	>(new Map(JSON.parse(JSON.stringify(Array.from(props.userInstruments)))));

	function onClickHandler(e: MouseEvent<HTMLDivElement>) {
		const classNameToAdd = "selectedInstrument";
		const update = userInstrumentUpdate;
		const id = parseInt(e.currentTarget.id);
		var value = true;
		if (e.currentTarget.classList.contains(classNameToAdd)) {
			e.currentTarget.classList.remove(classNameToAdd);
			value = false;
		} else e.currentTarget.classList.add(classNameToAdd);

		update.set(id, value);

		setUserInstrumentUpdate(update);
	}

	function onConfirm() {
		props.setUserInstruments(userInstrumentUpdate);
		props.exitCallback();
	}

	return (
		<Modal confirmExit={true} exitCallback={props.exitCallback}>
			<div id="selectInstrumentDiv">
				<h2>Select Instruments</h2>
				<hr />
				<div className="centerContent">
					<div className="selectGrid">
						{props.instruments.map((instrument) => (
							<div
								onClick={onClickHandler}
								id={instrument.id.toString()}
								key={instrument.id}
								className={
									props.userInstruments.get(instrument.id)
										? "cursorPoint grid-item selectedInstrument"
										: "cursorPoint grid-item"
								}
							>
								<div className="withinGrid">
									<p> {instrument.instrumentName} </p>
								</div>
							</div>
						))}
					</div>
					<button
						onClick={onConfirm}
						type="button"
						id="selectInstrumentsButton"
					>
						Confirm
					</button>
				</div>
			</div>
		</Modal>
	);
}

export default SelectInstruments;

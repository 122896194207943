import "./Settings.scss";
import MainSettings from "./MainSettings.tsx/MainSettings";
import NotificationSettings from "./NotificationSettings/NotificationSettings";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import config from "../../utils/config";
import SettingsService from "../../services/settings.service";
import SettingsType from "../../Types/SettingsType";
import NotificationSettingsType from "../../Types/NotificationSettingsType";
import InstrumentsService from "../../services/instruments.service";
import InstrumentType from "../../Types/InstrumentType";
import StudentContext from "../../Contexts/StudentContext";
import LoadingElement from "../LoadingElement/LoadingElement";

export default function Settings() {
	const user = useContext(StudentContext);
	const [settings, setSettings] = useState<SettingsType | null>(null);
	const [instruments, setInstruments] = useState<InstrumentType[]>([]);
	const [userInstruments, setUserInstruments] = useState<Map<number, boolean>>(
		new Map()
	);
	const [file, setFile] = useState<File>();
	const [loading, setLoading] = useState(true);

	useEffect(() => {
		document.title = config.SETTINGS_DOC_TITLE;

		getSettingsAndInstruments();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function getSettingsAndInstruments() {
		var settingsResult = await SettingsService.getSettings();
		console.log(settingsResult);
		var instrumentsResult = await InstrumentsService.getInstruments();
		setFile(undefined);
		setSettings(settingsResult);
		if (user?.isInstructor) {
			setInstruments(instrumentsResult);
			createInstrumentsMap(
				settingsResult.instrumentList.split(","),
				instrumentsResult
			);
		}
		setLoading(false);
	}

	function createInstrumentsMap(
		userInstrumentsList: string[],
		instruments: InstrumentType[]
	) {
		const instrumentMap = new Map();
		for (const instrument of instruments) {
			if (userInstrumentsList.includes(instrument.id.toString()))
				instrumentMap.set(instrument.id, true);
			else instrumentMap.set(instrument.id, false);
		}

		setUserInstruments(instrumentMap);
	}

	function setNotificationSettings(e: ChangeEvent<HTMLInputElement>) {
		const notification_setting: any = settings?.notification_setting;
		if (notification_setting) {
			e.target.checked
				? (notification_setting[
						e.target.name as keyof NotificationSettingsType
				  ] = 1)
				: (notification_setting[
						e.target.name as keyof NotificationSettingsType
				  ] = 0);
		}

		const newData: SettingsType = {
			...settings,
			notification_setting: notification_setting,
		} as SettingsType;
		setSettings(newData);
	}

	async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();

		for (const [key, value] of Object.entries(settings as SettingsType)) {
			switch (key) {
				case "notification_setting":
					formData.append(key, JSON.stringify(value));
					break;

				case "instrumentList":
					const oldInstruments = settings?.instrumentList.split(",");
					const updatedInstruments = Object.fromEntries(userInstruments);
					for (const [key, value] of Object.entries(updatedInstruments)) {
						if (
							(oldInstruments?.includes(key.toString()) && value) ||
							(!oldInstruments?.includes(key.toString()) && !value)
						)
							delete updatedInstruments[key];
					}
					formData.append("instruments", JSON.stringify(updatedInstruments));
					break;

				default:
					formData.append(key, value.toString());
					break;
			}
		}

		if (file) formData.append("file", file);

		const result = await SettingsService.setSettings(formData);

		if (result === 204) {
			alert("You have successfully updated your settings.");
			await getSettingsAndInstruments();
		} else alert("Something went wrong. Try again later.");

		setLoading(false);
	}

	return (
		<div className="gridContainer">
			<>
				<div id="settingsMain" className="gridTallLeftMain">
					{loading && <LoadingElement size={4} />}

					{settings && !loading && (
						<MainSettings
							settings={settings}
							setSettings={setSettings}
							instruments={instruments}
							userInstruments={userInstruments}
							setUserInstruments={setUserInstruments}
							file={file}
							setFile={setFile}
							onSubmitHandler={onSubmitHandler}
						/>
					)}
				</div>

				<div id="settingsNotif" className="gridTallRight">
					{settings !== null && !loading && (
						<NotificationSettings
							notificationSettings={settings?.notification_setting}
							setNotificationSettings={setNotificationSettings}
							onSubmitHandler={onSubmitHandler}
						/>
					)}
				</div>
			</>
		</div>
	);
}

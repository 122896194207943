import moment from "moment";
import DashboardLesson from "./DashboardLesson/DashboardLesson";
import LessonService from "../../../services/lesson.service";
import LessonType from "../../../Types/LessonType";
import { useContext, useEffect, useState } from "react";
import StudentContext from "../../../Contexts/StudentContext";

export default function DashboardLessons() {
	const user = useContext(StudentContext);
	const [lessons, setLessons] = useState<LessonType[]>([]);

	useEffect(() => {
		LessonService.getFutureLessons().then((value: LessonType[]) => {
			setLessons(value);
		});
	}, []);

	return (
		<div className="gridLeftMainUnderHeader" id="upcomingLessons">
			<h3>Upcoming Lessons</h3>
			<hr />
			<ul className="lessonList" id="futureLessons">
				{Array.from(lessons).map((item: any) => {
					return (
						<DashboardLesson
							key={item.lessonId}
							date={moment(item.chosenStart).format("dddd, MMMM Do YYYY")}
							startTime={moment(item.chosenStart).format("hh:mma")}
							name="Sean Danielson"
							meetLink={item.meetLink}
							lesson={item}
						/>
					);
				})}
			</ul>
			{lessons.length === 0 && (
				<p className="noContentToShow">
					You currently have no upcoming lessons scheduled.
					{!!user?.isInstructor === false && (
						<>
							<span></span>
							<a href="/instructors" id="findLessonButton">
								Find Lessons
							</a>
						</>
					)}
				</p>
			)}
		</div>
	);
}

import { useEffect, useState } from "react";
import LoadingPage from "./LoadingPage/LoadingPage";
import UserType from "../Types/UserType";
import AuthService from "../services/auth.service";
import StudentContext from "../Contexts/StudentContext";
import RefreshUserContext from "../Contexts/UserRefreshContext";
import UserService from "../services/user.service";
import { useCookies } from "react-cookie";

type Props = {
	children: JSX.Element | JSX.Element[];
};

function SoftLog(props: Props) {
	const [, setCookie] = useCookies();
	const [loading, setloading] = useState(true);
	const [user, setUser] = useState<UserType | undefined>(undefined);

	useEffect(() => {
		checkAuth();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function checkAuth() {
		const result = await AuthService.verifyToken();

		if (result) {
			await refreshUserData();
		}

		setloading(false);
	}

	async function refreshUserData() {
		await UserService.getUserData().then((userData) => {
			setUser(userData);
			setCookie("user", userData);
		});
	}

	return (
		<>
			{loading && <LoadingPage />}
			{!loading && (
				<StudentContext.Provider value={user}>
					<RefreshUserContext.Provider value={refreshUserData}>
						<>{props.children}</>
					</RefreshUserContext.Provider>
				</StudentContext.Provider>
			)}
		</>
	);
}

export default SoftLog;

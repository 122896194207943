import axios from "axios";
import UserType from "../Types/UserType";
import config from "../utils/config";

class RelationshipsService {
	static async getUserRelationships(): Promise<UserType[]> {
		return (
			await axios.get(config.API_URL + "/api/relationships", {
				withCredentials: true,
			})
		).data;
	}
}

export default RelationshipsService;

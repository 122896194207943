import moment from 'moment';
import { useState } from 'react';
import addToCartIcon from '../../../images/addToCart.png'
import AvailableType from '../../../Types/AvailableType';
import AddToCartModal from '../../AddToCartModal/AddToCartModal'

type Props = {
    id: number,
    onClick: Function,
    available: AvailableType,
    deleteFromAvailableList: Function
}

function UpcomingLesson(props: Props) {
    const [modalOpen, setModalOpen] = useState(false);

    async function modalButtonHandler() {
        setModalOpen(!modalOpen);
    }

    return (
        <>
            <li className="upcomingLesson cursorPoint" onClick={modalButtonHandler}>
                <span>{moment(props.available.startTime).format('l')}</span>
                <span>{moment(props.available.startTime).format('h:mm a')} - {moment(props.available.endTime).format('h:mm a')}</span>
                <span className='greenText'>${parseFloat(props.available.price)}</span>
                <img id='lessonAddToCartButton' src={addToCartIcon} alt='Add To Cart Icon' />
            </li>
            {modalOpen && (
                <AddToCartModal 
                    id={props.id} 
                    available={props.available}
                    exitCallback={modalButtonHandler} 
                    deleteFromAvailableList={props.deleteFromAvailableList}
                />
            )}
        </>

    )
}

export default UpcomingLesson
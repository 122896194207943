import moment from "moment";
import "../../scss/main.scss";
import PencilIcon from "../../images/pencilIcon.png";
import CreateAvailableRequestType from "../../Types/CreateAvailableRequestType";
import AddressType from "../../Types/AddressType";
import { MouseEvent, useEffect, useState } from "react";
import InstrumentType from "../../Types/InstrumentType";

type Props = {
	step: number;
	setStep: Function;
	formData: CreateAvailableRequestType;
	addresses: AddressType[];
	instructorRate: string;
	searchValue: string;
	instruments: InstrumentType[];
};

export default function ConfirmationStep(props: Props) {
	const [address, setAddress] = useState<AddressType | undefined>(undefined);

	console.log(props.instruments);
	console.log(props.formData.instruments);

	useEffect(() => {
		if (props.formData.addressId !== undefined) {
			const chosenAddress = props.addresses.find(
				(x) => x.id === props.formData.addressId
			);

			setAddress(chosenAddress);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	function prevPage() {
		props.setStep(props.step - 1);
	}

	function setStep(e: MouseEvent<HTMLImageElement>) {
		props.setStep(parseInt(e.currentTarget.id));
	}

	return (
		<>
			<div id="confirmationhtmlForm" className="">
				<hr />
				<div id="confirmationInfo">
					<div className="confirm-div">
						<p className="question confirmHeader">Date and Time</p>
						<img
							id="0"
							src={PencilIcon}
							alt="editIcon"
							className="editIcon cursorPoint"
							onClick={setStep}
						></img>
						<p>{moment(props.formData.start).format("dddd, MMMM Do YYYY")}</p>
						<p>
							{moment(props.formData.start).format("h:mma")} -{" "}
							{moment(props.formData.end).format("h:mma")}
						</p>
						{props.formData.repeat === undefined && (
							<p>No repitition of this lesson</p>
						)}
						{props.formData.repeat === 1 && <p>Repeat lesson 1 time</p>}
						{props.formData.repeat! > 1 && (
							<p>Repeat lesson {props.formData.repeat} times</p>
						)}
						{props.formData.price === undefined && (
							<p>${parseFloat(props.instructorRate).toFixed(2)}</p>
						)}
						{props.formData.price !== undefined && (
							<p>${parseFloat(props.formData.price).toFixed(2)}</p>
						)}
						<br />
					</div>

					<div className="confirm-div">
						<p className="question confirmHeader">Instruments</p>
						<img
							id="1"
							src={PencilIcon}
							alt="editIcon"
							className="editIcon cursorPoint"
							onClick={setStep}
						></img>
						{props.formData.instruments &&
							props.formData.instruments?.length === 0 && (
								<>
									{props.instruments.map((i) => (
										<p key={i.id}>{i.instrumentName}</p>
									))}
								</>
							)}
						{props.formData.instruments &&
							props.formData.instruments?.length !== 0 && (
								<>
									{props.instruments
										.filter((i) =>
											props.formData.instruments?.includes(i.id.toString())
										)
										.map((i) => (
											<p key={i.id}>{i.instrumentName}</p>
										))}
								</>
							)}
						<br />
					</div>

					<div className="confirm-div">
						<p className="question confirmHeader">Student Reservation</p>
						<img
							id="1"
							src={PencilIcon}
							alt="editIcon"
							className="editIcon cursorPoint"
							onClick={setStep}
						></img>
						{props.searchValue.length > 0 && <p>{props.searchValue}</p>}
						{props.searchValue.length === 0 && <p>Available to all students</p>}
						<br />
					</div>

					<div className="confirm-div">
						<p className="question confirmHeader">Location</p>
						<img
							id="2"
							src={PencilIcon}
							alt="editIcon"
							className="editIcon cursorPoint"
							onClick={setStep}
						></img>
						{props.formData.addressId === undefined &&
							props.formData.lineOne === undefined && (
								<p>The lesson will be virtual</p>
							)}
						{props.formData.addressId !== undefined && (
							<>
								<p>
									{address?.lineOne}, {address?.lineTwo}
								</p>
								<p>
									{address?.city}, {address?.state}
								</p>
								<p>{address?.zipCode}</p>
							</>
						)}
						{props.formData.lineOne !== undefined && (
							<>
								<p>
									{props.formData.lineOne}, {props.formData.lineTwo}
								</p>
								<p>
									{props.formData.city}, {props.formData.state}
								</p>
								<p>{props.formData.zipCode}</p>
							</>
						)}

						<br />
					</div>

					{props.formData.description !== undefined &&
						props.formData.description.length > 0 && (
							<div className="confirm-div">
								<p className="question confirmHeader">Description</p>
								<p>{props.formData.description}</p>
								<br />
							</div>
						)}

					{address?.description !== undefined &&
						address?.description.length > 0 && (
							<div className="confirm-div">
								<p className="question confirmHeader">Description</p>
								<p>{address?.description}</p>
								<br />
							</div>
						)}
				</div>
			</div>
			<div className="nextBackButton">
				<button
					type="button"
					id="confirmationBack"
					className="backButton"
					onClick={prevPage}
				>
					Back
				</button>
				<input
					className="nextButton"
					type="submit"
					value="Submit"
					id="confirmSubmit"
				/>
			</div>
		</>
	);
}

import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import "./InstructorOnboardModal.scss";
import InstrumentType from "../../Types/InstrumentType";
import StudentContext from "../../Contexts/StudentContext";
import LoadingElement from "../LoadingElement/LoadingElement";
import SelectInstruments from "../Settings/MainSettings.tsx/SettingsForm/SelectInstruments";
import InstrumentsService from "../../services/instruments.service";
import InstructorService from "../../services/instructor.service";
import RefreshUserContext from "../../Contexts/UserRefreshContext";

type Props = {
	exitCallback: Function;
};
function InstructorOnboardModal(props: Props) {
	const user = useContext(StudentContext);
	const refreshContext = useContext(RefreshUserContext);
	const [bio, setBio] = useState<string | undefined>(undefined);
	const [price, setPrice] = useState<number | undefined>(undefined);
	const [instruments, setInstruments] = useState<InstrumentType[]>([]);
	const [userInstruments, setUserInstruments] = useState<Map<number, boolean>>(
		new Map()
	);
	const [file, setFile] = useState<File>();
	const [loading, setLoading] = useState(false);
	const [canSubmit, setCanSubmit] = useState(false);
	const [onboardComplete, setOnboardComplete] = useState(false);

	useEffect(() => {
		setLoading(true);
		getData();
		setLoading(false);

		async function getData() {
			var instrumentsResult = await InstrumentsService.getInstruments();
			setInstruments(instrumentsResult);
		}
	}, []);

	useEffect(() => {
		userInstruments.forEach((value, key) => {
			if (value === false) userInstruments.delete(key);
		});

		if (
			userInstruments.size > 0 &&
			price !== undefined &&
			bio !== undefined &&
			bio.length > 0
		)
			setCanSubmit(true);
		else setCanSubmit(false);
	}, [file, userInstruments, price, bio]);

	const [selectInstrumentsModalOpen, setSelectInstrumentsModalOpen] =
		useState(false);

	function selectInstrumentsModalOpenHandler() {
		setSelectInstrumentsModalOpen(!selectInstrumentsModalOpen);
	}

	function bioOnChangeHandler(e: ChangeEvent<HTMLTextAreaElement>) {
		setBio(e.currentTarget.value);
	}

	function priceOnChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		setPrice(parseFloat(e.currentTarget.value));
	}

	function onFileChangeHandler(e: ChangeEvent<HTMLInputElement>) {
		if (e.target.files && e.target.files.length > 0) setFile(e.target.files[0]);
	}

	async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		const formData = new FormData();
		var instrumentToSend: any = {};

		if (file) formData.append("file", file);

		userInstruments.forEach((value, key) => {
			instrumentToSend = { ...instrumentToSend, [key]: value };
		});

		formData.append("instruments", JSON.stringify(instrumentToSend));
		formData.append("bio", bio!);
		formData.append("price", price!.toString());

		const result = await InstructorService.onboardInstructor(
			user!.id,
			formData
		);

		if (result === 204) {
			setOnboardComplete(true);
		} else alert("Something went wrong. Try again later.");

		setLoading(false);
	}

	function continueOnClickHandler() {
		setLoading(true);
		refreshContext();
	}

	return (
		<Modal exitCallback={props.exitCallback} canExit={true}>
			<ModalHeader
				headerText={
					onboardComplete
						? "Welcome aboard!"
						: "You're almost done! Tell us about yourself."
				}
			/>
			<hr />
			<>
				{loading && <LoadingElement size={2} />}
				{!loading && onboardComplete && (
					<div id="onboardSuccessMessage">
						<p>
							You have successfully completed the onboarding process! Press the
							continue button to start your Cloud Music Suite journey.
						</p>
						<button
							onClick={continueOnClickHandler}
							id="continueButton"
							className="nextButton"
						>
							Continue
						</button>
					</div>
				)}
				{!loading && !onboardComplete && (
					<form id="instructorOnboardForm" onSubmit={onSubmitHandler}>
						<div className="questionDiv">
							<p className="question">What's your background?</p>
							<textarea
								name="bio"
								id=""
								cols={100}
								rows={10}
								placeholder="Tell us about yourself"
								onChange={bioOnChangeHandler}
								required
							>
								{bio}
							</textarea>
						</div>
						<div className="questionDiv">
							<p className="question">
								Would you like to upload a profile picture?
							</p>
							<input
								className="custom-file-input"
								onChange={onFileChangeHandler}
								id="file"
								name="file"
								type="file"
								accept="image/*, .jpg"
							/>
						</div>
						<div className="questionDiv">
							<p className="question">What's your rate?</p>
							<input
								onChange={priceOnChangeHandler}
								type="number"
								name="price"
								placeholder="40.00"
								min="40.00"
								max="999.99"
								step="0.01"
								value={price}
								required
							/>
						</div>
						<div className="questionDiv">
							<p className="question">
								What will you teach? (choose at least one)
							</p>
							<button
								onClick={selectInstrumentsModalOpenHandler}
								id="selectInstrumentsButton"
								className=""
								type="button"
							>
								Select Instruments
							</button>
							{selectInstrumentsModalOpen && (
								<SelectInstruments
									instruments={instruments}
									userInstruments={userInstruments}
									setUserInstruments={setUserInstruments}
									exitCallback={selectInstrumentsModalOpenHandler}
								/>
							)}
						</div>
						<div className="nextButtonDiv">
							<button
								type="submit"
								className={
									canSubmit ? "nextButton activated" : "nextButton deactivated"
								}
							>
								Submit
							</button>
						</div>
					</form>
				)}
			</>
		</Modal>
	);
}

export default InstructorOnboardModal;

import React from 'react'
import { forwardRef } from 'react';
import cloudUpload from '../../../../images/cloudUpload.png';
import fileFolder from '../../../../images/fileIcons/fileFolder.png';


type Props = {
    fileModalOpenHandler: Function,
}
const AddFileDropdown = forwardRef((props: Props, ref: any) => {
    function fileModalOpenHandler(modalType: string) {
        props.fileModalOpenHandler(modalType);
    }

    return (
        <div ref={ref} id="addGrid" className='uploadGrid'>
            <div onClick={() => fileModalOpenHandler('file')} className='uploadGridItem cursorPoint'>
                <img src={cloudUpload} alt="Upload Icon" />
                <p>New File</p>
            </div>
            <div onClick={() => fileModalOpenHandler('folder')} className="uploadGridItem cursorPoint">
                <img src={fileFolder} alt="Folder Icon" />
                <p>New Folder</p>
            </div>
        </div>
    )
})

export default AddFileDropdown;
import { useEffect, useState } from "react";
import MessageHeader from "./MessageHeader/MessageHeader";
import MessagesList from "./MessagesList/MessagesList";
import { useParams } from "react-router-dom";
import UserService from "../../services/user.service";

function MessagesPage() {
	const { conversationId } = useParams();
	const [talkingToName, setTalkingToName] = useState("");

	useEffect(() => {
		const id = parseInt(conversationId!);
		UserService.getUser(id)
			.then((user) => {
				if (!user) {
					window.location.href = "/message";
					return;
				}
				setTalkingToName(user.firstName + " " + user.lastName);
			})
			.catch((err) => {
				console.error(err);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className="gridContainer">
			<div className="fullScreen">
				<MessageHeader talkingToName={talkingToName} />
				<MessagesList talkingToId={parseInt(conversationId!)} />
			</div>
		</div>
	);
}

export default MessagesPage;

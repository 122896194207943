import axios from "axios";
import UserType from "../Types/UserType";
import config from "../utils/config";

class UserService {
	static async getUser(userId: number): Promise<UserType> {
		return (
			await axios.get(config.API_URL + "/api/user/" + userId, {
				withCredentials: true,
			})
		).data;
	}

	static async getUserData(): Promise<any> {
		try {
			return (
				await axios.get(config.API_URL + "/api/user/userData", {
					withCredentials: true,
				})
			).data;
		} catch (err: any) {
			if (err.response.status === 401) {
				window.location.href = config.API_URL;
			}
		}
	}

	static async postAgreeToTerms(): Promise<any> {
		return (
			await axios.post(
				config.API_URL + "/api/terms",
				{ agree: true },
				{
					withCredentials: true,
				}
			)
		).data;
	}
}

export default UserService;

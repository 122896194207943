import { FormEventHandler, useContext } from "react";
import StudentContext from "../../../Contexts/StudentContext";
import InstrumentType from "../../../Types/InstrumentType";
import SettingsType from "../../../Types/SettingsType";
import SettingsForm from "./SettingsForm/SettingsForm";

type Props = {
	settings: SettingsType;
	setSettings: Function;
	instruments: InstrumentType[];
	userInstruments: Map<number, boolean>;
	setUserInstruments: Function;
	file?: File;
	setFile: Function;
	onSubmitHandler: FormEventHandler<HTMLFormElement>;
};
export default function MainSettings(props: Props) {
	const user = useContext(StudentContext);

	async function copyLinkFunction() {
		var link = `${window.location.protocol}//${window.location.host}/instructors/${user?.id}`;
		await navigator.clipboard.writeText(link);
		alert("Copied the text: " + link);
	}

	return (
		<>
			<div className="settingsHeader">
				{!!user?.isInstructor && (
					<img src={props.settings.profilePicLink} alt="User's face" />
				)}
				<h2>Profile Settings</h2>
			</div>

			{!!user?.isInstructor && <br />}
			<hr />

			{!!user?.isInstructor && (
				<button id="copyLink" className="cursorPoint" onClick={copyLinkFunction}>
					Copy Profile Link
				</button>
			)}

			<SettingsForm
				settings={props.settings}
				setSettings={props.setSettings}
				instruments={props.instruments}
				userInstruments={props.userInstruments}
				setUserInstruments={props.setUserInstruments}
				file={props.file}
				setFile={props.setFile}
				onSubmitHandler={props.onSubmitHandler}
			/>
		</>
	);
}

import React, { useContext, useEffect, useState } from 'react';
import Conversation from './Conversation/Conversation';
import MessageService from '../../../services/message.service';
import './ConversationList.scss'
import MessageType from '../../../Types/MessageType';
import StudentContext from '../../../Contexts/StudentContext';

type Props = {
  talkingToId: number,
  showMessageList: boolean, 
  setTalkingToId: Function,
  setShowMessageList: Function,
  setMessages: Function
}

export default function ConversationList(props: Props) {
  const [conversations, setConversations] = useState<MessageType[]>([]);
  const user = useContext(StudentContext);

  useEffect(() => {
    MessageService.getConversationList().then((value: MessageType[]) => {
      setConversations(value);
    });
  }, [])

  return (    
    <div id="conversationListContainer">
      <ul>
          {Array.from(conversations).map((item: any) => {
            return (
              <span key={item.createdAt}>
              <Conversation 
                imgSrc={user?.id === item.senderId ? item.user2ProfilePicLink : item.user1ProfilePicLink} 
                talkingToName={user?.id === item.senderId ? item.user2FirstName + ' ' + item.user2LastName : item.user1FirstName + ' ' + item.user1LastName} 
                talkingToId={user?.id === item.senderId ? item.receiverId : item.senderId} 
                preview={item.body} 
                showMessageList={props.showMessageList} 
                setTalkingToId={props.setTalkingToId} 
                setShowMessageList={props.setShowMessageList} 
                setMessages={props.setMessages} />
              </span>
            )
          })}
      </ul>
    </div>
);
}

import { CardNumberElement, useElements, useStripe } from '@stripe/react-stripe-js';
import { ConfirmCardPaymentData, PaymentIntent, Stripe } from '@stripe/stripe-js';
import { FormEvent, useRef, useState } from 'react'
import PaymentService from '../../../services/payment.service';
import PaymentFormDataType from '../../../Types/PaymentFormDataType';
import BillingStep from '../BillingStep/BillingStep';
import PaymentStep from '../PaymentStep/PaymentStep';
import ReviewStep from '../ReviewStep/ReviewStep';

type Props = {
    setIsLoading: Function,
    setIsComplete: Function,
    step: number,
    setStep: Function,
    priceString: string,
    stripePromise: Promise<Stripe | null>,
    cardList: any[],
    setMessage: Function,
    setIsSuccessful: Function,
    formDisabled: boolean
}
function PaymentForm(props: Props) {
    const stripe = useStripe();
    const elements = useElements();
    const formRef = useRef<HTMLFormElement>(null);
    const [saveCard, setSaveCard] = useState(false);

    const [formData, setFormData] = useState<PaymentFormDataType>({
        firstNameBilling: "",
        lastNameBilling: "",
        streetAddressLineOneBilling: "",
        streetAddressLineTwoBilling: "",
        cityBilling: "",
        stateBilling: "",
        zipcodeBilling: ""
    });

    const [last4, setLast4] = useState<string | undefined>('');
    const [exp, setExp] = useState<string | undefined>('');

    const [existingCardPaymentMethod, setExistingCardPaymentMethod] = useState("");

    function setFormDataHandler(data: PaymentFormDataType) {
        setFormData(data);
    }

    async function completePayment(intent: PaymentIntent) {
        const result: any = (saveCard === true || existingCardPaymentMethod.length > 0) ?
            await PaymentService.paymentComplete(intent.id) :
            await PaymentService.paymentComplete(intent.id, intent.payment_method);
        if (result.status === 204) {
            props.setIsSuccessful(true);
            return;
        }
        props.setMessage(result.data);
    }

    async function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        props.setIsLoading(true);
        const intent: any = existingCardPaymentMethod.length === 0 ?
            await PaymentService.createPaymentIntent() :
            await PaymentService.createPaymentIntentSavedCard(existingCardPaymentMethod);
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }

        const confirmParams: ConfirmCardPaymentData = {
            payment_method: {
                card: elements.getElement(CardNumberElement)!,
                billing_details: {
                    address: {
                        city: formData.cityBilling,
                        line1: formData.streetAddressLineOneBilling,
                        line2: formData.streetAddressLineTwoBilling,
                        postal_code: formData.zipcodeBilling,
                        state: formData.stateBilling,
                    },
                    name: `${formData.firstNameBilling} ${formData.lastNameBilling}`,
                }
            }
        };

        // if an existing card is being used, use new payment intent and separate
        // stripe function https://stripe.com/docs/js/payment_intents/confirm_card_payment
        const { error, paymentIntent } = existingCardPaymentMethod.length === 0 ?
            await stripe.confirmCardPayment(intent.client_secret, confirmParams) :
            await stripe.confirmCardPayment(intent.intent.client_secret, { payment_method: intent.intent.payment_method_id });

        if (paymentIntent) {
            await completePayment(paymentIntent);
            props.setIsLoading(false);
            props.setIsComplete(true);
            return;
        }

        // This point will only be reached if there is an immediate error when
        // confirming the payment.
        if (error!.type === "card_error" || error!.type === "validation_error") {
            props.setMessage(error!.message);
        } else {
            props.setMessage("An unexpected error occurred.");
        }
        props.setIsComplete(true);
        props.setIsLoading(false);
    }

    async function setNewCardDetails() {
        if (!stripe || !elements) {
            // Stripe.js has not yet loaded.
            // Make sure to disable form submission until Stripe.js has loaded.
            return;
        }
        const result = await stripe?.createPaymentMethod({
            type: 'card',
            card: elements.getElement(CardNumberElement)!,
        });
        setLast4(result.paymentMethod?.card?.last4);
        setExp(result.paymentMethod?.card?.exp_month + '/' + result.paymentMethod?.card?.exp_year);
    }

    return (
        <form ref={formRef} id="paymentForm" onSubmit={handleSubmit}>
            <PaymentStep
                setStep={props.setStep}
                step={props.step}
                stripePromise={props.stripePromise}
                cardList={props.cardList}
                setSaveCard={setSaveCard}
                setIsLoading={props.setIsLoading}
                setFormData={setFormData}
                setLast4={setLast4}
                setExp={setExp}
                setExistingCardPaymentMethod={setExistingCardPaymentMethod}
                formData={formData}
                setNewCardDetails={setNewCardDetails}
                formDisabled={props.formDisabled}
            />

            {props.step === 1 &&
                <BillingStep
                    setStep={props.setStep}
                    step={props.step}
                    formData={formData}
                    setFormDataHandler={setFormDataHandler}
                />
            }

            {props.step === 2 &&
                <ReviewStep
                    setStep={props.setStep}
                    step={props.step}
                    formRef={formRef}
                    formData={formData}
                    last4={last4}
                    exp={exp}
                    priceString={props.priceString}
                    choseExistingCard={existingCardPaymentMethod.length > 0 ? true : false}
                />
            }
        </form>
    )
}

export default PaymentForm
import ReactDOM from "react-dom";
import ExitIcon from "./ExitIcon/ExitIcon";
import "./Modal.scss";

type Props = {
	children: JSX.Element | JSX.Element[];
	exitCallback: Function;
	canExit?: boolean;
	confirmExit?: boolean;
};
export default function Modal(props: Props) {
	var canExit, confirmExit;
	if (!props.canExit) canExit = true;
	else canExit = false;

	if (!props.confirmExit) confirmExit = false;
	else confirmExit = true;

	return ReactDOM.createPortal(
		<div className="overlay">
			<div className="overlayContent">
				{canExit && (
					<ExitIcon
						confirmExit={confirmExit}
						exitCallback={props.exitCallback}
					/>
				)}
				{props.children}
			</div>
		</div>,
		document.getElementById("modal")!
	);
}

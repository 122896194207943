import React from "react";
import loadingImage from "../../images/BlueLogoClearBackground.png";
import "./LoadingElement.scss";

// Size is applied to the heigh and width styling in ems
type Props = {
	size: number;
};
function LoadingElement(props: Props) {
	const loadingSize = {
		width: props.size.toString() + "em",
		height: props.size.toString() + "em",
	};

	const elementPosition = {
		// transform: `translateX(-50%) translateY(-50%)`
	};

	return (
		<div id="loadingElement" style={elementPosition}>
			<div className="loadingSection">
				<div className="loadingContainer">
					<div style={loadingSize} className="loading">
						<img
							style={loadingSize}
							src={loadingImage}
							alt="Loading..."
							className="loadingImg"
						/>
					</div>
				</div>
			</div>
		</div>
	);
}

export default LoadingElement;

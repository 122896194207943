import './WhiteButton.scss'

type Props = {
    id: string,
    text: string,
    onClick: Function
}

export default function WhiteButton( props: Props ) {

    function onClickHandler() {
        props.onClick();
    }

    return (
        <button id={props.id} onClick={onClickHandler} className="whiteButton">
            {props.text}
        </button>
    )
}

import React from "react";
import loadingImage from "../../../images/BlueLogoClearBackground.png";

function PaymentLoading() {
	return (
		<div className="loadingSection">
			<div className="loadingContainer">
				<div className="loading">
					<img src={loadingImage} alt="Loading..." className="loadingImg" />
				</div>
			</div>
		</div>
	);
}

export default PaymentLoading;

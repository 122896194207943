import { ChangeEvent } from 'react';
import CardIcon from '../../../images/card-icon.svg';

type Props = {
    id: string,
    brand: string,
    last4: string,
    exp_month: number,
    exp_year: number,
    setPaymentMethod: Function,
    paymentMethod: string,
    nextActivatedHandler: Function,
    cardListStatus: {[key: string]: boolean}, 
    setCardListStatus: Function,
    setFormData: Function
}
function ExistingCard(props: Props) {

    async function onChangeHandler(e: ChangeEvent<HTMLInputElement>) {
        const cardListStatus = props.cardListStatus;
        cardListStatus[props.id] = true;
        cardListStatus[props.paymentMethod] = false;
        await props.setCardListStatus(cardListStatus);
        await props.setPaymentMethod(props.id);
        await props.nextActivatedHandler(true);
        await props.setFormData(props.id);
    }

    return (
        <li className={props.cardListStatus[props.id] === true ? 'selected' : ''}>
            <input className="cards" id={props.id.toString()} name="payment-method" checked={props.cardListStatus[props.id] === true}
                type="radio" onChange={onChangeHandler} />
            <label htmlFor={props.id.toString()}><img src={CardIcon}
                alt="Card icon" />
                <p className="cardNum">Card ending in {props.last4}
                </p>
                <p className="expDate">Exp: {props.exp_month}/{props.exp_year}</p>
            </label>
        </li>
    )
}

export default ExistingCard
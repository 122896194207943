/* eslint-disable react-hooks/exhaustive-deps */
import React, {
	ChangeEvent,
	MouseEvent,
	ReactElement,
	useEffect,
	useState,
} from "react";
import CreateAvailableRequestType from "../../Types/CreateAvailableRequestType";
import AvailableRadioTrackerType from "../../Types/AvailableRadioTrackerType";
import InstrumentType from "../../Types/InstrumentType";
import UserType from "../../Types/UserType";
import SearchService from "../../services/search.service";

type Props = {
	step: number;
	setStep: Function;
	formData: CreateAvailableRequestType;
	setFormData: Function;
	radioTracker: AvailableRadioTrackerType;
	setRadioTracker: Function;
	instruments: InstrumentType[];
	userRelationships: UserType[];
	searchValue: string;
	setSearchValue: Function;
	isEditModal: boolean;
};

export default function InstrumentStudentStep(props: Props) {
	const [chosenInstruments, setChosenInstruments] = useState<string[]>([]);
	const [userRelationshipsElements, setUserRelationshipsElements] = useState<
		ReactElement[]
	>([]);
	const [selectedRelationship, setSelectedRelationship] = useState("");
	const [nextStepUnlocked, setNextStepUnlocked] = useState(false);

	useEffect(() => {
		setChosenInstruments(props.formData.instruments ?? []);
	}, []);

	useEffect(() => {
		checkNext();
	}, [
		chosenInstruments,
		selectedRelationship,
		props.searchValue,
		props.radioTracker,
	]);

	function keyUpSearch(event: ChangeEvent<HTMLInputElement>) {
		setSelectedRelationship("");
		props.setSearchValue(event.target.value);
		const value = event.currentTarget.value.toUpperCase();
		const elements: ReactElement[] = [];
		if (value.length === 0) {
			setUserRelationshipsElements(elements);
			return;
		}
		for (const relationship of props.userRelationships) {
			if (
				relationship.firstName?.toUpperCase().includes(value) ||
				relationship.lastName?.toUpperCase().includes(value) ||
				relationship.email
					?.substring(0, relationship.email.indexOf("@"))
					.toUpperCase()
					.includes(value)
			)
				elements.push(
					<li
						onClick={relationShipSelect}
						key={relationship.id}
						className="shareLi cursorPoint"
						id={relationship.id.toString()}
					>
						<p className="shareName">
							{relationship.firstName} {relationship.lastName}
						</p>{" "}
						- <p className="userEmail">{relationship.email}</p>
					</li>
				);
		}
		setUserRelationshipsElements(elements);
	}

	function relationShipSelect(e: MouseEvent<HTMLLIElement>) {
		const id = e.currentTarget.id;
		const relationship = props.userRelationships.filter(
			(i) => i.id.toString() === id
		)[0];
		props.setSearchValue(relationship.email!);
		setSelectedRelationship(id);
		setUserRelationshipsElements([]);
	}

	async function nextPage() {
		const newFormData = { ...props.formData };
		if (props.radioTracker.studentRadio) {
			if (selectedRelationship.length > 0)
				newFormData.reservedFor = selectedRelationship;
			else {
				const result: number = (
					await SearchService.getUserViaEmail(props.searchValue)
				).data;
				if (result === null) {
					alert(
						"That user does not exist. Ensure that the email you provided is correct."
					);
					return;
				} else {
					newFormData.reservedFor = result.toString();
				}
			}
		}
		if (props.radioTracker.instrumentRadio)
			newFormData.instruments = chosenInstruments;
		else newFormData.instruments = [];

		props.setFormData(newFormData);
		props.setStep(props.step + 1);
	}

	function prevPage() {
		props.setStep(props.step - 1);
	}

	function onChange(e: ChangeEvent<HTMLInputElement | HTMLSelectElement>) {
		const newRadioTracker = { ...props.radioTracker };
		console.log(props.radioTracker);
		switch (e.target.name) {
			case "studentRadio":
				if (e.target.className === "yesRadio") {
					newRadioTracker["studentRadio"] = true;
				} else newRadioTracker["studentRadio"] = false;

				break;
			case "instrumentRadio":
				if (e.target.className === "yesRadio") {
					newRadioTracker["instrumentRadio"] = true;
				} else newRadioTracker["instrumentRadio"] = false;
				break;
			case "instruments":
				const newInstruments = chosenInstruments;
				if (chosenInstruments.includes(e.target.value))
					setChosenInstruments(
						chosenInstruments.filter((i) => i !== e.target.value)
					);
				else {
					newInstruments.push(e.target.value);
					setChosenInstruments(newInstruments);
				}
				break;
		}
		props.setRadioTracker(newRadioTracker);
	}

	function checkNext() {
		if (
			props.radioTracker.instrumentRadio !== undefined &&
			props.radioTracker.studentRadio !== undefined &&
			((props.radioTracker.instrumentRadio && chosenInstruments.length !== 0) ||
				!props.radioTracker.instrumentRadio) &&
			((props.radioTracker.studentRadio && props.searchValue.length !== 0) ||
				!props.radioTracker.studentRadio)
		)
			setNextStepUnlocked(true);
		else setNextStepUnlocked(false);
	}

	return (
		<>
			<div id="instrumentStudenthtmlForm" className="">
				<hr />
				<div className="firstQuestionDiv">
					<p className="question">
						Would you like to limit instrument choices For this lesson?
					</p>
					<label
						id="yesInstrumentLabel"
						className="yesLabel"
						htmlFor="yesInstrument"
					>
						Yes
					</label>
					<input
						type="radio"
						id="yesInstrument"
						className="yesRadio"
						name="instrumentRadio"
						checked={
							props.radioTracker.instrumentRadio === undefined
								? false
								: props.radioTracker.instrumentRadio
						}
						onChange={onChange}
					/>
					<label htmlFor="noInstrument">No</label>
					<input
						type="radio"
						id="noInstrument"
						name="instrumentRadio"
						checked={
							props.radioTracker.instrumentRadio === undefined
								? false
								: !props.radioTracker.instrumentRadio
						}
						onChange={onChange}
					/>
					<br />
					{props.radioTracker.instrumentRadio && (
						<div id="shareInstrumentList">
							{props.instruments.map((instrument) => {
								return (
									<div key={instrument.id}>
										<input
											type="checkbox"
											className="instruments"
											name="instruments"
											id={instrument.id.toString()}
											value={instrument.id}
											checked={
												chosenInstruments.includes(instrument.id.toString())
													? true
													: false
											}
											onChange={onChange}
										/>
										<label htmlFor={undefined}>
											{instrument.instrumentName}
										</label>
										<br />
									</div>
								);
							})}
						</div>
					)}
				</div>

				<div className="questionDiv">
					<p className="question">Is this lesson For a specific student?</p>
					<label id="yesStudentLabel" className="yesLabel" htmlFor="yesStudent">
						Yes
					</label>
					<input
						type="radio"
						id="yesStudent"
						className="yesRadio"
						name="studentRadio"
						checked={
							props.radioTracker.studentRadio === undefined
								? false
								: props.radioTracker.studentRadio
						}
						onChange={onChange}
					/>
					<label htmlFor="noStudent">No</label>
					<input
						type="radio"
						id="noStudent"
						name="studentRadio"
						checked={
							props.radioTracker.studentRadio === undefined
								? false
								: !props.radioTracker.studentRadio
						}
						onChange={onChange}
					/>
					<br />
					{props.radioTracker.studentRadio && (
						<div id="searchDiv">
							<div className="wrapper">
								<div id="searchInputAvailable" className="search-input active">
									<input
										value={props.searchValue}
										onChange={keyUpSearch}
										id="search"
										type="text"
										placeholder="Type to search.."
										autoComplete="off"
									/>
									{userRelationshipsElements.length > 0 && (
										<div id="autocomAvailable" className="autocom-box">
											<ul id="users">{userRelationshipsElements}</ul>
										</div>
									)}
								</div>
							</div>
							<input
								type="hidden"
								name="reservedhtmlFor"
								id="reservedhtmlFor"
								onInput={undefined}
							/>
						</div>
					)}
				</div>
			</div>
			<div className="nextBackButton">
				<button
					type="button"
					id="instrumentStudentBack"
					className="backButton"
					onClick={prevPage}
				>
					Back
				</button>
				<button
					type="button"
					id="dateTimeNext"
					className={nextStepUnlocked ? "nextButton" : "nextButton deactivated"}
					disabled={!nextStepUnlocked}
					onClick={nextPage}
				>
					Next
				</button>
			</div>
		</>
	);
}

import { useContext, useEffect, useRef, useState } from "react";
import { useCookies } from "react-cookie";
import Textarea from "react-expanding-textarea";
import MessageService from "../../../services/message.service";
import MessageType from "../../../Types/MessageType";
import Message from "./Message/Message";
import "./MessageList.scss";
import StudentContext from "../../../Contexts/StudentContext";

type Props = {
	talkingToId: number;
};
function setScrollToBottom() {
	let messages = document.getElementById("messagesListContainer");
	messages!.scrollTop = messages!.scrollHeight;
}

export default function MessagesList(props: Props) {
	const [messages, setMessages] = useState<MessageType[]>([]);
	const [cookies] = useCookies();
	const user = useContext(StudentContext);
	let newMessage: string;
	let newMessageInput = useRef<HTMLInputElement>(null);

	async function sendMessage(e: any) {
		e.preventDefault();
		let id = null;
		newMessage = newMessageInput.current!.value;
		newMessageInput.current!.value = "";
		newMessageInput.current!.style.height = "auto";

		if (messages.length > 0) id = messages[0].id;

		await MessageService.sendMessage(
			cookies.userId,
			props.talkingToId,
			newMessage,
			false,
			id
		);

		var getMessageToUserValue = await MessageService.getMessageToUser(
			props.talkingToId
		);

		setMessages(getMessageToUserValue);
	}

	useEffect(() => {
		setScrollToBottom();
	}, [messages]);

	useEffect(() => {
		MessageService.getMessageToUser(props.talkingToId).then(
			(value: MessageType[]) => {
				setMessages(value);
				setScrollToBottom();
			}
		);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<>
			<div id="messagesListContainer">
				<ul>
					{Array.from(messages).map((item: any) => {
						return (
							<span key={item.createdAt}>
								<Message
									message={item.body}
									sending={item.senderId === user!.id}
								/>
							</span>
						);
					})}
				</ul>
			</div>

			<form id="directMessageInput" onSubmit={sendMessage}>
				<input type="hidden" name="messageReceiver" value={props.talkingToId} />
				<Textarea
					name="message"
					id="message"
					maxLength={500}
					rows="1"
					placeholder="New Message"
					ref={newMessageInput}
				></Textarea>
				<input type="submit" value="Send" />
			</form>
		</>
	);
}

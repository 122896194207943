import { useContext } from "react";
import UserType from "../../../Types/UserType";
import LessonUserHistoryList from "./LessonUserHistoryList/LessonUserHistoryList";
import StudentContext from "../../../Contexts/StudentContext";

type Props = {
	pastUsers: UserType[];
};

export default function LessonUserHistory(props: Props) {
	const isStudent = !useContext(StudentContext)?.isInstructor;

	return (
		<div id="recentInstructors" className="gridTallRight">
			{isStudent ? <h2>Recent Instructors</h2> : <h2>Recent Students</h2>}
			<hr />
			<LessonUserHistoryList pastUsers={props.pastUsers} />
		</div>
	);
}

import CheckMark from '../../../images/checkmark.svg';

type Props = {
    step: number
}
function Steps(props: Props) {
    return (
        <div className="stepsDiv">
            <div className="individualStep">
                <div id="stepOneDiv" className={"circleDiv " + (props.step === 0 ? 'currentlyOn' : 'complete')}>
                    {props.step <= 0 &&
                        <p id="stepOneParagraph">1</p>
                    }
                    {props.step > 0 &&
                        <img src={CheckMark} alt="checkmark" id="stepOneCheckmark" className="" />
                    }
                </div>
                <div className="textUnderStep">
                    <p>Payment</p>
                </div>
            </div>
            <div className="stepSeparator">
                <hr />
            </div>
            <div className="individualStep">
                <div id="stepTwoDiv" className={"circleDiv " + (props.step === 1 ? 'currentlyOn' : (props.step > 1 ? 'complete' : 'notComplete'))}>
                    {props.step <= 1 &&
                        <p id="stepOneParagraph">2</p>
                    }
                    {props.step > 1 &&
                        <img src={CheckMark} alt="checkmark" id="stepOneCheckmark" className="" />
                    }
                </div>
                <div className="textUnderStep">
                    <p>Billing</p>
                </div>
            </div>
            <div className="stepSeparator">
                <hr />
            </div>
            <div className="individualStep">
                <div id="stepThreeDiv" className={"circleDiv " + (props.step === 2 ? 'currentlyOn' : (props.step > 2 ? 'complete' : 'notComplete'))}>
                    {props.step <= 2 &&
                        <p id="stepOneParagraph">3</p>
                    }
                    {props.step > 2 &&
                        <img src={CheckMark} alt="checkmark" id="stepOneCheckmark" className="" />
                    }
                </div>
                <div className="textUnderStep">
                    <p>Review</p>
                </div>
            </div>
        </div>
    )
}

export default Steps
import React from 'react';
import './MessageHeader.scss';

type Props = {
  talkingToName: string,
}

export default function MessageHeader(props: Props) {
  return (
    <>
      <div id="messageListHeader">
        <h3>{props.talkingToName}</h3>
        <a href="/message" className='greenButton'>My Messages</a>
      </div>
      <hr />
    </>
);
}

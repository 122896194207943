import "./Instructors.scss";
import InstructorList from "./InstructorList/InstructorList";
import { FormEvent, useEffect, useState } from "react";
import InstructorService from "../../services/instructor.service";
import InstructorType from "../../Types/InstructorType";
import config from "../../utils/config";
import CartService from "../../services/cart.service";
import GreenButton from "../Buttons/GreenButton/GreenButton";
import { useNavigate } from "react-router-dom";

function Instructors() {
	const [instructors, setInstructors] = useState<InstructorType[]>([]);
	const [instructorMap, setInstructorMap] = useState<any>({});
	const [cartCount, setCartCount] = useState<number>(0);
	const navigate = useNavigate();

	useEffect(() => {
		document.title = config.INSTRUCTORS_DOC_TITLE;
		collectData();
		async function collectData() {
			var instructorsList = await InstructorService.getInstructors();
			await CartService.getNumCarts()
				.then((cart: number) => {
					setCartCount(cart);
				})
				.catch((err) => {
					console.error(err);
					setCartCount(0);
				});
			setInstructors(instructorsList);
			var tempInstructorMap: any = {};
			for (const i of instructorsList) tempInstructorMap[i.userId] = true;
			setInstructorMap(tempInstructorMap);
		}
	}, []);

	function onInputHandler(e: FormEvent<HTMLInputElement>) {
		for (let i = 0; i < instructors.length; i++) {
			var instructorName =
				`${instructors[i].firstName} ${instructors[i].lastName}`.toLowerCase();
			if (!instructorName.includes(e.currentTarget.value.toLowerCase())) {
				setInstructorMap({ ...instructorMap, [instructors[i].userId]: false });
			} else {
				setInstructorMap({ ...instructorMap, [instructors[i].userId]: true });
			}
		}
	}

	return (
		<div className="gridContainer studentDashBody">
			<div id="findLessonContainer" className="fullScreen">
				<div className="header">
					<h2 id="findLesson">Find a Lesson</h2>
					{/* TODO: Populate cart size */}
					{cartCount > 0 && (
						<GreenButton
							text={`View Cart (${cartCount})`}
							onClick={() => {
								navigate("/cart");
							}}
						/>
					)}
				</div>

				{/* Conditionally Show Cart button if the user is signed in */}
				<hr />
				<input
					type="text"
					id="searchForInstructor"
					placeholder="Search Instructors"
					onInput={onInputHandler}
				/>
				<InstructorList
					instructors={instructors.filter(
						(i) => instructorMap[i.userId] === true
					)}
				/>
			</div>
		</div>
	);
}

export default Instructors;

import { MouseEvent, useEffect, useRef, useState } from "react";
import LoadingElement from "../../LoadingElement/LoadingElement";
import FileService from "../../../services/file.service";
import GetFilesResponseType from "../../../Types/GetFilesResponseType";
import FileFolder from "../../../images/fileIcons/fileFolder.png";
import FileDoc from "../../../images/fileIcons/fileDoc.png";
import FileMusic from "../../../images/fileIcons/fileMusic.png";
import FilePicture from "../../../images/fileIcons/filePicture.png";
import FileVideo from "../../../images/fileIcons/fileVideo.png";
import ArrowRight from "../../../images/arrowRight.png";
import FileType from "../../../Types/FileType";
import ReactDOM from "react-dom";

type Props = {
	fileId: number;
	currentFolderId: string;
	setFolder: Function;
};
function SelectFolderDropdown(props: Props) {
	const [showFolderDropdown, setShowFolderDropdown] = useState(false);
	const [selectedFolder, setSelectedFolder] = useState(props.currentFolderId);
	const [selectedFolderMetadata, setSelectedFolderMetadata] =
		useState<FileType>();
	const [fileList, setFileList] = useState<GetFilesResponseType>();
	const [loading, setLoading] = useState(true);
	const [moveButtonEnabled, setmoveButtonEnabled] = useState(false);
	const [buttonText, setButtonText] = useState("Choose Folder");
	const buttonRef = useRef(null);
	const dropdownRef = useRef(null);

	useEffect(() => {
		setLoading(true);
		getFiles();
		getMetadata();
		setLoading(false);

		document.addEventListener("mousedown", handleMouseDown);
		return function cleanup() {
			document.removeEventListener("mousedown", handleMouseDown);
		};

		async function getMetadata() {
			var results;
			if (selectedFolder !== " ")
				results = await FileService.getFileMetadata(parseInt(selectedFolder));

			setSelectedFolderMetadata(results);
		}

		async function getFiles() {
			var results;
			if (selectedFolder.toString() === " ")
				results = await FileService.getFiles();
			else results = await FileService.getFiles(selectedFolder.toString());

			if (results === 401 || results === 500 || typeof results === "number") {
				window.location.href = "/error";
				return;
			}

			setFileList(results);
		}
	}, [props.currentFolderId, selectedFolder]);

	function handleMouseDown(event: _MouseEvent) {
		const dropdownNode = ReactDOM.findDOMNode(dropdownRef.current);
		if (
			buttonRef &&
			event.target !== buttonRef.current &&
			dropdownRef &&
			!dropdownNode?.contains(event.target as Node)
		) {
			setShowFolderDropdown(false);
		}
	}

	function showFolderDropdownHandler() {
		setShowFolderDropdown(!showFolderDropdown);
	}

	function folderOnClick(e: MouseEvent<HTMLLIElement>) {
		setSelectedFolder(e.currentTarget.id);
		if (e.currentTarget.id !== props.currentFolderId)
			setmoveButtonEnabled(true);
		else setmoveButtonEnabled(false);
	}

	function backArrowOnClick() {
		setSelectedFolder(
			selectedFolderMetadata?.belongsToFolder?.toString() ?? " "
		);
		if (
			selectedFolderMetadata?.belongsToFolder?.toString() ??
			" " !== props.currentFolderId
		)
			setmoveButtonEnabled(true);
		else setmoveButtonEnabled(false);
	}

	function moveHereButtonHandler() {
		props.setFolder(selectedFolder);
		setShowFolderDropdown(false);
		setButtonText(selectedFolderMetadata?.displayName ?? "Home");
	}

	return (
		<div id="folderSelectEdit">
			<button
				onClick={showFolderDropdownHandler}
				id="chooseFolderButton"
				type="button"
				ref={buttonRef}
			>
				{buttonText}
			</button>
			{showFolderDropdown && (
				<div className="folderSelectDropdown" ref={dropdownRef}>
					<div className="dropdownTop">
						{selectedFolder === " " && (
							<p className="homeDirectory">
								<img src={ArrowRight} alt="Arrow" className="icon" />
								<span id="currentDirectoryName">Home</span>
							</p>
						)}
						{selectedFolder !== " " && (
							<p className="currentDirectory">
								<img
									src={ArrowRight}
									alt="Arrow"
									className="icon"
									onClick={backArrowOnClick}
								/>
								<span id="currentDirectoryName">
									{selectedFolderMetadata?.displayName}
								</span>
							</p>
						)}

						<ul className="folderList">
							{loading && <LoadingElement size={2} />}
							{!loading && (
								<div className="listSection">
									{/* <div id="<%= folderId %>" className="listSection"> */}
									{fileList?.files
										.filter((f) => {
											// eslint-disable-next-line eqeqeq
											return f.id != props.fileId;
										})
										.map((f) => {
											if (f.isFolder)
												return (
													<li
														key={f.id}
														onClick={folderOnClick}
														id={f.id.toString()}
													>
														<img
															src={FileFolder}
															alt="Folder Icon"
															className="icon"
														/>
														<span>{f.displayName}</span>
														<img
															src={ArrowRight}
															alt="Arrow Icon"
															className="icon right-icon"
														/>
													</li>
												);

											switch (f.type) {
												case "image":
													return (
														<li
															key={f.id}
															id={f.id.toString()}
															className="file"
														>
															<img src={FilePicture} alt="" className="icon" />
															<span>{f.displayName}</span>
														</li>
													);
												case "video":
													return (
														<li
															key={f.id}
															id={f.id.toString()}
															className="file"
														>
															<img src={FileVideo} alt="" className="icon" />
															<span>{f.displayName}</span>
														</li>
													);
												case "audio":
													return (
														<li
															key={f.id}
															id={f.id.toString()}
															className="file"
														>
															<img src={FileMusic} alt="" className="icon" />
															<span>{f.displayName}</span>
														</li>
													);
												default:
													return (
														<li
															key={f.id}
															id={f.id.toString()}
															className="file"
														>
															<img src={FileDoc} alt="" className="icon" />
															<span>{f.displayName}</span>
														</li>
													);
											}
										})}
								</div>
							)}
						</ul>
					</div>
					{moveButtonEnabled && (
						<div className="dropdownBottom">
							<button
								id="moveHere"
								className="selected"
								type="button"
								onClick={moveHereButtonHandler}
							>
								Move Here
							</button>
						</div>
					)}
					{!moveButtonEnabled && (
						<div className="dropdownBottom">
							<button id="moveHere" type="button" disabled>
								Move Here
							</button>
						</div>
					)}
				</div>
			)}
		</div>
	);
}

export default SelectFolderDropdown;

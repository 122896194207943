import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import { StripeElementChangeEvent } from '@stripe/stripe-js'
import { ChangeEvent, useState } from 'react';

type Props = {
    stripePromise: Object,
    nextActivatedHandler: Function,
    setSaveCard: Function,
    formDisabled: boolean
}

function NewCard(props: Props) {
    const [cardComplete, setCardComplete] = useState(false);
    const [cvcComplete, setCvcComplete] = useState(false);
    const [expiryComplete, setExpiryComplete] = useState(false);
    const [cardError, setCardError] = useState("");
    const [cardFocus, setCardFocus] = useState(false);
    const [expFocus, setExpFocus] = useState(false);
    const [cvcFocus, setCvcFocus] = useState(false);

    const ELEMENT_STYLES = {
        base: {
            color: '#32325D',
            fontWeight: 500,
            fontFamily: 'Roboto, Sans-Serif',
            fontSize: '16px',
            fontSmoothing: 'antialiased',

            '::placeholder': {
                color: '#CFD7DF',
            },

            ':-webkit-autofill': {
                color: '#e39f48',
            },
        },
        invalid: {
            color: '#E25950',

            '::placeholder': {
                color: '#FFCCA5',
            },
        },
    };

    const ELEMENT_CLASSES = {
        focus: 'focused',
        empty: 'empty',
        invalid: 'invalid',
    };

    const OPTIONS = {
        style: ELEMENT_STYLES,
        classes: ELEMENT_CLASSES,
        disabled: props.formDisabled
    }

    function onChangeHandler(event: StripeElementChangeEvent) {
        let tempCardComplete = cardComplete;
        let tempExpiryComplete = expiryComplete;
        let tempCvcComplete = cvcComplete;

        switch (event.elementType) {
            case 'cardNumber':
                setCardComplete(event.complete);
                tempCardComplete = event.complete;
                break;
            case 'cardExpiry':
                setExpiryComplete(event.complete);
                tempExpiryComplete = event.complete;
                break;
            case 'cardCvc':
                setCvcComplete(event.complete);
                tempCvcComplete = event.complete;
                break;
        }

        if (tempCardComplete && tempExpiryComplete && tempCvcComplete)
            props.nextActivatedHandler(true);
        else
            props.nextActivatedHandler(false);

        event.error ? setCardError(event.error.message) : setCardError("");
    }

    function onChangeHandlerSaveCard(event: ChangeEvent<HTMLInputElement>) {
        event.target.value === "on" ? props.setSaveCard(true) : props.setSaveCard(false);
    }

    return (
        <div id="newCardInputs">
            {/* <PaymentElement onChange={onChangeHandler} id="payment-element" /> */}
            <div id="inputs">
                <p className="cardError">{cardError}</p>
                <div>
                    <label htmlFor="cardDiv">Card</label>
                    <CardNumberElement options={OPTIONS} className={cardFocus ? "divInput focused" : "divInput"} id="cardDiv" onChange={onChangeHandler} onFocus={() => setCardFocus(true)} onBlur={() => setCardFocus(false)} />
                </div>
                <div className="shortInput">
                    <label htmlFor="expirationDateDiv">Expiration Date</label>
                    <CardExpiryElement options={OPTIONS} className={expFocus ? "divInput shortInput focused" : "divInput shortInput"} id="expirationDateDiv" onChange={onChangeHandler} onFocus={() => setExpFocus(true)} onBlur={() => setExpFocus(false)} />
                </div>
                <div className="shortInput">
                    <label htmlFor="cvcDiv">CVC</label>
                    <CardCvcElement options={OPTIONS} className={cvcFocus ? "divInput shortInput focused" : "divInput shortInput"} id="cvcDiv" onChange={onChangeHandler} onFocus={() => setCvcFocus(true)} onBlur={() => setCvcComplete(false)} />
                </div>
            </div>

            <div id="saveCardDiv">
                <input type="checkbox" name="saveCard" id="saveCard" onChange={onChangeHandlerSaveCard} />
                <label htmlFor="saveCard">Save my card information for future purchases</label>
            </div>
        </div>
    )
}

export default NewCard
import axios from "axios";
import InstructorType from "../Types/InstructorType";
import config from "../utils/config";

class InstructorService {
	static async getInstructors(): Promise<InstructorType[]> {
		return (
			await axios.get(config.API_URL + "/api/instructors", {
				withCredentials: true,
			})
		).data;
	}

	static async getInstructor(userId: number): Promise<InstructorType> {
		return (
			await axios.get(config.API_URL + "/api/instructors/" + userId, {
				withCredentials: true,
			})
		).data;
	}

	static async onboardInstructor(
		userId: number,
		onboardBody: FormData
	): Promise<Object> {
		return axios
			.post(config.API_URL + "/api/instructors/" + userId, onboardBody, {
				headers: { "content-type": "multipart/form-data" },
				withCredentials: true,
			})
			.then((result) => result.status)
			.catch((err) => err.status);
	}
}

export default InstructorService;

import config from "../../utils/config";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import "./StripeOnboardModal.scss";

type Props = {
	exitCallback: Function;
	accountCreated: boolean;
};
function StripeOnboardModal(props: Props) {
	return (
		<Modal canExit={true} exitCallback={props.exitCallback}>
			<div id="stripeModal">
				{!props.accountCreated && (
					<>
						<ModalHeader
							headerText={"Thank you for joining the Cloud Music Fleet"}
						/>
						<hr />
						<p id="stripeBlurb">
							We are super excited to get you set up so that you can efficiently
							bring on your students and teach like you always did, but with
							less stress! Before we continue, click the link below to get
							onboarded with Stripe, our payment service provider.
						</p>
						<div id="nextButtonStripeDiv">
							<button type="button" className={"nextButton"}>
								<a
									// crossOrigin="use-credentials"
									className="buttonLink"
									href={`${config.API_URL}/api/stripe/onboard`}
								>
									Onboard with Stripe
								</a>
							</button>
						</div>
					</>
				)}

				{props.accountCreated && (
					<>
						<ModalHeader headerText={"Stripe account has not been activated"} />
						<hr />
						<p id="stripeBlurb2">
							Our records indicate that your account with Stripe, Cloud Music
							Suite's payment handler, is not active. Try one of the following:
						</p>
						<ul id="stripeRecommendationList">
							<li>
								Click the button below to finish onboarding or fix missing
								information.
							</li>
							<li>
								Close the site and come back in a few minutes. Stripe accounts
								can sometimes take a few minutes to be verified and activated.
							</li>
						</ul>
						<div id="nextButtonStripeDiv">
							<button type="button" className={"nextButton"}>
								<a
									className="buttonLink"
									href={`${config.API_URL}/api/stripe/onboard`}
								>
									Navigate to Stripe
								</a>
							</button>
						</div>
					</>
				)}
			</div>
		</Modal>
	);
}

export default StripeOnboardModal;

import CartService from "../../../services/cart.service";
import CartType from "../../../Types/CartType";
import PaymentCartItem from "./PaymentCartItem";

type Props = {
    cart: CartType[],
    setCart: Function,
    fetchData: Function,
    isLoading: boolean,
    isComplete: boolean
    priceString: string
}
function PaymentCart(props: Props) {
    const cartElementList: JSX.Element[] = [];

    props.cart.forEach(cartItem => {
        cartElementList.push(
            <PaymentCartItem
                key={cartItem.lessonId}
                id={cartItem.lessonId}
                chosenStart={cartItem.chosenStart}
                chosenEnd={cartItem.chosenEnd}
                price={cartItem.price}
                instructorFirstName={cartItem.firstName}
                instructorLastName={cartItem.lastName}
                handleDelete={handleDelete}
                isLoading={props.isLoading}
                isComplete={props.isComplete}
            />
        )
    });

    async function handleDelete(id: number) {
        await CartService.deleteCart(id);
        props.fetchData();
    }

    return (
        <div className="split left">
            <h2>Cart</h2>
            <hr />
            <div>
                {props.cart.length !== 0 &&
                    <>
                        <ul id='cartList'>
                            {cartElementList}
                        </ul>
                        <div id="cartTotal">
                            <p>Total: {props.priceString}</p>
                        </div>
                    </>
                }
                {props.cart.length === 0 &&
                    <>
                        <div id='emptyCart'>
                            <div>
                                <p>Your cart is currently empty</p>
                                <p>-</p>
                                <button><a href="/instructors">Find Lessons</a></button>
                            </div>
                        </div>
                    </>
                }
            </div>

        </div>
    )
}

export default PaymentCart;
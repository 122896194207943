import { useEffect, useRef, useState } from 'react'
import TimePickerDropDown from './TimePickerDropDown/TimePickerDropDown'
import TimePickerInput from './TimePickerInput/TimePickerInput'
import ReactDOM from 'react-dom';
import './TimePicker.scss'

type Props = {
    time: string,
    setTime: Function,
    startLimit?: string,
    endLimit?: string
}
export default function TimePicker(props: Props) {
    const [showDropdown, setShowDropdown] = useState(false);
    const timePickerRef = useRef(null)
    const dropdownRef = useRef(null);
    
    function handleMouseDown (event: MouseEvent) {
        const dropdownNode = ReactDOM.findDOMNode(dropdownRef.current);
        if (timePickerRef && event.target !== timePickerRef.current && 
            dropdownRef && !dropdownNode?.contains(event.target as Node)) {
            setShowDropdown(false);
        }
    }

    useEffect(() => {
        document.addEventListener('mousedown', handleMouseDown);
        return function cleanup() {
            document.removeEventListener('mousedown', handleMouseDown);
        };
    });

    return (
        <div ref={timePickerRef} className="timePicker">
            <TimePickerInput
                setShowDropdown={setShowDropdown}
                time={props.time}
                setTime={props.setTime}
            />
            {showDropdown && 
                <TimePickerDropDown
                    setShowDropdown={setShowDropdown}
                    ref={dropdownRef}
                    time={props.time}
                    setTime={props.setTime}
                    startLimit={props.startLimit}
                    endLimit={props.endLimit}
                />
            }
        </div>
    )
}

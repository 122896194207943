import axios from "axios";
import config from "../utils/config";

class InstrumentsService {
	static async getInstruments(instructorId?: number): Promise<any> {
		var route = "/api/instruments";
		if (instructorId) route = route + "?instructorId=" + instructorId;
		return (
			await axios.get(config.API_URL + route, {
				withCredentials: true,
			})
		).data;
	}
}

export default InstrumentsService;

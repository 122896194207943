import axios, { AxiosResponse } from "axios";
import FileType from "../Types/FileType";
import FolderType from "../Types/FolderType";
import editFileType from "../Types/editFileType";
import shareType from "../Types/shareType";
import config from "../utils/config";
import GetFilesResponseType from "../Types/GetFilesResponseType";

class FileService {
	static async getFiles(
		folderId?: string,
		FAT?: string
	): Promise<GetFilesResponseType | number> {
		var route = "/api/files";
		if (folderId) route = route + "?folder=" + folderId;
		if (FAT) route = route + "&FAT=" + FAT;

		return await axios
			.get(config.API_URL + route, {
				withCredentials: true,
			})
			.then((result) => result.data)
			.catch((err) => err.response.status);
	}

	static async getSharedFiles(): Promise<FileType[]> {
		return (
			await axios.get(config.API_URL + "/api/files/shared", {
				withCredentials: true,
			})
		).data;
	}

	static async shareFile(details: shareType): Promise<AxiosResponse> {
		return await axios.post(config.API_URL + "/api/files/share", details, {
			withCredentials: true,
		});
	}

	static async getFileUrl(fileId: number, FAT?: string): Promise<string> {
		let queryParams = "";
		if (FAT) queryParams = "?FAT=" + FAT;
		return (
			await axios.get(config.API_URL + "/api/files/" + fileId + queryParams, {
				withCredentials: true,
			})
		).data;
	}

	static async getFileMetadata(fileId: number): Promise<FileType> {
		return (
			await axios.get(config.API_URL + "/api/files/metadata/" + fileId, {
				withCredentials: true,
			})
		).data;
	}

	static async uploadFile(details: FormData): Promise<any> {
		return await axios
			.post(config.API_URL + "/api/files/", details, {
				headers: { "content-type": "multipart/form-data" },
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.response;
			});
	}

	static async createFolder(details: FolderType): Promise<any> {
		return await axios
			.post(config.API_URL + "/api/files/folder/new", details, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.response;
			});
	}

	static async editFile(fileId: number, details: editFileType): Promise<any> {
		return await axios
			.put(config.API_URL + "/api/files/" + fileId, details, {
				withCredentials: true,
			})
			.then((result) => {
				return result.status;
			})
			.catch((err) => {
				return err.response;
			});
	}

	static async deleteFile(fileId: number): Promise<AxiosResponse> {
		return await axios.delete(config.API_URL + "/api/files/" + fileId, {
			withCredentials: true,
		});
	}
}

export default FileService;

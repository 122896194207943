import React, { FormEvent, useContext, useState, useEffect } from "react";
import Modal from "../Modal/Modal";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import StudentContext from "../../Contexts/StudentContext";
import "./FilePage.scss";
import FileService from "./../../services/file.service";
import InstructorService from "./../../services/instructor.service";
import InstructorType from "./../../Types/InstructorType";
import FolderType from "./../../Types/FolderType";
import FileType from "./../../Types/FileType";
import editFileType from "../../Types/editFileType";
import { useLocation } from "react-router-dom";
import SelectFolderDropdown from "./SelectFolderDropdown/SelectFolderDropdown";
import LoadingElement from "../LoadingElement/LoadingElement";

type Props = {
	modalType: string;
	exitCallback: Function;
	fileId: number;
};
export default function AddFileModal(props: Props) {
	const user = useContext(StudentContext);
	const [isIntro, setIsIntro] = useState(false);
	const [editFolder, setEditFolder] = useState(false);
	const [allInstruments, setAllInstruments] = useState(true);
	const [instrument, setInstrument] = useState("0");
	const [loading, setLoading] = useState(false);
	const [file, setFile] = useState(null);
	const [displayName, setDisplayName] = useState("");
	const [instructorInstruments, setInstructorInstruments] = useState("");
	const [instrumentIds, setInstrumentIds] = useState("");
	const [invalidForm, checkInvalidForm] = useState(false);
	const [belongsToFolder, setBelongsToFolder] = useState(" ");
	const location = useLocation();
	const maxFileLength: number = 75;

	useEffect(() => {
		if (user && user.id) {
			InstructorService.getInstructor(user.id).then((value: InstructorType) => {
				if (value.instrumentList)
					setInstructorInstruments(value.instrumentList);
				if (value.instrumentIds) setInstrumentIds(value.instrumentIds);
			});
		}
	}, [user]);

	useEffect(() => {
		FileService.getFileMetadata(props.fileId).then((value: FileType) => {
			if (value) {
				setDisplayName(value.displayName);
				introFileHandler(value.isIntroFolder);
				if (value.introInstrument && value.introInstrument) {
					introInstrumentHandler(true);
					instrumentSelectHandler(value.introInstrument.toString());
				}
				if (value.belongsToFolder) {
					setBelongsToFolder(value.belongsToFolder.toString());
				}
			}
		});
	}, [props.fileId]);

	useEffect(() => {
		if (props.modalType === "file")
			checkInvalidForm(
				(!displayName || !file || user!.isInstructor === true) &&
					isIntro &&
					!allInstruments &&
					!parseInt(instrument)
			);
		else
			checkInvalidForm(
				(!displayName || user!.isInstructor === true) &&
					isIntro &&
					!allInstruments &&
					!parseInt(instrument)
			);
	}, [
		checkInvalidForm,
		allInstruments,
		displayName,
		file,
		instrument,
		isIntro,
		props.modalType,
		user,
	]);

	function handleFileInsert(event: any) {
		setFile(event.target.files[0]);
		setDisplayName(event.target.value.replace(/^.*[\\\/]/, "")); // eslint-disable-line
	}

	function handleDisplayName(event: any) {
		setDisplayName(event.target.value);
	}

	function editFolderHandler() {
		setEditFolder(!editFolder);
	}

	function introFileHandler(show: boolean) {
		if (show) {
			setIsIntro(true);
		} else {
			setIsIntro(false);
			setAllInstruments(true);
			setInstrument("0");
		}
	}

	function introInstrumentHandler(show: boolean) {
		if (show) {
			setAllInstruments(false);
		} else {
			setAllInstruments(true);
			setInstrument("0");
		}
	}

	function instrumentSelectHandler(id: string) {
		setInstrument(id);
	}

	async function onSubmitHandler(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		let folderId: string = " ";
		let fileNumber: number = Number(
			location.pathname.substring(location.pathname.lastIndexOf("/") + 1)
		);
		if (!isNaN(fileNumber)) {
			folderId = String(fileNumber);
		}

		if (props.modalType === "folder") {
			let folderObject: FolderType = {
				folderId: folderId,
				displayName: displayName,
				isIntroFolder: isIntro,
				introInstrumentFolder: parseInt(instrument),
			};
			const result = await FileService.createFolder(folderObject);
			await props.exitCallback();
			if (result === 201) {
				alert("Folder successfully created!");
			} else {
				alert(result.data || "Something went wrong. Try again later.");
			}
		} else if (props.modalType === "file") {
			const formData = new FormData();
			if (file) {
				formData.append("file", file);
			} else {
				alert("please select a file");
			}
			if (displayName) {
				formData.append("displayName", displayName);
			}
			formData.append("folderId", folderId);
			isIntro
				? formData.append("isIntroFolder", "true")
				: formData.append("isIntroFolder", "");
			allInstruments
				? formData.append("introInstrument", "0")
				: formData.append("introInstrument", instrument);
			const result = await FileService.uploadFile(formData);
			await props.exitCallback();
			if (result === 200) {
				alert("File succcessfully created!");
			} else {
				alert(result.data || "Something went wrong. Try again later.");
			}
		} else if (
			props.modalType === "editFile" ||
			props.modalType === "editFolder"
		) {
			let body: editFileType = {
				belongsToFolder: parseInt(belongsToFolder),
				displayName: displayName,
				isIntroFolder: isIntro,
				introInstrument: parseInt(instrument),
			};
			const result = await FileService.editFile(props.fileId, body);
			await props.exitCallback();
			if (result === 200) {
				alert("File succcessfully edited!");
			} else {
				alert(result.data || "Something went wrong. Try again later.");
			}
		} else {
			alert("Something went wrong. Try again later.");
		}
	}

	let instructorInstrumentsArray = instructorInstruments.split(",");
	let instrumentIdsArray = instrumentIds.split(",");
	let instrumentList: string[] = [];
	for (let i = 0; i < instrumentIds.length - 1; i++) {
		instrumentList.push(
			instrumentIdsArray[i] + ":" + instructorInstrumentsArray[i]
		);
	}

	return (
		<Modal exitCallback={props.exitCallback}>
			<>
				{props.modalType === "file" && <ModalHeader headerText="Upload File" />}
			</>
			<>
				{props.modalType === "folder" && (
					<ModalHeader headerText="Create Folder" />
				)}
			</>
			<>
				{props.modalType === "editFile" && (
					<ModalHeader headerText="Edit File" />
				)}
			</>
			<>
				{props.modalType === "editFolder" && (
					<ModalHeader headerText="Edit Folder" />
				)}
			</>
			<hr />
			<>{loading && <LoadingElement size={2} />}</>
			<>
				{!loading && (
					<form
						id="uploadForm"
						className=""
						method="POST"
						onSubmit={onSubmitHandler}
						encType="multipart/form-data"
					>
						<div id="editSubContainer">
							{(props.modalType === "editFile" ||
								props.modalType === "editFolder") && (
								<div id="editFileName">{displayName}</div>
							)}
							{props.modalType === "file" && (
								<>
									<div className="questionDiv">
										<p className="question">Please select a file to upload.</p>
										<div>
											<input
												id="file"
												name="file"
												type="file"
												onChange={handleFileInsert}
												onInput={undefined}
												accept="image/*, video/*, audio/*, .pdf, .jpg, .doc, .docx"
											/>
										</div>
									</div>
									<div className="questionDiv">
										<p className="question">
											What would you like to name your file?
										</p>
										<div>
											<input
												name="displayName"
												id="displayName"
												type="text"
												placeholder="Display Name"
												value={displayName}
												onInput={undefined}
												onChange={handleDisplayName}
												maxLength={maxFileLength}
											/>
										</div>
									</div>
								</>
							)}
							{props.modalType === "editFile" && (
								<>
									<div className="questionDiv">
										<p className="question">
											What would you like to name your file?
										</p>
										<div>
											<input
												name="displayName"
												id="displayName"
												type="text"
												placeholder="Display Name"
												value={displayName}
												onInput={undefined}
												onChange={handleDisplayName}
												maxLength={maxFileLength}
											/>
										</div>
									</div>
								</>
							)}

							{(props.modalType === "folder" ||
								props.modalType === "editFolder") && (
								<>
									<div className="questionDiv">
										<p className="question">
											What would you like to name your folder?
										</p>
										<div>
											<input
												name="displayName"
												id="displayName"
												type="text"
												placeholder="Display Name"
												value={displayName}
												onInput={undefined}
												onChange={handleDisplayName}
												maxLength={maxFileLength}
											/>
										</div>
									</div>
								</>
							)}
							{(props.modalType === "editFolder" ||
								props.modalType === "editFile") && (
								<>
									<div className="questionDiv">
										<p className="question">
											Would you like to specify a folder location?
										</p>
										<p>(Select "No" to add to main file page)</p>
										<label
											id="yesSpecifyEditLabel"
											className="yesLabel"
											htmlFor="yesSpecifyEdit"
										>
											Yes
										</label>
										<input
											type="radio"
											id="yesSpecifyEdit"
											className="yesRadio"
											name="specifyEditRadio"
											checked={editFolder}
											onChange={editFolderHandler}
										/>
										<label htmlFor="noSpecifyEdit">No</label>
										<input
											type="radio"
											id="noSpecifyEdit"
											name="specifyEditRadio"
											checked={!editFolder}
											onChange={editFolderHandler}
										/>
										{editFolder && (
											<SelectFolderDropdown
												fileId={props.fileId}
												currentFolderId={belongsToFolder}
												setFolder={setBelongsToFolder}
											/>
										)}
									</div>
								</>
							)}
							{!!user?.isInstructor === true && (
								<>
									<div className="questionDiv">
										<p className="question">
											Would you like to set this as an introduction file?
										</p>
										<div>
											<label
												id="yesIntroLabel"
												className="yesLabel"
												htmlFor="yesIntro"
											>
												Yes
											</label>
											<input
												type="radio"
												id="yesIntro"
												className="yesRadio"
												name="introRadio"
												checked={isIntro}
												onChange={() => introFileHandler(true)}
											/>
											<label htmlFor="noIntro">No</label>
											<input
												type="radio"
												id="noIntro"
												name="introRadio"
												checked={!isIntro}
												onChange={() => introFileHandler(false)}
											/>
										</div>
									</div>
									{isIntro && (
										<div id="instrumentIntroDiv">
											<div className="questionDiv">
												<p className="question">
													Will this be an introduction for all your instruments?
												</p>
												<div>
													<label
														id="yesAllInstrumentsLabel"
														defaultChecked
														className="yesLabel"
														htmlFor="yesAllInstruments"
													>
														Yes
													</label>
													<input
														type="radio"
														id="yesAllInstruments"
														checked={allInstruments}
														className="yesRadio"
														name="allInstrumentsRadio"
														onChange={() => introInstrumentHandler(false)}
													/>
													<label htmlFor="noAllInstruments">No</label>
													<input
														type="radio"
														id="noAllInstruments"
														name="allInstrumentsRadio"
														checked={!allInstruments}
														onChange={() => introInstrumentHandler(true)}
													/>
												</div>
											</div>
											{!allInstruments && (
												<div id="instrumentRadioFile">
													<div className="questionDiv">
														<p className="question">
															Which instrument will this be an introduction for?
														</p>
														{Array.from(instrumentList).map((item: any) => {
															return (
																<>
																	<div>
																		<input
																			type="radio"
																			className="introInstrument"
																			name="introInstrument"
																			checked={
																				item.substring(0, item.indexOf(":")) ===
																				instrument
																			}
																			id={item.substring(0, item.indexOf(":"))}
																			value={item.substring(
																				0,
																				item.indexOf(":")
																			)}
																			onChange={() =>
																				instrumentSelectHandler(
																					item.substring(0, item.indexOf(":"))
																				)
																			}
																		/>
																		<label
																			htmlFor={item.substring(
																				0,
																				item.indexOf(":")
																			)}
																		>
																			{" "}
																			{item.substring(
																				item.indexOf(":") + 1,
																				item.length
																			)}
																		</label>
																		<br />
																	</div>
																</>
															);
														})}
													</div>
												</div>
											)}
										</div>
									)}
								</>
							)}

							<button
								className="fileSubmit cursorPoint"
								type="submit"
								value="Upload File"
								id="fileUploadButton"
								disabled={invalidForm}
							>
								Submit
							</button>
						</div>
					</form>
				)}
			</>
		</Modal>
	);
}

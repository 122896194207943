import { FormEvent, useContext, useEffect, useState } from "react";
import "./CreateAvailable.scss";
import Modal from "../Modal/Modal";
import DateTimeStep from "./DateTimeStep";
import InstrumentStudentStep from "./InstrumentStudentStep";
import ModalHeader from "../Modal/ModalHeader/ModalHeader";
import VirtualAddressStep from "./VirtualAddressStep";
import ConfirmationStep from "./ConfirmationStep";
import CreateAvailableRequestType from "../../Types/CreateAvailableRequestType";
import moment from "moment";
import AvailableRadioTrackerType from "../../Types/AvailableRadioTrackerType";
import InstrumentType from "../../Types/InstrumentType";
import InstrumentsService from "../../services/instruments.service";
import UserType from "../../Types/UserType";
import RelationshipsService from "../../services/relationship.service";
import StudentContext from "../../Contexts/StudentContext";
import AddressType from "../../Types/AddressType";
import AddressService from "../../services/address.service";
import InstructorService from "../../services/instructor.service";
import AvailableService from "../../services/available.service";
import LoadingElement from "../LoadingElement/LoadingElement";
import AvailableType from "../../Types/AvailableType";

type Props = {
	exitCallback: Function;
	available?: AvailableType;
};
export default function CreateAvailable(props: Props) {
	const user = useContext(StudentContext);
	const [instructorRate, setInstructorRate] = useState("");
	const [step, setStep] = useState(0);
	const [formData, setFormData] = useState<CreateAvailableRequestType>({
		start: moment("10:00am", "h:mma").add(1, "days").format(),
		end: moment("11:00am", "h:mma").add(1, "days").format(),
	});
	const [radioTracker, setRadioTracker] = useState<AvailableRadioTrackerType>({
		rateRadio: undefined,
		repeatRadio: undefined,
		studentRadio: undefined,
		instrumentRadio: undefined,
		virtualRadio: undefined,
		addressRadio: undefined,
	});
	const [instruments, setInstruments] = useState<InstrumentType[]>([]);
	const [userRelationships, setUserRelationships] = useState<UserType[]>([]);
	const [addresses, setAddresses] = useState<AddressType[]>([]);
	const [searchValue, setSearchValue] = useState("");
	const [loading, setLoading] = useState(true);
	const isEditModal = props.available ? true : false;

	useEffect(() => {
		preRenderWork();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	async function preRenderWork() {
		let { instruments } = await fetchData();
		if (props.available !== undefined) {
			const { instrumentIds, instrumentRadioUpdate } =
				checkEditInstruments(instruments);
			const studentRadioUpdate = checkEditReservedStudent();
			const addressRadioUpdate = checkAddresses();
			setFormData({
				start: props.available.startTime,
				end: props.available.endTime,
				price: props.available.price,
				reservedFor: props.available.reservedForId?.toString(),
				addressId: props.available.addressId ?? undefined,
				instruments: instrumentIds,
			});
			setRadioTracker({
				...radioTracker,
				instrumentRadio: instrumentRadioUpdate.instrumentRadio,
				studentRadio: studentRadioUpdate.studentRadio,
				virtualRadio: addressRadioUpdate.virtualRadio,
				addressRadio: false,
			});
			setStep(3);
		}
		setLoading(false);
	}

	function checkEditInstruments(instruments: InstrumentType[]) {
		let availableInstrumentList: string[] = props
			.available!.instrumentList.toString()
			.split(",");
		const radioUpdate = {
			instrumentRadio: false,
		};

		if (availableInstrumentList?.length === instruments.length) {
			radioUpdate.instrumentRadio = false;
			instruments = [];
		} else {
			radioUpdate.instrumentRadio = true;
			instruments = instruments.filter((s) =>
				availableInstrumentList.includes(s.instrumentName)
			);
		}
		const instrumentIds = Object.values(instruments).map((i) =>
			i.id.toString()
		);
		return {
			instrumentIds: instrumentIds,
			instrumentRadioUpdate: radioUpdate,
		};
	}

	function checkEditReservedStudent() {
		const radioUpdate = {
			studentRadio: false,
		};
		if (props.available?.reservedForId !== null) {
			setSearchValue(props.available?.reservedForEmail!);
			radioUpdate.studentRadio = true;
		} else radioUpdate.studentRadio = false;
		return radioUpdate;
	}

	function checkAddresses() {
		const radioUpdate = {
			addressRadio: false,
			virtualRadio: false,
		};
		if (props.available?.addressId !== null) {
			radioUpdate.virtualRadio = true;
		}
		return radioUpdate;
	}

	async function fetchData() {
		const instruments = await InstrumentsService.getInstruments(user!.id);
		setInstruments(instruments);
		const relationships = await RelationshipsService.getUserRelationships();
		setUserRelationships(relationships);
		const addresses = await AddressService.getAddressesForLoggedInInstructor();
		setAddresses(addresses);
		const instructor = await InstructorService.getInstructor(user?.id!);
		setInstructorRate(instructor.price);
		return { instruments, relationships, addresses, instructor };
	}

	async function handleSubmit(e: FormEvent<HTMLFormElement>) {
		e.preventDefault();
		setLoading(true);
		let result;
		if (props.available)
			result = await AvailableService.editAvailable(
				props.available.id,
				formData
			);
		else result = await AvailableService.createAvailable(formData);
		switch (result) {
			case 201:
				window.location.href = "/calendar";
				return;
			case 409:
				alert("You already have a lesson at that time. Choose another time.");
				break;
			default:
				alert("Something went wrong. Try again later.");
				break;
		}
		setLoading(false);
	}

	return (
		<Modal exitCallback={props.exitCallback}>
			<form
				onSubmit={handleSubmit}
				id="newLesson"
				method="POST"
				encType="application/x-www-htmlForm-urlencoded"
			>
				{loading && (
					<>
						<ModalHeader headerText="Loading..." />
						<hr />
						<LoadingElement size={2} />
					</>
				)}
				{!loading && (
					<>
						{step === 0 && (
							<>
								<ModalHeader headerText="Date and Time" />
								<DateTimeStep
									step={step}
									setStep={setStep}
									formData={formData}
									setFormData={setFormData}
									radioTracker={radioTracker}
									setRadioTracker={setRadioTracker}
									isEditModal={isEditModal}
								/>
							</>
						)}
						{step === 1 && (
							<>
								<ModalHeader headerText="Instruments/Students" />
								<InstrumentStudentStep
									step={step}
									setStep={setStep}
									formData={formData}
									setFormData={setFormData}
									radioTracker={radioTracker}
									setRadioTracker={setRadioTracker}
									instruments={instruments}
									userRelationships={userRelationships}
									searchValue={searchValue}
									setSearchValue={setSearchValue}
									isEditModal={isEditModal}
								/>
							</>
						)}
						{step === 2 && (
							<>
								<ModalHeader headerText="Location" />
								<VirtualAddressStep
									step={step}
									setStep={setStep}
									formData={formData}
									setFormData={setFormData}
									radioTracker={radioTracker}
									setRadioTracker={setRadioTracker}
									addresses={addresses}
								/>
							</>
						)}
						{step === 3 && (
							<>
								<ModalHeader headerText="Confirmation" />
								<ConfirmationStep
									step={step}
									setStep={setStep}
									formData={formData}
									instruments={instruments}
									addresses={addresses}
									instructorRate={instructorRate}
									searchValue={searchValue}
								/>
							</>
						)}
					</>
				)}
			</form>
		</Modal>
	);
}

import { RefObject, useEffect } from "react";
import PaymentFormDataType from "../../../Types/PaymentFormDataType";

type Props = {
    setStep: Function,
    step: number,
    formRef: RefObject<HTMLFormElement>,
    formData: PaymentFormDataType,
    last4: string | undefined,
    exp: string | undefined,
    priceString: string,
    choseExistingCard: boolean
}
function ReviewStep(props: Props) {

    useEffect(() => {
    window.scrollTo(0,0);
    }, [])
    
    function nextStepHandler() {
        props.setStep(props.step + 1);
    }
    function previousStepHandler() {
        props.choseExistingCard ? 
        props.setStep(props.step - 2) :
        props.setStep(props.step - 1);
    }
    function clickHandler(){
        nextStepHandler();
        props.formRef.current?.dispatchEvent(
            new Event("submit", { cancelable: true, bubbles: true })
        );
    }

    return (
        <div id="reviewDiv" className="">
            <h2>Review</h2>
            <div id="reviewInfo">
                <div className="centerContent">
                    <div id="billingReview">
                        <p className='editReview cursorPoint' >Edit</p>
                        <h4>Billing</h4>
                        <p>{props.formData.firstNameBilling} {props.formData.lastNameBilling}</p>
                        <p id='streetAddressReview'>{props.formData.streetAddressLineOneBilling} 
                        {props.formData.streetAddressLineTwoBilling.length !== 0 && 
                           <>, {props.formData.streetAddressLineTwoBilling}</> 
                        }  </p>
                        <p>Fort Mill, SC</p>
                        <p>29715</p>
                        <br />
                    </div>
                    <div id="paymentReview">
                        <p className='editReview cursorPoint' >Edit</p>
                        <h4>Payment</h4>
                        <p>Card Ending in {props.last4}</p>
                        <p>Expires on {props.exp}</p>
                        <br />
                    </div>
                    <div id="totalReview">
                        <h4>Total: {props.priceString}</h4>
                    </div>
                </div>
            </div>
            <div className="footerButtons">
                <button type="button" className="invertedBtn viewCartBtn"><a href="/cart">View Cart</a></button>
                <button type="button" id="reviewBack" onClick={previousStepHandler} >Back</button>
                <button type="button" id="reviewPurchase" className="activated cursorPoint" onClick={clickHandler}
                >Purchase</button>
            </div>
        </div>
    )
}

export default ReviewStep
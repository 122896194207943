import { useEffect, useState } from "react";
import NotificationService from "../../../services/notification.service";
import DashboardNotification from "./DashboardNotification/DashboardNotification";
import NotificationType from "../../../Types/NotificationType";
import LessonType from "../../../Types/LessonType";
import Modal from "../../Modal/Modal";
import ModalHeader from "../../Modal/ModalHeader/ModalHeader";
import LessonModal from "../../LessonModal/LessonModal";
import LessonService from "../../../services/lesson.service";

export default function DashboardNotifications() {
	const [notifications, setNotifications] = useState<NotificationType[]>([]);
	let notificationElements: JSX.Element[] | null | undefined = [];
	const [openLessonDetailsModal, setOpenLessonDetailsModal] = useState(false);
	const [lesson, setLesson] = useState<LessonType | undefined>();

	useEffect(() => {
		NotificationService.getNotifications().then((notifications) => {
			setNotifications(notifications);
		});
	}, []);

	function handleRemove(id: number): void {
		NotificationService.deleteNotification(id).then((status: number) => {
			if (status === 204) {
				const newList = notifications.filter((item) => item.id !== id);
				setNotifications(newList);
			}
		});
	}

	function removeAllNotifications(): void {
		NotificationService.deleteNotifications().then((status: number) => {
			if (status === 204) {
				setNotifications([]);
			}
		});
	}

	function modalHandler() {
		setOpenLessonDetailsModal(!openLessonDetailsModal);
	}

	async function openLessonDetailsHandler(lessonId: string): Promise<void> {
		setLesson(await LessonService.getLesson(parseInt(lessonId)));
		modalHandler();
	}

	try {
		notificationElements = notifications.map((notification) => {
			return (
				<DashboardNotification
					key={notification.id}
					id={notification.id}
					title={notification.title}
					description={notification.description}
					linkText={notification.linkText}
					route={notification.route}
					handleRemove={handleRemove}
					lessonModalHandler={openLessonDetailsHandler}
				/>
			);
		});
	} catch (e) {
		notificationElements = [];
	}

	return (
		<div id="notifications" className="gridRightUnderHeader">
			<div className="notificationsHeader">
				<h3>Notifications</h3>
				<button
					onClick={removeAllNotifications}
					id="clearNotifications"
					className="greenButton"
				>
					Clear
				</button>
			</div>
			<hr />
			<ul id="notificationList">{notificationElements}</ul>
			{notificationElements.length === 0 && (
				<p className="noContentToShow">No new notifications.</p>
			)}
			{openLessonDetailsModal && (
				<Modal exitCallback={modalHandler}>
					<ModalHeader headerText={`Lesson with ${lesson?.studentName}`} />
					<LessonModal
						id={lesson!.lessonId}
						start={lesson!.chosenStart}
						end={lesson!.chosenEnd}
						notes={lesson!.notes!}
						meetLink={lesson!.meetLink}
						studentName={lesson!.studentName}
						instructorName={lesson!.instructorName!}
						lessonPrice={parseFloat(lesson!.price)}
						addressLineOne={lesson!.addressLineOne!}
						addressLineTwo={lesson!.addressLineTwo!}
						addressCity={lesson!.addressCity!}
						addressZipCode={lesson!.addressZipCode!}
						addressState={lesson!.addressState!}
						addressDescription={lesson!.addressDescription!}
						instrumentList=""
						title={`Lesson with ${lesson?.studentName}`}
					/>
				</Modal>
			)}
		</div>
	);
}

import React, { useEffect } from "react";

type Props = {
    defaultValue ?: string,
    setFilterOption: Function,
}

export default function FilterDropdown( props: Props ) {

    useEffect(() => {
        props.setFilterOption("mostRecent");
    })

    return(
        <select name="filterDropdown" id="filterDropdown" defaultValue={props.defaultValue} onChange={(e) => props.setFilterOption(e.target.value)}>
            <option value="mostRecent">Most Recent</option>
            <option value="oldest">Oldest</option>
            <option value="AtoZ">Alphabetical A-Z</option>
            <option value="ZtoA">Alphabetical Z-A</option>
        </select>
    )
}
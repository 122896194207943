import { ChangeEventHandler, FormEventHandler } from "react";
import NotificationSettingsType from "../../../Types/NotificationSettingsType";

type Props = {
	notificationSettings: NotificationSettingsType;
	setNotificationSettings: ChangeEventHandler<HTMLInputElement>;
	onSubmitHandler: FormEventHandler<HTMLFormElement>;
};
export default function NotificationSettings(props: Props) {
	return (
		<>
			<form id="notificationSettings" onSubmit={props.onSubmitHandler}>
				<h2>Notification Settings</h2>
				<hr />
				<input
					className="checkbox"
					type="checkbox"
					id="1hrRemind"
					name="oneHourReminder"
					checked={props.notificationSettings.oneHourReminder}
					onChange={props.setNotificationSettings}
				/>

				<label htmlFor="1hrRemind">Remind 1 Hour Before Lessons</label>
				<br />
				<br />
				<input
					className="checkbox"
					type="checkbox"
					id="1dayRemind"
					name="oneDayReminder"
					checked={props.notificationSettings.oneDayReminder}
					onChange={props.setNotificationSettings}
				/>

				<label htmlFor="1dayRemind">Remind 24 Hour Before Lessons</label>
				<br />
				<br />
				<input
					className="checkbox"
					type="checkbox"
					id="cancelNotif"
					name="cancellation"
					checked={props.notificationSettings.cancellation}
					onChange={props.setNotificationSettings}
				/>

				<label htmlFor="cancelNotif">
					Notify me if there is a cancellation
				</label>
				<br />
				<br />
				<input
					className="checkbox"
					type="checkbox"
					id="signUpNotif"
					name="receipt"
					checked={props.notificationSettings.receipt}
					onChange={props.setNotificationSettings}
				/>

				<label htmlFor="signUpNotif">
					Let me know when someone signs up for a lesson
				</label>
				<br />
				<br />
				<input
					className="checkbox"
					type="checkbox"
					id="emailNotif"
					name="emailMessage"
					checked={props.notificationSettings.emailMessage}
					onChange={props.setNotificationSettings}
				/>

				<label htmlFor="emailNotif">Send new messages to my email</label>
				<br />

				<input type="submit" value="Save Changes" className="greenButton" />
			</form>
		</>
	);
}

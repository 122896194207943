const dev = Object.freeze({
	API_URL: "http://localhost:8080",
	REACT_URL: "http://localhost:3000",
	DOC_TITLE_SUFFIX: "| Cloud Music Suite",
	DASHBOARD_DOC_TITLE: "Dashboard | Cloud Music Suite",
	FILES_DOC_TITLE: "Files | Cloud Music Suite",
	MESSAGE_DOC_TITLE: "Message | Cloud Music Suite",
	PAYMENT_DOC_TITLE: "Payment | Cloud Music Suite",
	SETTINGS_DOC_TITLE: "Settings | Cloud Music Suite",
	CALENDAR_DOC_TITLE: "Calendar | Cloud Music Suite",
	CART_DOC_TITLE: "Cart | Cloud Music Suite",
	INSTRUCTORS_DOC_TITLE: "Instructors | Cloud Music Suite",
	NOT_FOUND_DOC_TITLE: "Not Found | Cloud Music Suite",
});

const prod = Object.freeze({
	API_URL: "https://development.cloudmusicsuite.com",
	REACT_URL: "http://localhost:3000",
	REACT_URL_TEST: "",
	DOC_TITLE_SUFFIX: "| Cloud Music Suite",
	DASHBOARD_DOC_TITLE: "Dashboard | Cloud Music Suite",
	FILES_DOC_TITLE: "Files | Cloud Music Suite",
	MESSAGE_DOC_TITLE: "Message | Cloud Music Suite",
	PAYMENT_DOC_TITLE: "Payment | Cloud Music Suite",
	SETTINGS_DOC_TITLE: "Settings | Cloud Music Suite",
	CALENDAR_DOC_TITLE: "Calendar | Cloud Music Suite",
	CART_DOC_TITLE: "Cart | Cloud Music Suite",
	INSTRUCTORS_DOC_TITLE: "Instructors | Cloud Music Suite",
	NOT_FOUND_DOC_TITLE: "Not Found | Cloud Music Suite",
});

export default process.env.NODE_ENV === "development" ? dev : prod;

import LoadingElement from "../LoadingElement/LoadingElement";
import "./LoadingPage.scss";

function LoadingPage() {
	return (
		<div id="loadingPage">
			<LoadingElement size={10} />
		</div>
	);
}

export default LoadingPage;

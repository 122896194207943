import React from "react";
import Modal from "../Modal/Modal";
import { useContext, useEffect, useState } from "react";
import SearchService from "./../../services/search.service";
import StudentContext from "../../Contexts/StudentContext";
import userRelationshipType from "../../Types/UserRelationshipType";
import ShareType from "../../Types/shareType";
import FileService from "../../services/file.service";

type Props = {
	fileName: string;
	fileId: number;
	exitCallback: Function;
};
export default function ShareFileModal(props: Props) {
	const user = useContext(StudentContext);
	const [userRelationships, setUserRelationships] = useState<
		userRelationshipType[]
	>([]);
	const [shownRelationships, setShownRelationships] = useState<
		userRelationshipType[]
	>([]);
	const [userId, setUserId] = useState(0);
	const [search, setSearch] = useState("");
	const searchWrapper = document.querySelector(".search-input");

	let id = 0;
	if (user && user.id) {
		id = user.id;
	}
	useEffect(() => {
		SearchService.getUserRelationships(id).then((value: any[]) => {
			let temp: userRelationshipType[] = [];
			for (let i = 0; i < value.length; i++) {
				if (value[i].userOneId !== id) {
					temp.push(value[i].userOne);
				} else {
					temp.push(value[i].userTwo);
				}
			}
			setUserRelationships(temp);
		});
	}, [id]);

	function displayRelationships(event: any) {
		if (event.key === "enter") {
			shareSubmit();
		} else {
			let searchInput = event.target.value.toLowerCase();
			setSearch(searchInput);
			setUserId(0);
			setShownRelationships([]);
			let tempRelationships: userRelationshipType[] = [];
			for (let i = 0; i < userRelationships.length; i++) {
				if (
					searchInput !== "" &&
					(searchInput ===
						userRelationships[i].firstName
							.substring(0, searchInput.length)
							.toLowerCase() ||
						searchInput ===
							userRelationships[i].lastName
								.substring(0, searchInput.length)
								.toLowerCase() ||
						searchInput ===
							userRelationships[i].email
								.substring(0, searchInput.length)
								.toLowerCase())
				) {
					tempRelationships.push(userRelationships[i]);
				}
			}
			if (tempRelationships.length > 0 && searchWrapper !== null) {
				searchWrapper.classList.add("active");
			} else if (tempRelationships.length === 0 && searchWrapper !== null) {
				searchWrapper.classList.remove("active");
			}
			setShownRelationships(tempRelationships);
		}
	}

	function selectRelationship(email: string, id: number) {
		setSearch(email);
		setUserId(id);
		if (searchWrapper !== null) {
			searchWrapper.classList.remove("active");
		}
		setShownRelationships([]);
	}

	async function shareSubmit() {
		if (search !== "") {
			let body: ShareType = {
				fileId: props.fileId,
				email: search,
				userId: 0,
				relationship: false,
			};
			if (userId === 0) {
				await SearchService.getUserViaEmail(search).then(async (value: any) => {
					if (value !== null && value.data !== null) {
						value = value.data;
						let relationship: boolean = false;
						for (let i = 0; i < userRelationships.length; i++) {
							if (userRelationships[i].id === value) {
								relationship = true;
							}
						}
						body.userId = value;
						body.relationship = relationship;
					}
				});
			} else {
				body.userId = userId;
				body.relationship = true;
			}
			if (body.userId === 0) {
				alert("Please enter a valid email");
			} else {
				const result = await FileService.shareFile(body);
				await props.exitCallback();
				if (result.status === 201) {
					alert("File succcessfully shared!");
				} else {
					alert(result.data || "Something went wrong. Try again later.");
				}
			}
		} else {
			alert("Please enter a valid email");
		}
	}

	return (
		<Modal exitCallback={props.exitCallback}>
			<h2>Share</h2>
			<hr />
			<div id="editFileName">{props.fileName}</div>
			<div id="userSearch" className="firstQuestionDiv">
				<p className="question">Who would you like to share this file with?</p>
				<div className="wrapper">
					<div className="search-input">
						<input
							id="userShareSearch"
							type="text"
							placeholder="Type to search.."
							onChange={displayRelationships}
							autoComplete="off"
							value={search}
						/>

						<div className="autocom-box">
							<ul id="users">
								{Array.from(shownRelationships).map(
									(user: userRelationshipType) => {
										return (
											<li
												className="shareLi cursorPoint"
												id={user.id.toString()}
												onClick={() => selectRelationship(user.email, user.id)}
											>
												<p className="shareName">
													{user.firstName} {user.lastName}
												</p>
												<p className="userEmail"> - {user.email}</p>
											</li>
										);
									}
								)}
							</ul>
						</div>
						<div className="icon cursorPoint" onClick={shareSubmit}>
							Share
						</div>
					</div>
				</div>
			</div>
			<p>
				Please enter an exact email if you have not connected with this user
				before.
			</p>
		</Modal>
	);
}
